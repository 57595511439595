export const gloRaids = [
  '0227',
  '0249',
  '0306',
  '0353',
  '0390',
  '0418',
  '0447',
  '0516',
  '0518',
  '0575',
  '0603',
  '0654',
  '0770',
  '0771',
  '0772',
  '0773',
  '0774',
  '0775',
  '0795',
  '0836',
  '0865',
  '0933',
  '0978',
  '0989',
  '1047',
  '1108',
  '1163',
  '1298',
  '1374',
  '1432',
  '1518',
  '1595',
  '1623',
  '1647',
  '1669',
  '1680',
  '1696',
  '1727',
  '1739',
  '1772',
  '1780',
  '1815',
  '1826',
  '1830',
  '1879',
  '1924',
  '1930',
  '1943',
  '2015',
  '2033',
  '2070',
  '2097',
  '2158',
  '2197',
  '2263',
  '2281',
  '2356',
  '2394',
  '2398',
  '2473',
  '2504',
  '2517',
  '2552',
  '2559',
  '2642',
  '2643',
  '2644',
  '2645',
  '2646',
  '2647',
  '2670',
  '2684',
  '2698',
  '2707',
  '2711',
  '2712',
  '2781',
  '2795',
  '2819',
  '2846',
  '2885',
  '2893',
  '2922',
  '2956',
  '2987',
  '3340',
  '3352',
  '3357',
  '3359',
  '3368',
  '3376',
  '5052',
  '3078',
  '3143',
  '3209',
  '3263',
  '3399',
];

export const japRaids = [];

export const raids = [...gloRaids, ...japRaids];
