import { Legend } from '../../models/legend.interface';
import {
  CharacterClass,
  CharacterTypeClass,
} from '../../models/character-class.interface';
import { PotentialAbility } from '../../models/potential-ability';

export const singleLegendsDex: Legend[] = [
  // Ace V1
  {
    id: '0562',
    title: 'Commandant de Barbe Blanche en noir',
    name: 'Portgas D. Ace',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/p5WU6dy.png',
      sixStars: 'https://i.imgur.com/1E2sGaH.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Commandant de la 2e flotte de l'équipage de Barbe Blanche. Un esprit libre et tolérant, également capable de courtoisie et de bon sens quand le besoin s'en fait sentir. Rentre dans une colère noire quand on ose insulter le Capitaine Barbe Blanche, son sauveur et bienfaiteur.`,
      type: CharacterTypeClass.DEX,
      classes: [CharacterClass.Tireur, CharacterClass.Libre],
      combo: 4,
      hp: {
        initial: 3818,
        afterLimitBreak: 4168,
        afterLimitBreakPlus: 4468,
      },
      atk: {
        initial: 1352,
        afterLimitBreak: 1602,
        afterLimitBreakPlus: 1752,
      },
      rcv: {
        initial: 321,
        afterLimitBreak: 421,
        afterLimitBreakPlus: 521,
      },
      captainAbility: `Multiplie les HP des personnages de classe Tireur par 1,5 et multiplie l'ATK des personnages de classe Tireur par 3 s'ils ont un cercle correspondant, sinon par 2.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie les HP des personnages de classe Tireur par 1,5 et multiplie l'ATK des personnages de classe Tireur par 3,5 s'ils ont un cercle correspondant, sinon par 3.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Réduit les HP de l'équipe de 40%, inflige 15 fois les HP perdus en dégâts non élémentaires sur tous les ennemis et bloque les cercles pendant un tour.`,
      specialTurns: {
        initial: 22,
        max: 17,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 4,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour les personnages de classe Tireur.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [DEX] avantageux pour les personnages de classe Tireur.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0561.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0562.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.QCK_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type QCK de 1%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 2%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 3%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 5%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 7%.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
        ],
      },
      {
        type: PotentialAbility.BARRIERE,
        icon: '',
        effects: [
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 90%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 70%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 50%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0086.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0302.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Barbe Blanche', 'Marco'],
      levels: [
        `Bloque le cercle du personnage soutenu pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Bloque le cercle du personnage soutenu pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Bloque le cercle du personnage soutenu pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Bloque les cercles pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Bloque les cercles pendant 2 tours si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Ace V1 6+
  {
    id: '1816',
    title: 'Soleil destructeur de ténèbres',
    name: 'Portgas D. Ace',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/5qaO8JU.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Commandant de la 2e flotte de l'équipage de Barbe Blanche. Bien décidé à faire payer Barbe Noire pour avoir enfraint la règle d'or de l'équipage, il retrouve ce dernier sur l'île de Banaro et s'engage avec lui dans un duel qui sera plus tard considéré comme l'évènement déclencheur de la guerre au sommet.`,
      type: CharacterTypeClass.DEX,
      classes: [CharacterClass.Tireur, CharacterClass.Libre],
      combo: 4,
      hp: {
        initial: 3922,
        afterLimitBreak: 4272,
        afterLimitBreakPlus: 4572,
      },
      atk: {
        initial: 1466,
        afterLimitBreak: 1716,
        afterLimitBreakPlus: 1866,
      },
      rcv: {
        initial: 358,
        afterLimitBreak: 458,
        afterLimitBreakPlus: 558,
      },
      captainAbility: `Multiplie les HP des personnages de classe Tireur par 1,5 et multiplie l'ATK des personnages de classe Tireur par 3,25 s'ils ont un cercle correspondant, sinon par 2,25.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie les HP des personnages de classe Tireur par 1,5 et multiplie l'ATK des personnages de classe Tireur par 3,75 s'ils ont un cercle correspondant, sinon par 3,5.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Réduit les HP de l'équipe de 40%, inflige 15 fois les HP perdus en dégâts non élémentaires sur tous les ennemis et multiplie l'ATK des personnages de classe Tireur par 2 pendant un tour.`,
      specialTurns: {
        initial: 20,
        max: 15,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 4,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour les personnages de classe Tireur.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [DEX] avantageux pour les personnages de classe Tireur.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1816.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.QCK_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type QCK de 1%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 2%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 3%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 5%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 7%.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
        ],
      },
      {
        type: PotentialAbility.BARRIERE,
        icon: '',
        effects: [
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 90%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 70%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 50%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/green_skull2_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0086.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0302.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Barbe Blanche', 'Marco'],
      levels: [
        `Bloque le cercle du personnage soutenu pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Bloque le cercle du personnage soutenu pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Bloque le cercle du personnage soutenu pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Bloque les cercles pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Bloque les cercles pendant 2 tours si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Crocodile
  {
    id: '0720',
    title: `Ancien membre de l'ordre des sept grands corsaires`,
    name: 'Sir Crocodile Logia',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/hcQhi4J.png',
      sixStars: 'https://i.imgur.com/5N2Dc9R.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Ancien membre de l'ordre des sept grands corsaires et chef de l'organisation criminelle Baroque Works. Le crochet qui lui sert de main gauche est empoisonné et recouvert de venin de scorpion.`,
      type: CharacterTypeClass.DEX,
      classes: [CharacterClass.Intellectuel, CharacterClass.Ravageur],
      combo: 4,
      hp: {
        initial: 3621,
        afterLimitBreak: 4121,
        afterLimitBreakPlus: 0,
      },
      atk: {
        initial: 1435,
        afterLimitBreak: 1635,
        afterLimitBreakPlus: 0,
      },
      rcv: {
        initial: 310,
        afterLimitBreak: 410,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Intellectuel selon les HP de l'équipe au début du tour.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: `Par 1,5 si les HP de l'équipe sont au maximum et par 3,25 s'il ne reste qu'un HP à l'équipe.`,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Multiplie l'ATK des personnages de classe Intellectuel par 2 pendant un tour si les HP sont en-dessous de 30%, sinon par 1,5 pendant un tour, et inflige 20 fois son ATK en dégâts de type DEX sur tous les ennemis.`,
      specialTurns: {
        initial: 21,
        max: 14,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 0,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 50 en ATK pour les personnages de classe Intellectuel.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [DEX] avantageux pour les personnages de classe Intellectuel.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0719.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0720.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.QCK_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type QCK de 1%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 2%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 3%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 5%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 7%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 10% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 75 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 200 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 250 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 300 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0086.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0102.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de classe Intellectuel'],
      levels: [
        `Ajoute 5% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 6% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 7% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 8% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 9% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Corazon
  {
    id: '1001',
    title: null,
    name: 'Don Quijote Rosinante',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/1rrkaoF.png',
      sixStars: 'https://i.imgur.com/22vXULd.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Ancien officier d'élite de l'équipage de Don Quijote. "Corazon" est juste un nom de code. Son véritable nom est Don Quijote Rosinante, et c'est le frère cadet de Doflamingo.`,
      type: CharacterTypeClass.DEX,
      classes: [CharacterClass.Libre, CharacterClass.Intellectuel],
      combo: 4,
      hp: {
        initial: 2746,
        afterLimitBreak: 3136,
        afterLimitBreakPlus: 3336,
      },
      atk: {
        initial: 1222,
        afterLimitBreak: 1482,
        afterLimitBreakPlus: 1682,
      },
      rcv: {
        initial: 555,
        afterLimitBreak: 605,
        afterLimitBreakPlus: 705,
      },
      captainAbility: `Multiplie l'ATK de tous les personnages selon les HP de l'équipe au début du tour, restaure les HP avec 0,5 fois sa RCV à chaque "SUPER" et 1,5 fois sa RCV à chaque "BIEN" à la fin du tour.`,
      captainAbilityAfterLimitBreakPlus: `Inconnue.`,
      captainAbilityAdditionnalInformation: `Par 2 si les HP de l'équipe sont au maximum et par 1 s'il ne reste qu'un HP à l'équipe.`,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Restaure les HP avec 25 fois sa RCV et réduit la durée de "Lien" et de "Désespoir du capitaine" de 3 tours.`,
      specialTurns: {
        initial: 23,
        max: 17,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 3,
      },
      crewmateAbilities: [
        {
          ability: `Réduit la durée de "Paralysie" de 1 tour.`,
          needLimitBreak: true,
        },
        {
          ability: `Réduit la durée de "Lien des coups spéciaux" de 1 tour.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1000.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1001.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 50 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 75 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 200 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
        ],
      },
      {
        type: PotentialAbility.REDUCTION_SPECIAL,
        icon: '',
        effects: [
          `Réduit le temps de chargement de son coup spécial de 1 tour au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 3 tours au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 5 tours au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 7 tours au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 10 tours au début de la quête.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0086.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0302.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Trafalgar Law'],
      levels: [
        `Réduit la durée de "Lien" et de "Désespoir du capitaine" de 1 tour si un personnage a été touché par le "Lien" ou le "Désespoir du capitaine" (une fois par quête).`,
        `Réduit la durée de "Lien" et de "Désespoir du capitaine" de 1 tour si un personnage a été touché par le "Lien" ou le "Désespoir du capitaine" (une fois par quête).`,
        `Réduit la durée de "Lien" et de "Désespoir du capitaine" de 1 tour si un personnage a été touché par le "Lien" ou le "Désespoir du capitaine" (une fois par quête).`,
        `Réduit la durée de "Lien", de "Désespoir du capitaine" et de "Paralysie" de 1 tour si un personnage a été touché par le "Lien", le "Désespoir du capitaine" ou la "Paralysie" (une fois par quête).`,
        `Réduit la durée de "Lien", de "Désespoir du capitaine" et de "Paralysie" de 2 tours si un personnage a été touché par le "Lien", le "Désespoir du capitaine" ou la "Paralysie" (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Corazon 6+
  {
    id: '2195',
    title: 'Personne empathique envers Law',
    name: 'Cora',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/htOgYK6.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Ancien lieutenant-chef de l'équipage de Don Quijote. Avant de mourir sous les balles de son frère Doflamingo, dont il avait pressenti la vengeance, il parvient à sauver Law en lui faisant manger le fruit du bistouri et en le comblant d'affection.`,
      type: CharacterTypeClass.DEX,
      classes: [CharacterClass.Libre, CharacterClass.Intellectuel],
      combo: 4,
      hp: {
        initial: 2846,
        afterLimitBreak: 3236,
        afterLimitBreakPlus: 3436,
      },
      atk: {
        initial: 1308,
        afterLimitBreak: 1568,
        afterLimitBreakPlus: 1768,
      },
      rcv: {
        initial: 562,
        afterLimitBreak: 612,
        afterLimitBreakPlus: 712,
      },
      captainAbility: `Multiplie l'ATK de tous les personnages selon les HP de l'équipe au début du tour, restaure les HP avec 0,5 fois sa RCV à chaque "SUPER" et 1,5 fois sa RCV à chaque "BIEN" à la fin du tour.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie l'ATK de tous les personnages selon les HP de l'équipe au début du tour, restaure les HP avec 0,3 fois sa RCV à chaque "PARFAIT", 0,8 fois sa RCV à chaque "SUPER" et 1,5 fois sa RCV à chaque "BIEN" à la fin du tour.`,
      captainAbilityAdditionnalInformation: `Par 3 si les HP de l'équipe sont au maximum et par 2 s'il ne reste qu'un HP à l'équipe.`,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: `Par 3,5 si les HP de l'équipe sont au maximum et par 2 s'il ne reste qu'un HP à l'équipe.`,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Restaure les HP avec 50 fois sa RCV et réduit la durée de "Lien", de "Désespoir du capitaine", de "Paralysie", des réductions d'ATK, des blocages et des diminutions de hausse du multiplicateur de 3 tours. Si le personnage est capitaine ou capitaine ami, réduit la durée de "Désespoir du capitaine" de 20 tours.`,
      specialTurns: {
        initial: 22,
        max: 16,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 3,
      },
      crewmateAbilities: [
        {
          ability: `Réduit la durée de "Paralysie" de 1 tour.`,
          needLimitBreak: true,
        },
        {
          ability: `Réduit la durée de "Lien des coups spéciaux" de 1 tour.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f2195.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 50 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 75 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 200 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
        ],
      },
      {
        type: PotentialAbility.REDUCTION_SPECIAL,
        icon: '',
        effects: [
          `Réduit le temps de chargement de son coup spécial de 1 tour au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 3 tours au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 5 tours au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 7 tours au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 10 tours au début de la quête.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/green_skull2_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0086.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0302.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Trafalgar Law'],
      levels: [
        `Réduit la durée de "Lien" et de "Désespoir du capitaine" de 1 tour si un personnage a été touché par le "Lien" ou le "Désespoir du capitaine" (une fois par quête).`,
        `Réduit la durée de "Lien" et de "Désespoir du capitaine" de 1 tour si un personnage a été touché par le "Lien" ou le "Désespoir du capitaine" (une fois par quête).`,
        `Réduit la durée de "Lien" et de "Désespoir du capitaine" de 1 tour si un personnage a été touché par le "Lien" ou le "Désespoir du capitaine" (une fois par quête).`,
        `Réduit la durée de "Lien", de "Désespoir du capitaine" et de "Paralysie" de 1 tour si un personnage a été touché par le "Lien", le "Désespoir du capitaine" ou la "Paralysie" (une fois par quête).`,
        `Réduit la durée de "Lien", de "Désespoir du capitaine" et de "Paralysie" de 2 tours si un personnage a été touché par le "Lien", le "Désespoir du capitaine" ou la "Paralysie" (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Blackbeard
  {
    id: '1268',
    title: `Capitaine de l'équipage de Barbe Noire`,
    name: 'Barbe Noire',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/Gp6rSkn.png',
      sixStars: 'https://i.imgur.com/eAl6kPk.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage de Barbe Noire. Lors de son apparition surprise à la fin de la guerre au sommet, il annonce sa démission de l'ordre des sept grands corsaires et s'empare du fruit du tremblement du défunt Barbe Blanche, montrant ainsi qu'il peut posséder les pouvoirs de deux fruits du démon là où une personne normalement constituée ne peut en assimiler qu'un.`,
      type: CharacterTypeClass.DEX,
      classes: [CharacterClass.Ambitieux, CharacterClass.Tenace],
      combo: 4,
      hp: {
        initial: 3350,
        afterLimitBreak: 3790,
        afterLimitBreakPlus: 4140,
      },
      atk: {
        initial: 1520,
        afterLimitBreak: 1745,
        afterLimitBreakPlus: 1945,
      },
      rcv: {
        initial: 244,
        afterLimitBreak: 399,
        afterLimitBreakPlus: 449,
      },
      captainAbility: `Si l'équipe contient un personnage de classe Cogneur, un de classe Sabreur, un de classe Tireur et un de classe Ravageur, multiplie l'ATK de tous les personnages par 2,75 et les HP par 1,5. Permet aux attaques normales d'ignorer les effets défensifs dont bénéficient les ennemis et l'équipe.`,
      captainAbilityAfterLimitBreakPlus: `Si l'équipe contient un personnage de classe Cogneur, un de classe Sabreur, un de classe Tireur et un de classe Ravageur, multiplie l'ATK de tous les personnages par 3,25 et les HP par 1,5. Permet aux attaques normales d'ignorer les effets défensifs dont bénéficient les ennemis et l'équipe.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Enlève 30% des HP de chaque ennemi et si le personnage est capitaine, ignore les effets défensifs.`,
      specialTurns: {
        initial: 25,
        max: 19,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 4,
      },
      crewmateAbilities: [
        {
          ability: `Multiplie l'ATK du personnage par 2 contre les ennemis de type STR.`,
          needLimitBreak: true,
        },
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour tous les personnages.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1267.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1268.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 4,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 20 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 40 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 180 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 120 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 60% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 4 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0302.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0304.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0102.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de type DEX'],
      levels: [
        `Ajoute 5% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
        `Ajoute 6% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
        `Ajoute 7% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
        `Ajoute 8% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
        `Ajoute 10% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Zoro V1
  {
    id: '1362',
    title: `Nouvelle vie - Équipage de Chapeau de paille`,
    name: 'Roronoa Zoro',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/wWU8lwI.png',
      sixStars: 'https://i.imgur.com/CmVlbvM.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Sabreur à trois lames de l'équipage de Chapeau de paille. Grâce à son entraînement de deux ans auprès de son rival Mihawk, il remporte une victoire écrasante face au meilleur sabreur de l'île des hommes-poissons.`,
      type: CharacterTypeClass.DEX,
      classes: [CharacterClass.Ambitieux, CharacterClass.Sabreur],
      combo: 4,
      hp: {
        initial: 3603,
        afterLimitBreak: 4103,
        afterLimitBreakPlus: 4453,
      },
      atk: {
        initial: 1518,
        afterLimitBreak: 1718,
        afterLimitBreakPlus: 1918,
      },
      rcv: {
        initial: 294,
        afterLimitBreak: 369,
        afterLimitBreakPlus: 444,
      },
      captainAbility: `Si l'équipe contient au moins 5 personnages de classe Sabreur, multiplie l'ATK des personnages de classe Sabreur par 2,5 et les HP par 1,5. Si un ennemi a été vaincu au tour précédent, multiplie l'ATK des personnages de classe Sabreur par 3 et multiplie l'ATK des autres personnages par 1,2.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige 60 fois son ATK en dégâts non élémentaires sur tous les ennemis et multiplie l'ATK des personnages de classe Sabreur par 2 pendant un tour si le personnage est capitaine ou capitaine ami, sinon par 1,75 pendant un tour.`,
      specialTurns: {
        initial: 20,
        max: 16,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 5,
      },
      crewmateAbilities: [
        {
          ability: `Rend les cercles [DEX] avantageux pour les personnages de classe Sabreur.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [TND] avantageux pour les personnages de classe Sabreur.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1361.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1362.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.QCK_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type QCK de 2%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 3%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 4%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 5%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 7%.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 4% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 6% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 80% de chances de faire 8% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 90% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0086.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0302.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Dracule Mihawk', 'Perona'],
      levels: [
        `Bloque le multiplicateur de combos à 2 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Bloque le multiplicateur de combos à 2 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Bloque le multiplicateur de combos à 2,5 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Bloque le multiplicateur de combos à 2,5 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Bloque le multiplicateur de combos à 2,5 pendant 2 tours si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Zoro V1 6+
  {
    id: '1921',
    title: `Nouvelle vie - Équipage de Chapeau de paille`,
    name: 'Zoro le chasseur de pirates',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/o1pXcH8.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Sabreur à trois lames de l'équipage de Chapeau de paille. Il est désormais capable d'utiliser une lame noire suite à son apprentissage auprès de Mihawk, ce qui lui permet de détruire d'un seul coup d'épée le géant de pierre Pica, comme l'eut fait autrefois son maître avec un galion. Cette lame noire ne peut être brisée.`,
      type: CharacterTypeClass.DEX,
      classes: [CharacterClass.Ambitieux, CharacterClass.Sabreur],
      combo: 4,
      hp: {
        initial: 3922,
        afterLimitBreak: 4422,
        afterLimitBreakPlus: 4772,
      },
      atk: {
        initial: 1555,
        afterLimitBreak: 1755,
        afterLimitBreakPlus: 1955,
      },
      rcv: {
        initial: 328,
        afterLimitBreak: 403,
        afterLimitBreakPlus: 478,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Sabreur par 3 et multiplie les HP par 1,5.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige 120 fois son ATK en dégâts non élémentaires sur tous les ennemis et multiplie l'ATK des personnages de classe Sabreur par 2 pendant un tour si le personnage est capitaine ou capitaine ami, sinon par 1,75 pendant un tour et bloque le multiplicateur de combos à 2,5 pendant un tour.`,
      specialTurns: {
        initial: 19,
        max: 15,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 5,
      },
      crewmateAbilities: [
        {
          ability: `Rend les cercles [DEX] avantageux pour les personnages de classe Sabreur.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [TND] avantageux pour les personnages de classe Sabreur.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1921.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.QCK_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type QCK de 2%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 3%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 4%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 5%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 7%.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 4% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 6% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 80% de chances de faire 8% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 90% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/green_skull2_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0086.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0302.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Dracule Mihawk', 'Perona'],
      levels: [
        `Bloque le multiplicateur de combos à 2 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Bloque le multiplicateur de combos à 2 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Bloque le multiplicateur de combos à 2,5 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Bloque le multiplicateur de combos à 2,5 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Bloque le multiplicateur de combos à 2,5 pendant 2 tours si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
];
