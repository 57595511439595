import { Legend } from '../../models/legend.interface';
import {
  CharacterClass,
  CharacterTypeClass,
} from '../../models/character-class.interface';
import { PotentialAbility } from '../../models/potential-ability';
import { PvpStyle } from 'src/app/models/pvp.interface';

export const singleLegendsInt: Legend[] = [
  // Rayleigh V1
  {
    id: '0367',
    title: null,
    name: 'Rayleigh le Seigneur des Ténèbres',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/iMMRzHA.png',
      sixStars: 'https://i.imgur.com/eERtzOs.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Ancien second de l'équipage de Roger. Également connu sous les noms de "Bras Droit du Roi des Pirates" et de "Seigneur des Ténèbres". C'est une légende vivante pour tous, ses exploits sont plus que célèbres. C'est un pirate à la retraite qui utilise le Fluide du Conquérant.`,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Cogneur, CharacterClass.Intellectuel],
      combo: 4,
      hp: {
        initial: 3001,
        afterLimitBreak: 3501,
        afterLimitBreakPlus: 0,
      },
      atk: {
        initial: 1300,
        afterLimitBreak: 1490,
        afterLimitBreakPlus: 0,
      },
      rcv: {
        initial: 500,
        afterLimitBreak: 600,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie le multiplicateur de combos par 4.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige 20 fois son ATK en dégâts de type INT sur tous les ennemis et réduit la durée de "Lien" de 5 tours.`,
      specialTurns: {
        initial: 23,
        max: 18,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 0,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour tous les personnages.`,
          needLimitBreak: true,
        },
        {
          ability: `Si un autre personnage utilise son coup spécial, réduit son coup spécial de 2 tours.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0366.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0367.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 4,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 2 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 10% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_URGENCE,
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 10%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 15%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 20%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 30%,`,
          `Restaure les HP à hauteur de 2 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0088.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0099.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Monkey D. Luffy'],
      levels: [
        `Réduit la durée de "Lien" et de "Désespoir du capitaine" de 1 tour si un personnage a été touché par le "Lien" ou le "Désespoir du capitaine" (une fois par quête).`,
        `Réduit la durée de "Lien" et de "Désespoir du capitaine" de 1 tour si un personnage a été touché par le "Lien" ou le "Désespoir du capitaine" (une fois par quête).`,
        `Réduit la durée de "Lien" et de "Désespoir du capitaine" de 1 tour si un personnage a été touché par le "Lien" ou le "Désespoir du capitaine" (une fois par quête).`,
        `Réduit la durée de "Lien", de "Désespoir du capitaine" et de "Paralysie" de 1 tour si un personnage a été touché par le "Lien", le "Désespoir du capitaine" ou la "Paralysie" (une fois par quête).`,
        `Réduit la durée de "Lien", de "Désespoir du capitaine" et de "Paralysie" de 2 tours si un personnage a été touché par le "Lien", le "Désespoir du capitaine" ou la "Paralysie" (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Rayleigh V1 6+
  {
    id: '1619',
    title: 'Bras droit du roi des pirates',
    name: `Silvers Rayleigh le "seigneur des ténèbres"`,
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/7oBy6xX.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Ancien vice-capitaine de l'équipage de Roger. Bien qu'il ait conquis Grand Line et découvert la véritable Histoire aux côtés de Roger, dont on raconte qu'il pouvait entendre la voix de "toutes les choses", il n'en dit que très peu sur ses différents exploits et préfère encourager les jeunes aventuriers à explorer le monde par eux-mêmes.`,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Cogneur, CharacterClass.Intellectuel],
      combo: 4,
      hp: {
        initial: 3121,
        afterLimitBreak: 3621,
        afterLimitBreakPlus: 0,
      },
      atk: {
        initial: 1472,
        afterLimitBreak: 1662,
        afterLimitBreakPlus: 0,
      },
      rcv: {
        initial: 515,
        afterLimitBreak: 615,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie le multiplicateur de combos par 4 et multiplie les HP et la RCV de tous les personnages par 1,2.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Réduit la durée de "Lien", de "Désespoir du capitaine" et de "Paralysie" de 7 tours, retarde toutes les attaques ennemies d'un tour et rend le timing des coups "PARFAIT" plus facile pendant un tour.`,
      specialTurns: {
        initial: 19,
        max: 14,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 0,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour tous les personnages.`,
          needLimitBreak: true,
        },
        {
          ability: `Si un autre personnage utilise son coup spécial, réduit son coup spécial de 2 tours.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture: 'https://i.imgur.com/KBeErRM.png',
      },
    },
    sockets: 4,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 2 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 10% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_URGENCE,
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 10%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 15%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 20%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 30%,`,
          `Restaure les HP à hauteur de 2 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0088.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0099.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Monkey D. Luffy'],
      levels: [
        `Réduit la durée de "Lien" et de "Désespoir du capitaine" de 1 tour si un personnage a été touché par le "Lien" ou le "Désespoir du capitaine" (une fois par quête).`,
        `Réduit la durée de "Lien" et de "Désespoir du capitaine" de 1 tour si un personnage a été touché par le "Lien" ou le "Désespoir du capitaine" (une fois par quête).`,
        `Réduit la durée de "Lien" et de "Désespoir du capitaine" de 1 tour si un personnage a été touché par le "Lien" ou le "Désespoir du capitaine" (une fois par quête).`,
        `Réduit la durée de "Lien", de "Désespoir du capitaine" et de "Paralysie" de 1 tour si un personnage a été touché par le "Lien", le "Désespoir du capitaine" ou la "Paralysie" (une fois par quête).`,
        `Réduit la durée de "Lien", de "Désespoir du capitaine" et de "Paralysie" de 2 tours si un personnage a été touché par le "Lien", le "Désespoir du capitaine" ou la "Paralysie" (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Pudding
  {
    id: '2837',
    title: 'Séparation par amour',
    name: 'Charlotte Pudding',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/1U9H3r6.png',
      sixStars: 'https://i.imgur.com/fGqALyc.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `35e fille de la famille Charlotte. Soucieuse de ne pas porter préjudice à Sanji, elle quitte ce dernier après l'avoir remercié et serré dans ses bras, autant de souvenirs qu'elle lui ôte aussitôt à l'aide de ses pouvoirs.
      `,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Intellectuel, CharacterClass.Tireur],
      combo: 5,
      hp: {
        initial: 2932,
        afterLimitBreak: 3202,
        afterLimitBreakPlus: 3502,
      },
      atk: {
        initial: 1465,
        afterLimitBreak: 1665,
        afterLimitBreakPlus: 1915,
      },
      rcv: {
        initial: 423,
        afterLimitBreak: 533,
        afterLimitBreakPlus: 603,
      },
      captainAbility: `Multiplie les HP des personnages de type INT par 1,25, multiplie l'ATK des personnages de type INT par 4,25 s'ils ont un cercle correspondant, sinon par 3,5, rend les cercles [PSY] avantageux pour tous les personnages et augmente les chances d'apparition des cercles [PSY].`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Change les cercles désavantageux, [EMP], [BLK] et son cercle en cercles correspondants et réduit le temps de chargement des coups spéciaux de tous les personnages d'un tour. Enregistre un effet augmentant les dégâts infligés au choix, réduit complètement le temps de chargement du coup spécial du personnage et si le coup spécial est utilisé lorsqu'un effet est enregistré, restaure l'effet enregistré pendant un tour en ignorant les interruptions des coups spéciaux.`,
      specialTurns: {
        initial: 12,
        max: 7,
        gainWithLimitBreak: 0,
        gainWithLimitBreakPlus: 0,
      },
      crewmateAbilities: [
        {
          ability: `Réduit la durée de "Lien des coups spéciaux" du personnage de 10 tours.`,
          needLimitBreak: true,
        },
        {
          ability: `Réduit l'inversion de son coup spécial de 10 tours.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: 'https://i.imgur.com/ZwMMVN5.png',
        sixStarsPicture: 'https://i.imgur.com/FJqE403.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.BARRIERE,
        icon: '',
        effects: [
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 90%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 70%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 50%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
          `Réduit la durée de “Soin impossible” de 10 tours.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/blue_skull_f.png',
      'https://onepiece-treasurecruise.com/wp-content/uploads/red_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0100.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Sanji Vinsmoke'],
      levels: [
        `Réduit la durée de "Lien des coups spéciaux" du personnage soutenu de 1 tour si un personnage a été touché par le "Lien des coups spéciaux" (une fois par quête).`,
        `Réduit la durée de "Lien des coups spéciaux" du personnage soutenu de 1 tour si un personnage a été touché par le "Lien des coups spéciaux" (une fois par quête).`,
        `Réduit la durée de "Lien des coups spéciaux" du personnage soutenu de 1 tour et réduit le temps de chargement du coup spécial du personnage soutenu de 1 tour si un personnage a été touché par le "Lien des coups spéciaux" ou l'inversion des coups spéciaux (une fois par quête).`,
        `Réduit la durée de "Lien des coups spéciaux" du personnage soutenu de 1 tour et réduit le temps de chargement du coup spécial du personnage soutenu de 1 tour si un personnage a été touché par le "Lien des coups spéciaux" ou l'inversion des coups spéciaux (une fois par quête).`,
        `Réduit la durée de "Lien des coups spéciaux" du personnage soutenu de 1 tour et réduit le temps de chargement du coup spécial du personnage soutenu de 1 tour si un personnage a été touché par le "Lien des coups spéciaux" ou l'inversion des coups spéciaux (une fois par quête).`,
      ],
    },
    batch: `Waifus`,
  },
  // Sengoku 6+
  {
    id: '1847',
    title: 'Amiral commandant en chef de la Marine',
    name: 'Sengoku le Bouddha',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/jIF3ugu.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Amiral commandant en chef de la Marine. Soucieux d'éradiquer le Mal mais aussi de faire triompher la Justice, il accède à la demande de Shanks de mettre fin à la guerre au sommet, voyant bien que l'objectif de la Marine a déjà été atteint et que ses soldats n'ont plus aucune raison de continuer à se battre.`,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Cogneur, CharacterClass.Intellectuel],
      combo: 4,
      hp: {
        initial: 3696,
        afterLimitBreak: 4046,
        afterLimitBreakPlus: 4546,
      },
      atk: {
        initial: 1458,
        afterLimitBreak: 1708,
        afterLimitBreakPlus: 1908,
      },
      rcv: {
        initial: 398,
        afterLimitBreak: 498,
        afterLimitBreakPlus: 548,
      },
      captainAbility: `Réduit le temps de chargement des coups spéciaux de tous les personnages de 2 tours au début de la quête, multiplie l'ATK des personnages ayant un coût inférieur ou égal à 29 par 3,25, les HP par 1,2 et multiplie l'ATK des personnages ayant un coût égal à 54 par 3.`,
      captainAbilityAfterLimitBreakPlus: `Réduit le temps de chargement des coups spéciaux de tous les personnages de 2 tours au début de la quête, multiplie l'ATK des personnages ayant un coût inférieur ou égal à 29 par 4, les HP et la RCV par 1,5 et multiplie l'ATK des personnages ayant un coût égal à 54 par 4.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige de lourds dégâts de type INT sur tous les ennemis, multiplie l'ATK de tous les personnages par 1,5 pendant un tour et change les cercles en cercles correspondants pour les personnages ayant un coût inférieur ou égal à 29.`,
      specialTurns: {
        initial: 23,
        max: 14,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 6,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 100 en HP et ATK pour les personnages ayant un coût inférieur à 29.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [INT] avantageux pour tous les personnages.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1847.png',
      },
    },
    sockets: 5,
    cost: 54,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 2 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 10% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 75 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 200 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 250 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 300 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/black_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0088.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0304.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages ayant un coût inférieur ou égal à 29'],
      levels: [
        `Multiplie l'ATK du personnage soutenu par 1,25 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 1,5 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 1,75 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 2 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 2,5 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Mihawk V1
  {
    id: '0718',
    title: 'Le plus puissant sabreur du monde',
    name: 'Mihawk "Œil de faucon"',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/KHbs8zx.png',
      sixStars: 'https://i.imgur.com/F6Ckd1J.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Ancien second de l'équipage de Roger. Également connu sous les noms de "Bras Droit du Roi des Pirates" et de "Seigneur des Ténèbres". C'est une légende vivante pour tous, ses exploits sont plus que célèbres. C'est un pirate à la retraite qui utilise le Fluide du Conquérant.`,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Sabreur],
      combo: 5,
      hp: {
        initial: 3900,
        afterLimitBreak: 4500,
        afterLimitBreakPlus: 4850,
      },
      atk: {
        initial: 1490,
        afterLimitBreak: 1690,
        afterLimitBreakPlus: 1890,
      },
      rcv: {
        initial: 120,
        afterLimitBreak: 190,
        afterLimitBreakPlus: 265,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Sabreur selon le timing du personnage précédent.`,
      captainAbilityAfterLimitBreakPlus: 'Inconnue.',
      captainAbilityAdditionnalInformation: `Par 2 si le personnage précédent effectue un "MANQUÉ" ou un coup inférieur à "BIEN", par 2,25 si le personnage précédent effectue un "BIEN", par 2,5 si le personnage précédent effectue un "SUPER" et par 2,75 si le personnage précédent effectue un "PARFAIT".`,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige 50% des dégâts infligés par des attaques normales sur tous les ennemis en dégâts non élémentaires.`,
      specialTurns: {
        initial: 25,
        max: 19,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 4,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 120 en HP pour les personnages de classe Sabreur.`,
          needLimitBreak: true,
        },
        {
          ability: `Ajoute 50 en ATK pour les personnages de classe Sabreur.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0717.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0718.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 4,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 20 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 40 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 80 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 120 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.PSY_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type PSY de 1%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 2%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 3%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 4%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 5%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 10% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0088.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0099.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0304.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de classe Sabreur'],
      levels: [
        `Ajoute 4% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 5% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 7% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 8% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 9% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Mihawk V1 6+
  {
    id: '1881',
    title: 'Sabre noir "Yoru"',
    name: 'Mihawk "Œil de faucon"',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/j0rV3Tc.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Un des sept grands corsaires. Connu comme étant le plus puissant sabreur du monde, il se dresse face à Luffy durant la guerre au sommet. Bien que Luffy fût impressionné par sa puissance, Mihawk a de son côté ressenti l'effroyable capacité qu'a Luffy de se faire des alliés.`,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Sabreur],
      combo: 6,
      hp: {
        initial: 4200,
        afterLimitBreak: 4800,
        afterLimitBreakPlus: 5150,
      },
      atk: {
        initial: 1590,
        afterLimitBreak: 1790,
        afterLimitBreakPlus: 1990,
      },
      rcv: {
        initial: 120,
        afterLimitBreak: 190,
        afterLimitBreakPlus: 265,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Sabreur selon le timing du personnage précédent.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie l'ATK des personnages de classe Sabreur selon le timing du personnage précédent et augmente les chances d'apparition des cercles correspondants.`,
      captainAbilityAdditionnalInformation: `Par 1,5 si le personnage précédent effectue un "MANQUÉ" ou un coup inférieur à "BIEN", par 2 si le personnage précédent effectue un "BIEN", par 2,75 si le personnage précédent effectue un "SUPER" et par 3,5 si le personnage précédent effectue un "PARFAIT".`,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: `Par ? si le personnage précédent effectue un "MANQUÉ" ou un coup inférieur à "BIEN", par ? si le personnage précédent effectue un "BIEN", par ? si le personnage précédent effectue un "SUPER" et par 3,75 si le personnage précédent effectue un "PARFAIT".`,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: [
        {
          specialTurns: {
            initial: 18,
            max: 12,
            gainWithLimitBreak: 1,
            gainWithLimitBreakPlus: 4,
          },
          special: `Inflige 30% des dégâts infligés par des attaques normales sur tous les ennemis en dégâts non élémentaires.`,
        },
        {
          specialTurns: {
            initial: 24,
            max: 18,
            gainWithLimitBreak: 1,
            gainWithLimitBreakPlus: 4,
          },
          special: `Inflige 60% des dégâts infligés par des attaques normales sur tous les ennemis en dégâts non élémentaires.`,
        },
      ],
      special: null,
      specialTurns: null,
      crewmateAbilities: [
        {
          ability: `Ajoute 120 en HP pour les personnages de classe Sabreur.`,
          needLimitBreak: true,
        },
        {
          ability: `Ajoute 50 en ATK pour les personnages de classe Sabreur.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1881.png',
      },
    },
    sockets: 4,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 20 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 40 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 80 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 120 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.PSY_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type PSY de 1%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 2%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 3%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 4%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 5%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 10% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/black_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0088.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0303.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de classe Sabreur'],
      levels: [
        `Ajoute 4% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 5% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 7% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 8% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 9% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Doflamingo V1 6+
  {
    id: '2444',
    title: 'Punition de dieu',
    name: 'Don Quijote Doflamingo',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/ZwmwcG9.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Membre de l'ordre des sept grands corsaires et actuel roi de Dressrosa. D'une cruauté inhumaine, il attise la colère et la haine chez ses ennemis de façon à profiter de leur imprudence. Grâce à sa technique "Black Knight", il crée un double de lui et mène un combat acharné contre Luffy au dernier étage du palais royal.`,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Ambitieux, CharacterClass.Sabreur],
      combo: 5,
      hp: {
        initial: 3100,
        afterLimitBreak: 3550,
        afterLimitBreakPlus: 3850,
      },
      atk: {
        initial: 1608,
        afterLimitBreak: 1858,
        afterLimitBreakPlus: 2008,
      },
      rcv: {
        initial: 461,
        afterLimitBreak: 536,
        afterLimitBreakPlus: 636,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Ambitieux par 3,5, les HP par 1,2 et rend les cercles [PSY] et [INT] avantageux pour les personnages de classe Ambitieux.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Change les cercles adjacents en cercles correspondants et multiplie les effets des cercles des personnages de classe Ambitieux par 2,25 pendant un tour.`,
      specialTurns: {
        initial: 20,
        max: 15,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 1,
      },
      crewmateAbilities: [
        {
          ability: `Si le personnage a un cercle [QCK] et qu'il fait un coup "PARFAIT", garde son cercle [QCK] pour le tour suivant.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [QCK] avantageux pour les personnages de classe Ambitieux.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture: 'https://i.imgur.com/bd769sX.png',
      },
    },
    pvpData: {
      cost: 55,
      style: PvpStyle.DEF,
      ct: 27,
      specialDescription: [
        `Inflige 1 fois son ATK à 3 ennemis, augmente la défense des alliés de classe Sabreur et Ambitieux de 5 niveaux pendant 10 secondes et augmente les chances des alliés de classe Sabreur et Ambitieux d'effectuer une attaque critique de 2 niveaux pendant 10 secondes.`,
        `Inflige 1,1 fois son ATK à 3 ennemis, augmente la défense des alliés de classe Sabreur et Ambitieux de 5 niveaux pendant 11 secondes et augmente les chances des alliés de classe Sabreur et Ambitieux d'effectuer une attaque critique de 2 niveaux pendant 11 secondes.`,
        `Inflige 1,2 fois son ATK à 3 ennemis, augmente la défense des alliés de classe Sabreur et Ambitieux de 5 niveaux pendant 12 secondes et augmente les chances des alliés de classe Sabreur et Ambitieux d'effectuer une attaque critique de 2 niveaux pendant 12 secondes.`,
        `Inflige 1,3 fois son ATK à 3 ennemis, augmente la défense des alliés de classe Sabreur et Ambitieux de 5 niveaux pendant 13 secondes et augmente les chances des alliés de classe Sabreur et Ambitieux d'effectuer une attaque critique de 3 niveaux pendant 13 secondes.`,
        `Inflige 1,4 fois son ATK à 3 ennemis, augmente la défense des alliés de classe Sabreur et Ambitieux de 5 niveaux pendant 14 secondes et augmente les chances des alliés de classe Sabreur et Ambitieux d'effectuer une attaque critique de 3 niveaux pendant 14 secondes.`,
        `Inflige 1,5 fois son ATK à 3 ennemis, augmente la défense des alliés de classe Sabreur et Ambitieux de 5 niveaux pendant 15 secondes et augmente les chances des alliés de classe Sabreur et Ambitieux d'effectuer une attaque critique de 4 niveaux pendant 15 secondes.`,
        `Inflige 1,6 fois son ATK à 3 ennemis, augmente la défense des alliés de classe Sabreur et Ambitieux de 5 niveaux pendant 16 secondes et augmente les chances des alliés de classe Sabreur et Ambitieux d'effectuer une attaque critique de 4 niveaux pendant 16 secondes.`,
        `Inflige 1,7 fois son ATK à 3 ennemis, augmente la défense des alliés de classe Sabreur et Ambitieux de 5 niveaux pendant 17 secondes et augmente les chances des alliés de classe Sabreur et Ambitieux d'effectuer une attaque critique de 4 niveaux pendant 17 secondes.`,
        `Inflige 1,8 fois son ATK à 3 ennemis, augmente la défense des alliés de classe Sabreur et Ambitieux de 5 niveaux pendant 18 secondes et augmente les chances des alliés de classe Sabreur et Ambitieux d'effectuer une attaque critique de 5 niveaux pendant 18 secondes.`,
        `Inflige 2 fois son ATK à 3 ennemis, augmente la défense des alliés de classe Sabreur et Ambitieux de 5 niveaux pendant 19 secondes et augmente les chances des alliés de classe Sabreur et Ambitieux d'effectuer une attaque critique de 6 niveaux pendant 19 secondes.`,
      ],
      capacityDescription: [
        `Augmente les HP des alliés de classe Sabreur et Ambitieux de 2 niveaux. Pendant 30 secondes à partir du début du combat, augmente la défense des alliés de classe Sabreur et Ambitieux de 3 niveaux et augmente l'ATK des alliés de classe Sabreur et Ambitieux de 2 niveaux.`,
        `Augmente les HP des alliés de classe Sabreur et Ambitieux de 3 niveaux. Pendant 30 secondes à partir du début du combat, augmente la défense des alliés de classe Sabreur et Ambitieux de 3 niveaux et augmente l'ATK des alliés de classe Sabreur et Ambitieux de 3 niveaux.`,
        `Augmente les HP des alliés de classe Sabreur et Ambitieux de 4 niveaux. Pendant 30 secondes à partir du début du combat, augmente la défense des alliés de classe Sabreur et Ambitieux de 3 niveaux et augmente l'ATK des alliés de classe Sabreur et Ambitieux de 4 niveaux.`,
        `Augmente les HP des alliés de classe Sabreur et Ambitieux de 5 niveaux. Pendant 30 secondes à partir du début du combat, augmente la défense des alliés de classe Sabreur et Ambitieux de 3 niveaux et augmente l'ATK des alliés de classe Sabreur et Ambitieux de 5 niveaux.`,
        `Augmente les HP des alliés de classe Sabreur et Ambitieux de 6 niveaux. Pendant 30 secondes à partir du début du combat, augmente la défense des alliés de classe Sabreur et Ambitieux de 3 niveaux et augmente l'ATK des alliés de classe Sabreur et Ambitieux de 6 niveaux.`,
      ],
      capacityAttributes: [
        {
          HP: {
            value: 6,
            targets: [CharacterClass.Sabreur, CharacterClass.Ambitieux],
            fromTime: 100,
            toTime: 0,
          },
          DEF: {
            value: 3,
            targets: [CharacterClass.Sabreur, CharacterClass.Ambitieux],
            fromTime: 100,
            toTime: 70,
          },
          ATK: {
            value: 6,
            targets: [CharacterClass.Sabreur, CharacterClass.Ambitieux],
            fromTime: 100,
            toTime: 70,
          },
        },
      ],
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_URGENCE,
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 10%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 15%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 20%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 30%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Annule le “Lien des cercles” du personnage.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/Doflamingo_skull_f2.png',
      'https://onepiece-treasurecruise.com/wp-content/uploads/Doflamingo_skull_f2.png',
      'https://onepiece-treasurecruise.com/wp-content/uploads/Doflamingo_skull_f2.png',
      'https://onepiece-treasurecruise.com/wp-content/uploads/Doflamingo_skull_f2.png',
      'https://onepiece-treasurecruise.com/wp-content/uploads/Doflamingo_skull_f2.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de classe Ambitieux'],
      levels: [
        `Ajoute 5% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 6% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 8% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 10% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 12% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Jimbei V1
  {
    id: '0935',
    title: `Ancien membre de l'ordre des sept grands corsaires`,
    name: 'Jinbe le paladin des mers',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/GjapGYW.png',
      sixStars: 'https://i.imgur.com/U8oaBOH.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage des hommes-poissons et ancien membre de l'ordre des sept grands corsaires. Alors qu'il était l'un des sept grands corsaires, il a refusé un ordre du gouvernement l'incitant à combattre Barbe Blanche. Il s'est battu à ses côtés pour sauver Ace le condamné.`,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Cogneur, CharacterClass.Tenace],
      combo: 4,
      hp: {
        initial: 3400,
        afterLimitBreak: 3800,
        afterLimitBreakPlus: 4300,
      },
      atk: {
        initial: 1440,
        afterLimitBreak: 1640,
        afterLimitBreakPlus: 1840,
      },
      rcv: {
        initial: 347,
        afterLimitBreak: 397,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Cogneur par 2,75 si les HP sont en-dessous de 30% ou au-dessus de 70%, sinon par 2, et multiplie les HP des personnages de classe Cogneur par 1,5.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie l'ATK des personnages de classe Cogneur par 3 si les HP sont en-dessous de 30% ou au-dessus de 70%, sinon par 2,5, et multiplie les HP des personnages de classe Cogneur par 1,5.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Rend le timing des coups "PARFAIT" plus difficile pendant un tour, inflige 20 fois son ATK en dégâts de type INT sur tous les ennemis et change les cercles des personnages de classe Cogneur en cercles correspondants.`,
      specialTurns: {
        initial: 19,
        max: 14,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 1,
      },
      crewmateAbilities: [
        {
          ability: `Rend les cercles [RCV] et [TND] avantageux pour le personnage.`,
          needLimitBreak: true,
        },
        {
          ability: `Immunise le personnage contre l'inversion de son coup spécial.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: 'https://i.imgur.com/DsdLp1x.png',
        sixStarsPicture: 'https://i.imgur.com/F9McPS9.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.PSY_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type PSY de 1%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 2%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 3%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 4%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 5%.`,
        ],
      },
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 4%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 3 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
          `Annule le “Lien des cercles” du personnage.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0088.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0304.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Monkey D. Luffy'],
      levels: [
        `Réduit les dégâts subis par les ennemis de type PSY de 1% et ajoute 3% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 2% et ajoute 4% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 3% et ajoute 6% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 4% et ajoute 8% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 5% et ajoute 10% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Jimbei V1 6+
  {
    id: '2066',
    title: `Complice de Luffy`,
    name: 'Jinbe le paladin des mers',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/kTtpcpo.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage des hommes-poissons et ancien membre de l'ordre des sept grands corsaires. Convaincu que la paix dans l'océan repose sur la survie d'Ace et de Barbe Blanche, il participe à la guerre au sommet et aide Luffy à secourir son frère Ace, notamment en faisant recracher à Moria les ombres que ce dernier venait d'absorber.`,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Cogneur, CharacterClass.Tenace],
      combo: 4,
      hp: {
        initial: 3980,
        afterLimitBreak: 4380,
        afterLimitBreakPlus: 4880,
      },
      atk: {
        initial: 1484,
        afterLimitBreak: 1684,
        afterLimitBreakPlus: 1884,
      },
      rcv: {
        initial: 352,
        afterLimitBreak: 402,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Cogneur par 3, les HP par 1,5 et réduit les dégâts subis de 7%.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie l'ATK des personnages de classe Cogneur par 3,5, les HP par 1,5 et réduit les dégâts subis de 7%.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Rend le timing des coups "PARFAIT" plus difficile pendant un tour, inflige 20 fois son ATK en dégâts de type INT sur tous les ennemis et change les cercles des personnages de classe Cogneur en cercles correspondants.`,
      specialTurns: {
        initial: 17,
        max: 12,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 1,
      },
      crewmateAbilities: [
        {
          ability: `Rend les cercles [RCV] et [TND] avantageux pour le personnage.`,
          needLimitBreak: true,
        },
        {
          ability: `Immunise le personnage contre l'inversion de son coup spécial.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f2066.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.PSY_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type PSY de 1%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 2%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 3%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 4%.`,
          `Réduit les dégâts subis par les ennemis de type PSY de 5%.`,
        ],
      },
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 4%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 3 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
          `Annule le “Lien des cercles” du personnage.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/black_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0088.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0304.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0304.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Monkey D. Luffy'],
      levels: [
        `Réduit les dégâts subis par les ennemis de type PSY de 1% et ajoute 3% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 2% et ajoute 4% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 3% et ajoute 6% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 4% et ajoute 8% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 5% et ajoute 10% des HP et de l'ATK du personnage en HP et ATK pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Fujitora V1
  {
    id: '1085',
    title: null,
    name: 'Issho',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/UeEr0Xb.png',
      sixStars: 'https://i.imgur.com/kzjDfjS.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Un amiral de la Marine et l'une des personnes les plus puissantes de cette force. Il peut contrôler librement la gravité pour clouer ses ennemis au sol ou invoquer des météores. Il peut également chavaucher des objets flottants pour se déplacer dans les airs.`,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Ambitieux, CharacterClass.Tenace],
      combo: 4,
      hp: {
        initial: 3809,
        afterLimitBreak: 4349,
        afterLimitBreakPlus: 4849,
      },
      atk: {
        initial: 1380,
        afterLimitBreak: 1555,
        afterLimitBreakPlus: 1755,
      },
      rcv: {
        initial: 325,
        afterLimitBreak: 420,
        afterLimitBreakPlus: 470,
      },
      captainAbility: `Multiplie les HP des personnages de classe Ambitieux par 1,5, multiplie l'ATK des personnages de classe Ambitieux par 3 s'ils ont un cercle correspondant, sinon par 1,5, et rend les cercles [TND] et [RCV] avantageux pour les personnages de classe Ambitieux.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie les HP des personnages de classe Ambitieux par 1,5, multiplie l'ATK des personnages de classe Ambitieux par 3,5 s'ils ont un cercle correspondant, sinon par 1,5, et rend les cercles [TND] et [RCV] avantageux pour les personnages de classe Ambitieux.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Enlève 20% des HP de chaque ennemi et pendant 3 tours, enlève 20% des HP de chaque ennemi à la fin de chaque tour.`,
      specialTurns: {
        initial: 25,
        max: 17,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 2,
      },
      crewmateAbilities: [
        {
          ability: `Ne peut pas être projeté en dehors de l'écran.`,
          needLimitBreak: true,
        },
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour les personnages de classe Ambitieux et Tenace.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1084.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1085.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 20 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 40 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 80 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 3 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
          `Annule le “Lien des cercles” du personnage.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0088.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0304.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Sakazuki', 'Borsalino', 'Sengoku', 'Tsuru'],
      levels: [
        `Réduit les dégâts subis par les ennemis de type PSY de 1% et ajoute 3% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 2% et ajoute 5% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 3% et ajoute 7% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 4% et ajoute 10% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 5% et ajoute 13% de l'ATK du personnage en ATK pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Fujitora V1 6+
  {
    id: '2954',
    title: 'Gravité enflammée',
    name: 'Fujitora',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/yOm2KTZ.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Amiral au quartier général de la Marine. Il a affronté Sabo à Dressrosa. Avec l'idée que la Marine ne peut pas être celle qui sauvera Dressrosa, il a parié sur la victoire de Luffy contre Doflamingo, agissant ainsi contre la volonté de la Marine.`,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Ambitieux, CharacterClass.Tenace],
      combo: 4,
      hp: {
        initial: 4147,
        afterLimitBreak: 4687,
        afterLimitBreakPlus: 5187,
      },
      atk: {
        initial: 1510,
        afterLimitBreak: 1685,
        afterLimitBreakPlus: 1885,
      },
      rcv: {
        initial: 350,
        afterLimitBreak: 445,
        afterLimitBreakPlus: 495,
      },
      captainAbility: `Multiplie les HP des personnages de classe Ambitieux par 1,5, multiplie l'ATK des personnages de classe Ambitieux par 3,5 s'ils ont un cercle correspondant, sinon par 3, rend les cercles [TND] et [RCV] avantageux pour les personnages de classe Ambitieux et active le coup spécial du personnage au début de la quête.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie les HP des personnages de classe Ambitieux par 1,5, multiplie l'ATK des personnages de classe Ambitieux par 4 s'ils ont un cercle correspondant, sinon par 3,25, rend les cercles [TND] et [RCV] avantageux pour les personnages de classe Ambitieux et active le coup spécial du personnage au début de la quête.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Enlève 20% des HP de chaque ennemi et change les cercles de type en cercles correspondants. Après l'activation du coup spécial, enlève 20% des HP de chaque ennemi à la fin de chaque tour pendant 99+ tours.`,
      specialTurns: {
        initial: 25,
        max: 17,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 2,
      },
      crewmateAbilities: [
        {
          ability: `Ne peut pas être projeté en dehors de l'écran.`,
          needLimitBreak: true,
        },
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour les personnages de classe Ambitieux et Tenace.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/wp-content/uploads/f2954.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 20 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 40 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 80 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 3 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
          `Annule le “Lien des cercles” du personnage.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/black_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0088.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0304.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Sakazuki', 'Borsalino', 'Sengoku', 'Tsuru'],
      levels: [
        `Réduit les dégâts subis par les ennemis de type PSY de 1% et ajoute 3% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 2% et ajoute 5% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 3% et ajoute 7% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 4% et ajoute 10% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type PSY de 5% et ajoute 13% de l'ATK du personnage en ATK pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Buggy
  {
    id: '1391',
    title: null,
    name: 'Captain Baggy',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/hiwkmwA.png',
      sixStars: 'https://i.imgur.com/UQwGqlU.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage de Baggy le clown. Après avoir survécu à Impel Down et à la guerre au sommet, il rejoint son équipage et agrandit ce dernier en recrutant les détenus avec lesquels il s'est évédé de prison, ce qui lui vaut d'acquérir une grande renommée et de recevoir une lettre du gouvernement.`,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Ambitieux, CharacterClass.Tireur],
      combo: 5,
      hp: {
        initial: 2506,
        afterLimitBreak: 2956,
        afterLimitBreakPlus: 3306,
      },
      atk: {
        initial: 1149,
        afterLimitBreak: 1399,
        afterLimitBreakPlus: 1599,
      },
      rcv: {
        initial: 491,
        afterLimitBreak: 591,
        afterLimitBreakPlus: 666,
      },
      captainAbility: `Multiplie l'ATK des personnages ayant un coût inférieur ou égal à 40 par 1,5, divise l'ATK des personnages ayant un coût supérieur ou égal à 41 par 2 et ajoute un trésor à ceux obtenus lors de la quête.`,
      captainAbilityAfterLimitBreakPlus: 'Inconnue.',
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Mélange les cercles de tous les personnages aléatoirement et multiplie l'ATK des personnages ayant un coût inférieur ou égal à 40 par 1,75 pendant 2 tours.`,
      specialTurns: {
        initial: 17,
        max: 12,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 0,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour les personnages ayant un coût inférieur ou égal à 40.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [INT] avantageux pour tous les personnages.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1390.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1391.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 40,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_URGENCE,
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 10%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 20%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 30%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 40%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
        ],
      },
      {
        type: PotentialAbility.BARRIERE,
        icon: '',
        effects: [
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 90%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 70%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 50%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0088.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0099.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages ayant un coût inférieur ou égal à 40'],
      levels: [
        `Multiplie l'ATK du personnage soutenu par 1,5 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 1,6 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 1,7 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 1,8 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 2 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Buggy 6+
  {
    id: '2035',
    title: null,
    name: 'Baggy le Clown aux mille pièces',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/BlqAnyy.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage de Baggy. Profitant du titre de grand corsaire qu'il a obtenu suite à la guerre au sommet, il a monté une véritable armée de malfrats en tant que président de Baggy's Delivery, une société dont les employés sont légalement habilités à commettre des actes de piraterie.`,
      type: CharacterTypeClass.INT,
      classes: [CharacterClass.Ambitieux, CharacterClass.Tireur],
      combo: 4,
      hp: {
        initial: 2710,
        afterLimitBreak: 3160,
        afterLimitBreakPlus: 3510,
      },
      atk: {
        initial: 1201,
        afterLimitBreak: 1451,
        afterLimitBreakPlus: 1651,
      },
      rcv: {
        initial: 498,
        afterLimitBreak: 598,
        afterLimitBreakPlus: 673,
      },
      captainAbility: `Multiplie l'ATK des personnages ayant un coût inférieur ou égal à 40 par 1,75, divise l'ATK des personnages ayant un coût supérieur ou égal à 41 par 2 et ajoute un trésor à ceux obtenus lors de la quête.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie l'ATK des personnages ayant un coût inférieur ou égal à 40 par 2,25, divise l'ATK des personnages ayant un coût supérieur ou égal à 41 par 2 et ajoute un trésor à ceux obtenus lors de la quête.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Multiplie l'ATK des personnages ayant un coût inférieur ou égal à 40 par 2 pendant 2 tours.`,
      specialTurns: {
        initial: 16,
        max: 11,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 0,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour les personnages ayant un coût inférieur ou égal à 40.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [INT] avantageux pour tous les personnages.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f2035.png',
      },
    },
    sockets: 5,
    cost: 40,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_URGENCE,
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 10%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 20%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 30%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 40%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
        ],
      },
      {
        type: PotentialAbility.BARRIERE,
        icon: '',
        effects: [
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 90%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 70%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 50%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/black_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0088.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0304.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages ayant un coût inférieur ou égal à 40'],
      levels: [
        `Multiplie l'ATK du personnage soutenu par 1,5 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 1,6 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 1,7 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 1,8 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 2 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
];
