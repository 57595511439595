export const glbArenas = [
  '3145',
  '3189',
  '3210',
  '3235',
  '3269',
  '3293',
  '3329',
  '3361',
  '3385',
  '3408',
  '3460',
  '3499',
];

export const japArenas = [];

export const arenas = [...glbArenas, ...japArenas];
