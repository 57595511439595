export const gloAmbushs = [
  '1258',
  '1530',
  '1846',
  '2006',
  '2283',
  '2381',
  '2886',
  '2923',
  '3354',
  '3172',
];

export const japAmbushs = [];

export const ambushs = [...gloAmbushs, ...japAmbushs];
