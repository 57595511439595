export const gloLrrs = [
  '1621',
  '1632',
  '1665',
  '1678',
  '1713',
  '1721',
  '1770',
  '1778',
  '1824',
  '1859',
  '1895',
  '1926',
  '1974',
  '2013',
  '2048',
  '2050',
  '2068',
  '2161',
  '2199',
  '2247',
  '2285',
  '2325',
  '2349',
  '2431',
  '2463',
  '2487',
  '2515',
  '2575',
  '2696',
  '2731',
  '2892',
  '2914',
  '2919',
  '3051',
  '3097',
];

export const japLrrs = [];

export const lrrs = [...gloLrrs, ...japLrrs];
