export enum TierListCharacterType {
  LEGEND = 'LEGEND',
  SIX_PLUS_LEGEND = 'SIX_PLUS_LEGEND',
  JAP_LEGEND = 'JAP_LEGEND',
  JAP_SIX_PLUS_LEGEND = 'JAP_SIX_PLUS_LEGEND',
  RR = 'RR',
  LRR = 'LRR',
  PERIOD_LRR = 'PERIOD_LRR',
  KIZUNA_LRR = 'KIZUNA_LRR',
  COLOSSEUM = 'COLOSSEUM',
  ARENA = 'ARENA',
  RAID = 'RAID',
  TM = 'TM',
  TM_LRR = 'TM_LRR',
  PVP_F2P = 'PVP_F2P',
  PVP = 'PVP',
  SUPPORT = 'SUPPORT',
  KIZUNA = 'KIZUNA',
  AMBUSH = 'AMBUSH',
  SPECIAL = 'SPECIAL',
}
