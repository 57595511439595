export const gloPvpLrrs = [
  '2931',
  '2932',
  '2933',
  '2978',
  '3000',
  '3025',
  '3061',
  '3062',
  '3063',
  '3095',
  '3096',
  '3125',
  '3163',
  '3130',
  '3206',
  '3243',
  '3292',
  '3328',
  '3362',
  '3395',
  '3409',
  '3428',
  '3458',
  '3459',
  '3480',
  '3501',
  '3521',
  '3541',
  '3561',
  '3566',
  '3583',
  '3605',
  '3638',
  '3639',
  '3658',
  '3679',
  '3703',
  '3704',
  '3731',
  '3753',
  '3797',
  '3817',
  '3818',
  '3836',
  '3837',
];

export const japPvpLrrs = [];

export const pvpLrrs = [...gloPvpLrrs, ...japPvpLrrs];
