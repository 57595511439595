export const gloPeriodsLrrs = [
  '0438',
  '0440',
  '0498',
  '0513',
  '0532',
  '0534',
  '0536',
  '0548',
  '0783',
  '0829',
  '0856',
  '0858',
  '0916',
  '0922',
  '0945',
  '0976',
  '1008',
  '1021',
  '1054',
  '1093',
  '1100',
  '1135',
  '1161',
  '1194',
  '1214',
  '1249',
  '1260',
  '1262',
  '1264',
  '1266',
  '1296',
  '1308',
  '1336',
  '1355',
  '1382',
  '1395',
  '1420',
  '1430',
  '1454',
  '1467',
  '1514',
  '1524',
  '1545',
  '1568',
  '1590',
  '1592',
  '1674',
  '1731',
  '1912',
  '1914',
  '2039',
  '2041',
  '2084',
  '2086',
  '2191',
  '2213',
  '2287',
  '2401',
  '2403',
  '2405',
  '2410',
  '2412',
  '2414',
  '2496',
  '2498',
  '2546',
  '2548',
  '2585',
  '2587',
  '2620',
  '2622',
  '2624',
  '2626',
  '2663',
  '2664',
  '2734',
  '2735',
  '2736',
  '2764',
  '2765',
  '2769',
  '2771',
  '2798',
  '2816',
  '2817',
  '2825',
  '2827',
  '2829',
  '2856',
  '2857',
  '2858',
  '2870',
  '2871',
  '2872',
  '2873',
  '2874',
  '2973',
  '2974',
  '3361',
  '3023',
  '3024',
  '3085',
  '3198',
  '3199',
  '3075',
  '3077',
  '3122',
  '3123',
  '3127',
  '3129',
  '3219',
  '3221',
  '3246',
  '3248',
  '3249',
  '3340',
  '3341',
  '3342',
];

export const japPeriodsLrrs = [];

export const periodsLrrs = [...gloPeriodsLrrs, ...japPeriodsLrrs];
