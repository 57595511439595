export const gloTmLrrs = [
  '1804',
  '1806',
  '1849',
  '1851',
  '1885',
  '1887',
  '1937',
  '1939',
  '1968',
  '1970',
  '2009',
  '2011',
  '2060',
  '2062',
  '2132',
  '2134',
  '2135',
  '2169',
  '2171',
  '2173',
  '2207',
  '2209',
  '2256',
  '2257',
  '2259',
  '2295',
  '2296',
  '2297',
  '2358',
  '2359',
  '2360',
  '2383',
  '2384',
  '2385',
  '2437',
  '2438',
  '2466',
  '2467',
  '2506',
  '2507',
  '2508',
  '2553',
  '2554',
  '2555',
  '2579',
  '2580',
  '2581',
  '2612',
  '2613',
  '2614',
  '2687',
  '2688',
  '2725',
  '2726',
  '2727',
  '2759',
  '2760',
  '2761',
  '2790',
  '2821',
  '2822',
  '2847',
  '2848',
  '2849',
  '2876',
  '2877',
  '2878',
  '2910',
  '2911',
  '2912',
  '2992',
  '2993',
  '2994',
  '3019',
  '3020',
  '3021',
  '3057',
  '3059',
  '3087',
  '3088',
  '3089',
  '3112',
  '3113',
  '3114',
  '3146',
  '3147',
  '3148',
  '3190',
  '3191',
  '3192',
  '3214',
  '3215',
  '3216',
  '3236',
  '3237',
  '3238',
  '3260',
  '3261',
  '3262',
  '3294',
  '3295',
  '3296',
  '3386',
  '3387',
  '3388',
  '3412',
  '3413',
  '3414',
  '3439',
  '3440',
  '3441',
  '3442',
  '3502',
  '3503',
  '3504',
  '3661',
  '3660',
  '3659',
  '3365',
  '3366',
  '3367',
  '3463',
  '3464',
  '3484',
  '3485',
  '3524',
  '3525',
  '3544',
  '3545',
  '3564',
  '3565',
  '3584',
  '3585',
  '3586',
  '3642',
  '3643',
  '3686',
  '3687',
  '3688',
  '3732',
  '3733',
  '3776',
  '3777',
  '3798',
  '3799',
  '3800',
  '3819',
  '3820',
  '3821',
];

export const japTmLrrs = [];

export const tmLrrs = [...gloTmLrrs, ...japTmLrrs];
