export const gloSoutiens = [
  '2388',
  '2389',
  '2390',
  '2391',
  '2392',
  '2435',
  '2436',
  '2460',
  '2461',
  '2501',
  '2502',
  '2772',
  '2800',
  '2851',
  '5053',
  '3159',
  '3160',
  '3161',
  '3162',
  '3200',
  '3201',
  '3265',
  '3266',
  '3267',
  '3268',
  '3465',
  '3500',
  '3530',
  '3570',
  '3622',
  '3667',
  '3717',
  '3759',
  '3802',
  '3846',
  '5053',
];

export const japSoutiens = [];

export const soutiens = [...gloSoutiens, ...japSoutiens];
