<div class="tier-list-actions-container">
  <div>
    <button mat-button (click)="share()">
      <mat-icon>share</mat-icon>
      <ng-container *ngIf="language === 'FR'"
        >Partager ma Tier List</ng-container
      >
      <ng-container *ngIf="language === 'EN'">Share my Tier List</ng-container>
    </button>

    <button mat-button (click)="onExportTierList(false)">
      <mat-icon>save_alt</mat-icon>
      <ng-container *ngIf="language === 'FR'"
        >Exporter ma Tier List sous forme d'image</ng-container
      >
      <ng-container *ngIf="language === 'EN'"
        >Export my Tier List as a picture</ng-container
      >
    </button>

    <button mat-button (click)="onExportTierList(true)">
      <mat-icon>save_alt</mat-icon>
      <ng-container *ngIf="language === 'FR'"
        >Exporter ma Tier List sous forme d'image sans voir le dernier
        Tier</ng-container
      >
      <ng-container *ngIf="language === 'EN'"
        >Export my Tier List as a picture hiding the last Tier</ng-container
      >
    </button>

    <button mat-button (click)="onExportAllTierLists()">
      <mat-icon>save_alt</mat-icon>
      <ng-container *ngIf="language === 'FR'"
        >Exporter toutes mes Tier Lists sauvegardées au format
        JSON</ng-container
      >
      <ng-container *ngIf="language === 'EN'"
        >Export all my saved Tier List in JSON format</ng-container
      >
    </button>
  </div>

  <div>
    <button
      mat-button
      [ngClass]="{ active: loadingTierList }"
      (click)="onLoadTierList()"
    >
      <mat-icon>cloud_download</mat-icon>
      <ng-container *ngIf="language === 'FR'"
        >Charger une Tier List</ng-container
      >
      <ng-container *ngIf="language === 'EN'">Load Tier List</ng-container>
    </button>

    <button
      mat-button
      [ngClass]="{ active: removingTierList }"
      class="warn"
      (click)="onRemoveTierList()"
    >
      <mat-icon>delete</mat-icon>
      <ng-container *ngIf="language === 'FR'"
        >Supprimer une Tier List</ng-container
      >
      <ng-container *ngIf="language === 'EN'">Delete Tier List</ng-container>
    </button>
  </div>

  <div *ngIf="loadingTierList">
    <p *ngIf="language === 'FR'">
      Veuillez sélectionner une Tier List à charger
    </p>
    <p *ngIf="language === 'EN'">Select a Tier List to load</p>
    <button
      *ngFor="let tierList of loadedTierLists"
      mat-button
      (click)="selectTierListToLoad(tierList)"
    >
      {{ tierList.name }}
    </button>
  </div>

  <div *ngIf="removingTierList">
    <p *ngIf="language === 'FR'">
      Veuillez sélectionner une Tier List à supprimer
    </p>
    <p *ngIf="language === 'EN'">Select a Tier List to remove</p>
    <button
      *ngFor="let tierList of loadedTierLists"
      mat-button
      (click)="selectTierListToRemove(tierList)"
    >
      {{ tierList.name }}
    </button>
  </div>
</div>
