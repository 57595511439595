<br />
<select
  [value]="selectedLegendOrder"
  (change)="updateOrder($event.target.value)"
>
  <option value="color">Par couleur</option>
  <option value="id">Par ID</option></select
><br />
<ul class="legends-list">
  <li *ngFor="let l of legends" (click)="selectLegend(l.id)">
    <img
      *ngIf="l.isDual"
      [src]="l.dualCharacterData.finalForm.picture.sixStarsPicture"
      width="70"
    />
    <img
      *ngIf="!l.isDual"
      [src]="
        l.singleCharacterData.picture.sixStarsPicture ||
        l.singleCharacterData.picture.sixPlusStarsPicture
      "
      width="70"
    />
  </li>
</ul>

<ng-container *ngIf="legend$ | async; let legend">
  <section *ngIf="!legend.isDual" name="singleCharacter">
    <div style="text-align: center">
      <h1>
        <u>{{ legend.name }}</u>
      </h1>
    </div>
    <div style="text-align: center">
      <h2><u>Fiche</u></h2>
    </div>
    <div style="text-align: center">
      <img
        *ngIf="legend.singleCharacterData.picture.fiveStarsPicture"
        [src]="legend.singleCharacterData.picture.fiveStarsPicture"
      />
      <img
        *ngIf="legend.singleCharacterData.picture.sixStarsPicture"
        [src]="legend.singleCharacterData.picture.sixStarsPicture"
      />
      <img
        *ngIf="legend.singleCharacterData.picture.sixPlusStarsPicture"
        [src]="legend.singleCharacterData.picture.sixPlusStarsPicture"
      />
    </div>
    <div style="text-align: center">
      <img
        *ngIf="legend.artwork.fiveStars"
        height="50%"
        [src]="legend.artwork.fiveStars"
        width="50%"
        style="max-width: 640px"
      />
      <img
        *ngIf="legend.artwork.sixStars"
        height="50%"
        [src]="legend.artwork.sixStars"
        width="50%"
        style="max-width: 640px"
      />
      <img
        *ngIf="legend.artwork.sixPlusStars"
        height="50%"
        [src]="legend.artwork.sixPlusStars"
        width="50%"
        style="max-width: 640px"
      />
    </div>

    <table>
      <tr></tr>
      <tbody>
        <tr>
          <td>Nom</td>
          <td>
            <b
              ><small>{{ legend.title }}</small
              ><br />{{ legend.name }}</b
            >
          </td>
        </tr>
        <tr>
          <td>Histoire</td>
          <td>{{ legend.singleCharacterData.story }}</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>
            <b></b>
            <p>
              <b [ngStyle]="color(legend.singleCharacterData.type)">{{
                legend.singleCharacterData.type
              }}</b>
            </p>
            <b><b></b></b>
          </td>
        </tr>
        <tr>
          <td>Classe 1</td>
          <td>
            {{ legend.singleCharacterData.classes[0] }}
          </td>
        </tr>
        <tr>
          <td>Classe 2</td>
          <td>
            {{ legend.singleCharacterData.classes[1] }}
          </td>
        </tr>
        <tr>
          <td>Coût</td>
          <td>{{ legend.cost }}</td>
        </tr>
        <tr>
          <td>Combo</td>
          <td>
            {{ legend.singleCharacterData.combo }}
          </td>
        </tr>
        <tr>
          <td>Emplacements de pouvoirs</td>
          <td>{{ legend.sockets }}</td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="text-align: center">
              <b>Statistiques</b>
            </div>
          </td>
        </tr>
        <tr>
          <td>HP</td>
          <td>
            {{ legend.singleCharacterData.hp.initial }} puis
            {{ legend.singleCharacterData.hp.afterLimitBreak }} après le
            dépassement de limites
            <span *ngIf="legend.hasLimitBreakPlus"
              >puis
              {{ legend.singleCharacterData.hp.afterLimitBreakPlus }} après
              l'extension du dépassement de limites</span
            ><br />
          </td>
        </tr>
        <tr>
          <td>ATK</td>
          <td>
            {{ legend.singleCharacterData.atk.initial }} puis
            {{ legend.singleCharacterData.atk.afterLimitBreak }} après le
            dépassement de limites
            <span *ngIf="legend.hasLimitBreakPlus"
              >puis
              {{ legend.singleCharacterData.atk.afterLimitBreakPlus }}
              après l'extension du dépassement de limites</span
            ><br />
          </td>
        </tr>
        <tr>
          <td>RCV</td>
          <td>
            {{ legend.singleCharacterData.rcv.initial }} puis
            {{ legend.singleCharacterData.rcv.afterLimitBreak }} après le
            dépassement de limites
            <span *ngIf="legend.hasLimitBreakPlus"
              >puis
              {{ legend.singleCharacterData.rcv.afterLimitBreakPlus }}
              après l'extension du dépassement de limites</span
            ><br />
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="text-align: center">
              <b>Abilités</b>
            </div>
          </td>
        </tr>
        <tr>
          <td>Captain Ability</td>
          <td>
            {{ legend.singleCharacterData.captainAbility }}
            <ng-container
              *ngIf="
                legend.singleCharacterData.captainAbilityAdditionnalInformation
              "
            >
              <br />
              <small>{{
                legend.singleCharacterData.captainAbilityAdditionnalInformation
              }}</small>
            </ng-container>
          </td>
        </tr>

        <tr
          *ngIf="
            legend.hasLimitBreakPlus &&
            legend.singleCharacterData.captainAbilityAfterLimitBreakPlus
          "
        >
          <td>Captain Ability (après l'extension du dépassement de limites)</td>
          <td>
            {{ legend.singleCharacterData.captainAbilityAfterLimitBreakPlus }}
            <ng-container
              *ngIf="
                legend.singleCharacterData
                  .captainAbilityAfterLimitBreakPlusAdditionnalInformation
              "
            >
              <br />
              <small>{{
                legend.singleCharacterData
                  .captainAbilityAfterLimitBreakPlusAdditionnalInformation
              }}</small>
            </ng-container>
          </td>
        </tr>
        <tr *ngIf="legend.singleCharacterData.captainAction">
          <td>
            Action de capitaine ({{
              legend.singleCharacterData.captainActionTurns
            }}
            tours)
          </td>
          <td>{{ legend.singleCharacterData.captainAction }}</td>
        </tr>
        <tr *ngIf="!legend.singleCharacterData.specialWithStages">
          <td>
            Special ({{ legend.singleCharacterData.specialTurns.initial }}→{{
              legend.singleCharacterData.specialTurns.max
            }}
            <span
              *ngIf="
                legend.singleCharacterData.specialTurns.gainWithLimitBreak &&
                !legend.singleCharacterData.specialTurns.gainWithLimitBreakPlus
              "
            >
              puis
              {{
                legend.singleCharacterData.specialTurns.max -
                  legend.singleCharacterData.specialTurns.gainWithLimitBreak
              }}</span
            >
            <span
              *ngIf="
                legend.singleCharacterData.specialTurns
                  .gainWithLimitBreakPlus &&
                !legend.singleCharacterData.specialTurns.gainWithLimitBreak
              "
            >
              puis
              {{
                legend.singleCharacterData.specialTurns.max -
                  legend.singleCharacterData.specialTurns.gainWithLimitBreakPlus
              }}</span
            >
            <span
              *ngIf="
                legend.singleCharacterData.specialTurns
                  .gainWithLimitBreakPlus &&
                legend.singleCharacterData.specialTurns.gainWithLimitBreak
              "
            >
              puis
              {{
                legend.singleCharacterData.specialTurns.max -
                  legend.singleCharacterData.specialTurns.gainWithLimitBreak
              }}
              puis
              {{
                legend.singleCharacterData.specialTurns.max -
                  legend.singleCharacterData.specialTurns.gainWithLimitBreak -
                  legend.singleCharacterData.specialTurns.gainWithLimitBreakPlus
              }}</span
            >)
          </td>
          <td>
            {{ legend.singleCharacterData.special }}
          </td>
        </tr>
        <ng-container
          *ngIf="legend.singleCharacterData.specialWithStages"
        ></ng-container>
        <tr
          *ngFor="
            let special of legend.singleCharacterData.specialWithStages;
            let i = index
          "
        >
          <td>
            Special - Stage {{ i + 1 }} ({{ special.specialTurns.initial }}→{{
              special.specialTurns.max
            }}
            <span
              *ngIf="
                special.specialTurns.gainWithLimitBreak &&
                !special.specialTurns.gainWithLimitBreakPlus
              "
            >
              puis
              {{
                special.specialTurns.max -
                  special.specialTurns.gainWithLimitBreak
              }}</span
            >
            <span
              *ngIf="
                special.specialTurns.gainWithLimitBreakPlus &&
                !special.specialTurns.gainWithLimitBreak
              "
            >
              puis
              {{
                special.specialTurns.max -
                  special.specialTurns.gainWithLimitBreakPlus
              }}</span
            >
            <span
              *ngIf="
                special.specialTurns.gainWithLimitBreakPlus &&
                !special.specialTurns.gainWithLimitBreak
              "
            >
              puis
              {{
                special.specialTurns.max -
                  special.specialTurns.gainWithLimitBreak
              }}
              puis
              {{
                special.specialTurns.max -
                  special.specialTurns.gainWithLimitBreak -
                  special.specialTurns.gainWithLimitBreakPlus
              }}</span
            >)
          </td>
          <td>
            {{ special.special }}
          </td>
        </tr>
        <tr
          *ngIf="
            !legend.singleCharacterData.crewmateAbilities[0].needLimitBreak
          "
        >
          <td>Crewmate Ability 1</td>
          <td>
            {{ legend.singleCharacterData.crewmateAbilities[0].ability }}
          </td>
        </tr>
        <tr
          *ngIf="
            legend.singleCharacterData.crewmateAbilities[1] &&
            !legend.singleCharacterData.crewmateAbilities[1].needLimitBreak
          "
        >
          <td>Crewmate Ability 2</td>
          <td>
            {{ legend.singleCharacterData.crewmateAbilities[1].ability }}
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="text-align: center">
              <b>Dépassement de limites</b>
            </div>
          </td>
        </tr>
        <tr
          *ngIf="legend.singleCharacterData.crewmateAbilities[0].needLimitBreak"
        >
          <td>Crewmate Ability 1</td>
          <td>
            {{ legend.singleCharacterData.crewmateAbilities[0].ability }}
          </td>
        </tr>
        <tr
          *ngIf="
            legend.singleCharacterData.crewmateAbilities[1] &&
            legend.singleCharacterData.crewmateAbilities[1].needLimitBreak
          "
        >
          <td>Crewmate Ability 2</td>
          <td>
            {{ legend.singleCharacterData.crewmateAbilities[1].ability }}
          </td>
        </tr>
        <tr>
          <td>Potential Ability 1</td>
          <td>{{ legend.potentialAbilities[0].effects[0] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[0].effects[1] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[0].effects[2] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[0].effects[3] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[0].effects[4] }}</td>
        </tr>
        <tr>
          <td>Potential Ability 2</td>
          <td>{{ legend.potentialAbilities[1].effects[0] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[1].effects[1] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[1].effects[2] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[1].effects[3] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[1].effects[4] }}</td>
        </tr>
        <tr>
          <td>Potential Ability 3</td>
          <td>{{ legend.potentialAbilities[2].effects[0] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[2].effects[1] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[2].effects[2] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[2].effects[3] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[2].effects[4] }}</td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="text-align: center">
              <b>Effets de soutien</b>
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            Personnages soutenus : <br />
            <div
              style="margin-left: 40px"
              *ngFor="
                let supportedCharacter of legend.support.supportedCharacters
              "
            >
              {{ supportedCharacter }}
            </div>
          </td>
        </tr>
        <tr *ngFor="let support of legend.support.levels">
          <td></td>
          <td>{{ support }}</td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="text-align: center">
              <b>Évolueurs requis</b>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="text-align: center">
              <img height="8%" [src]="legend.evolvers[0]" width="8%" />
              <img
                height="8%"
                style="margin-left: 5px"
                [src]="legend.evolvers[1]"
                width="8%"
              />
              <img
                height="8%"
                style="margin-left: 5px"
                [src]="legend.evolvers[2]"
                width="8%"
              />
              <img
                height="8%"
                style="margin-left: 5px"
                [src]="legend.evolvers[3]"
                width="8%"
              />
              <img
                height="8%"
                style="margin-left: 5px"
                [src]="legend.evolvers[4]"
                width="8%"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </section>

  <section *ngIf="legend.isDual" name="dualCharacter">
    <div style="text-align: center">
      <h1>
        <u>{{ legend.name }}</u>
      </h1>
    </div>
    <div style="text-align: center">
      <h2><u>Fiche</u></h2>
    </div>
    <div style="text-align: center">
      <img
        *ngIf="legend.dualCharacterData.finalForm.picture.fiveStarsPicture"
        [src]="legend.dualCharacterData.finalForm.picture.fiveStarsPicture"
      />
      <img
        *ngIf="legend.dualCharacterData.finalForm.picture.sixStarsPicture"
        [src]="legend.dualCharacterData.finalForm.picture.sixStarsPicture"
      />
      <img
        *ngIf="legend.dualCharacterData.finalForm.picture.sixPlusStarsPicture"
        [src]="legend.dualCharacterData.finalForm.picture.sixPlusStarsPicture"
      />
    </div>
    <div style="text-align: center">
      <img
        *ngIf="legend.dualCharacterData.firstForm.picture.fiveStarsPicture"
        [src]="legend.dualCharacterData.firstForm.picture.fiveStarsPicture"
      />
      <img
        *ngIf="legend.dualCharacterData.secondForm.picture.fiveStarsPicture"
        [src]="legend.dualCharacterData.secondForm.picture.fiveStarsPicture"
      />
      <img
        *ngIf="legend.dualCharacterData.firstForm.picture.sixStarsPicture"
        [src]="legend.dualCharacterData.firstForm.picture.sixStarsPicture"
      />
      <img
        *ngIf="legend.dualCharacterData.secondForm.picture.sixStarsPicture"
        [src]="legend.dualCharacterData.secondForm.picture.sixStarsPicture"
      />
      <img
        *ngIf="legend.dualCharacterData.firstForm.picture.sixPlusStarsPicture"
        [src]="legend.dualCharacterData.firstForm.picture.sixPlusStarsPicture"
      />
      <img
        *ngIf="legend.dualCharacterData.secondForm.picture.sixPlusStarsPicture"
        [src]="legend.dualCharacterData.secondForm.picture.sixPlusStarsPicture"
      />
    </div>
    <div style="text-align: center">
      <img
        *ngIf="legend.artwork.fiveStars"
        height="50%"
        [src]="legend.artwork.fiveStars"
        width="50%"
        style="max-width: 640px"
      />
      <img
        *ngIf="legend.artwork.sixStars"
        height="50%"
        [src]="legend.artwork.sixStars"
        width="50%"
        style="max-width: 640px"
      />
      <img
        *ngIf="legend.artwork.sixPlusStars"
        height="50%"
        [src]="legend.artwork.sixPlusStars"
        width="50%"
        style="max-width: 640px"
      />
    </div>

    <table>
      <tr></tr>
      <tbody>
        <tr>
          <td>Nom</td>
          <td>
            <b
              ><small>{{ legend.title }}</small
              ><br />{{ legend.name }}</b
            >
          </td>
        </tr>
        <tr>
          <td>Histoire</td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            : {{ legend.dualCharacterData.firstForm.story }}<br />
            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            : {{ legend.dualCharacterData.secondForm.story }}<br />
            <b
              class="dual-name"
              [ngStyle]="
                gradient(
                  legend.dualCharacterData.firstForm.type,
                  legend.dualCharacterData.secondForm.type
                )
              "
              >{{ legend.dualCharacterData.finalForm.name }}</b
            >
            :
            {{ legend.dualCharacterData.finalForm.story }}
          </td>
        </tr>
        <tr>
          <td>Type</td>
          <td>
            <p>
              <b
                ><span
                  [ngStyle]="color(legend.dualCharacterData.firstForm.type)"
                  >{{ legend.dualCharacterData.firstForm.type }}</span
                >
                ({{ legend.dualCharacterData.firstForm.name }})
              </b>
              /
              <b
                ><span
                  [ngStyle]="color(legend.dualCharacterData.secondForm.type)"
                  >{{ legend.dualCharacterData.secondForm.type }}</span
                >
                ({{ legend.dualCharacterData.secondForm.name }})
              </b>
            </p>
          </td>
        </tr>
        <tr>
          <td>Classe 1</td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            : {{ legend.dualCharacterData.firstForm.classes[0] }}<br />

            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            : {{ legend.dualCharacterData.secondForm.classes[0] }}<br />

            <b
              class="dual-name"
              [ngStyle]="
                gradient(
                  legend.dualCharacterData.firstForm.type,
                  legend.dualCharacterData.secondForm.type
                )
              "
              >{{ legend.dualCharacterData.finalForm.name }}</b
            >
            : {{ legend.dualCharacterData.finalForm.classes[0] }}
          </td>
        </tr>
        <tr>
          <td>Classe 2</td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            : {{ legend.dualCharacterData.firstForm.classes[1] }}<br />

            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            : {{ legend.dualCharacterData.secondForm.classes[1] }}<br />

            <b
              class="dual-name"
              [ngStyle]="
                gradient(
                  legend.dualCharacterData.firstForm.type,
                  legend.dualCharacterData.secondForm.type
                )
              "
              >{{ legend.dualCharacterData.finalForm.name }}</b
            >
            : {{ legend.dualCharacterData.finalForm.classes[1] }}
          </td>
        </tr>
        <tr>
          <td>Coût</td>
          <td>{{ legend.cost }}</td>
        </tr>
        <tr>
          <td>Combo</td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            : {{ legend.dualCharacterData.firstForm.combo }}<br />

            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            : {{ legend.dualCharacterData.secondForm.combo }}<br />

            <b
              class="dual-name"
              [ngStyle]="
                gradient(
                  legend.dualCharacterData.firstForm.type,
                  legend.dualCharacterData.secondForm.type
                )
              "
              >{{ legend.dualCharacterData.finalForm.name }}</b
            >
            : {{ legend.dualCharacterData.finalForm.combo }}
          </td>
        </tr>
        <tr>
          <td>Emplacements de pouvoirs</td>
          <td>{{ legend.sockets }}</td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="text-align: center">
              <b>Statistiques</b>
            </div>
          </td>
        </tr>
        <tr>
          <td>HP</td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            : {{ legend.dualCharacterData.firstForm.hp.initial }} puis
            {{ legend.dualCharacterData.firstForm.hp.afterLimitBreak }} après le
            dépassement de limites
            <span *ngIf="legend.hasLimitBreakPlus"
              >puis
              {{ legend.dualCharacterData.firstForm.hp.afterLimitBreakPlus }}
              après l'extension du dépassement de limites</span
            ><br />
            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            : {{ legend.dualCharacterData.secondForm.hp.initial }} puis
            {{ legend.dualCharacterData.secondForm.hp.afterLimitBreak }} après
            le dépassement de limites
            <span *ngIf="legend.hasLimitBreakPlus"
              >puis
              {{ legend.dualCharacterData.secondForm.hp.afterLimitBreakPlus }}
              après l'extension du dépassement de limites</span
            ><br />
            <b
              class="dual-name"
              [ngStyle]="
                gradient(
                  legend.dualCharacterData.firstForm.type,
                  legend.dualCharacterData.secondForm.type
                )
              "
              >{{ legend.dualCharacterData.finalForm.name }}</b
            >
            : {{ legend.dualCharacterData.finalForm.hp.initial }} puis
            {{ legend.dualCharacterData.finalForm.hp.afterLimitBreak }} après le
            dépassement de limites
            <span *ngIf="legend.hasLimitBreakPlus"
              >puis
              {{ legend.dualCharacterData.finalForm.hp.afterLimitBreakPlus }}
              après l'extension du dépassement de limites</span
            ><br />
          </td>
        </tr>
        <tr>
          <td>ATK</td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            : {{ legend.dualCharacterData.firstForm.atk.initial }} puis
            {{ legend.dualCharacterData.firstForm.atk.afterLimitBreak }} après
            le dépassement de limites
            <span *ngIf="legend.hasLimitBreakPlus"
              >puis
              {{ legend.dualCharacterData.firstForm.atk.afterLimitBreakPlus }}
              après l'extension du dépassement de limites</span
            ><br />
            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            : {{ legend.dualCharacterData.secondForm.atk.initial }} puis
            {{ legend.dualCharacterData.secondForm.atk.afterLimitBreak }} après
            le dépassement de limites
            <span *ngIf="legend.hasLimitBreakPlus"
              >puis
              {{ legend.dualCharacterData.secondForm.atk.afterLimitBreakPlus }}
              après l'extension du dépassement de limites</span
            ><br />
            <b
              class="dual-name"
              [ngStyle]="
                gradient(
                  legend.dualCharacterData.firstForm.type,
                  legend.dualCharacterData.secondForm.type
                )
              "
              >{{ legend.dualCharacterData.finalForm.name }}</b
            >
            : {{ legend.dualCharacterData.finalForm.atk.initial }} puis
            {{ legend.dualCharacterData.finalForm.atk.afterLimitBreak }} après
            le dépassement de limites
            <span *ngIf="legend.hasLimitBreakPlus"
              >puis
              {{ legend.dualCharacterData.finalForm.atk.afterLimitBreakPlus }}
              après l'extension du dépassement de limites</span
            ><br />
          </td>
        </tr>
        <tr>
          <td>RCV</td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            : {{ legend.dualCharacterData.firstForm.rcv.initial }} puis
            {{ legend.dualCharacterData.firstForm.rcv.afterLimitBreak }} après
            le dépassement de limites
            <span *ngIf="legend.hasLimitBreakPlus"
              >puis
              {{ legend.dualCharacterData.firstForm.rcv.afterLimitBreakPlus }}
              après l'extension du dépassement de limites</span
            ><br />
            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            : {{ legend.dualCharacterData.secondForm.rcv.initial }} puis
            {{ legend.dualCharacterData.secondForm.rcv.afterLimitBreak }} après
            le dépassement de limites
            <span *ngIf="legend.hasLimitBreakPlus"
              >puis
              {{ legend.dualCharacterData.secondForm.rcv.afterLimitBreakPlus }}
              après l'extension du dépassement de limites</span
            ><br />
            <b
              class="dual-name"
              [ngStyle]="
                gradient(
                  legend.dualCharacterData.firstForm.type,
                  legend.dualCharacterData.secondForm.type
                )
              "
              >{{ legend.dualCharacterData.finalForm.name }}</b
            >
            : {{ legend.dualCharacterData.finalForm.rcv.initial }} puis
            {{ legend.dualCharacterData.finalForm.rcv.afterLimitBreak }} après
            le dépassement de limites
            <span *ngIf="legend.hasLimitBreakPlus"
              >puis
              {{ legend.dualCharacterData.finalForm.rcv.afterLimitBreakPlus }}
              après l'extension du dépassement de limites</span
            ><br />
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="text-align: center">
              <b>Abilités</b>
            </div>
          </td>
        </tr>
        <tr>
          <td>Captain Ability</td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            : {{ legend.dualCharacterData.firstForm.captainAbility }}<br />
            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            : {{ legend.dualCharacterData.secondForm.captainAbility }}<br />
            <b
              class="dual-name"
              [ngStyle]="
                gradient(
                  legend.dualCharacterData.firstForm.type,
                  legend.dualCharacterData.secondForm.type
                )
              "
              >{{ legend.dualCharacterData.finalForm.name }}</b
            >
            : {{ legend.dualCharacterData.finalForm.captainAbility }}<br />
            <small>{{
              legend.dualCharacterData.finalForm
                .captainAbilityAdditionnalInformation
            }}</small>
          </td>
        </tr>
        <tr *ngIf="legend.dualCharacterData.hasOnlyOneSpecial">
          <td>
            Special ({{ legend.dualCharacterData.specialTurns.initial }}→{{
              legend.dualCharacterData.specialTurns.max
            }}
            <span
              *ngIf="legend.dualCharacterData.specialTurns.gainWithLimitBreak"
            >
              puis
              {{
                legend.dualCharacterData.specialTurns.max -
                  legend.dualCharacterData.specialTurns.gainWithLimitBreak
              }}</span
            >)
          </td>

          <td>
            {{ legend.dualCharacterData.special }}
          </td>
        </tr>
        <tr *ngIf="!legend.dualCharacterData.hasOnlyOneSpecial">
          <td>
            Special ({{ legend.dualCharacterData.specialTurns.initial }}→{{
              legend.dualCharacterData.specialTurns.max
            }}
            <span
              *ngIf="legend.dualCharacterData.specialTurns.gainWithLimitBreak"
            >
              puis
              {{
                legend.dualCharacterData.specialTurns.max -
                  legend.dualCharacterData.specialTurns.gainWithLimitBreak
              }}</span
            >)
          </td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            : {{ legend.dualCharacterData.firstForm.ownSpecial }}<br />
            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            : {{ legend.dualCharacterData.secondForm.ownSpecial }}<br />
          </td>
        </tr>
        <tr
          *ngIf="
            !legend.dualCharacterData.hasDifferentCrewmateAbility1 &&
            !legend.dualCharacterData.finalForm.crewmateAbilities[0]
              .needLimitBreak
          "
        >
          <td>Crewmate Ability 1</td>
          <td>
            {{
              legend.dualCharacterData.finalForm.crewmateAbilities[0].ability
            }}
          </td>
        </tr>
        <tr
          *ngIf="
            legend.dualCharacterData.hasDifferentCrewmateAbility1 &&
            !legend.dualCharacterData.finalForm.crewmateAbilities[0]
              .needLimitBreak
          "
        >
          <td>Crewmate Ability 1</td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            :
            {{ legend.dualCharacterData.firstForm.crewmateAbilities[0].ability
            }}<br />
            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            :
            {{ legend.dualCharacterData.secondForm.crewmateAbilities[0].ability
            }}<br />
            <b
              class="dual-name"
              [ngStyle]="
                gradient(
                  legend.dualCharacterData.firstForm.type,
                  legend.dualCharacterData.secondForm.type
                )
              "
              >{{ legend.dualCharacterData.finalForm.name }}</b
            >
            :
            {{
              legend.dualCharacterData.finalForm.crewmateAbilities[0].ability
            }}
          </td>
        </tr>
        <tr
          *ngIf="
            !legend.dualCharacterData.hasDifferentCrewmateAbility2 &&
            !legend.dualCharacterData.finalForm.crewmateAbilities[1]
              .needLimitBreak
          "
        >
          <td>Crewmate Ability 2</td>
          <td>
            {{
              legend.dualCharacterData.finalForm.crewmateAbilities[1].ability
            }}
          </td>
        </tr>
        <tr
          *ngIf="
            legend.dualCharacterData.hasDifferentCrewmateAbility2 &&
            !legend.dualCharacterData.finalForm.crewmateAbilities[1]
              .needLimitBreak
          "
        >
          <td>Crewmate Ability 2</td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            :
            {{ legend.dualCharacterData.firstForm.crewmateAbilities[1].ability
            }}<br />
            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            :
            {{ legend.dualCharacterData.secondForm.crewmateAbilities[1].ability
            }}<br />
            <b
              class="dual-name"
              [ngStyle]="
                gradient(
                  legend.dualCharacterData.firstForm.type,
                  legend.dualCharacterData.secondForm.type
                )
              "
              >{{ legend.dualCharacterData.finalForm.name }}</b
            >
            :
            {{
              legend.dualCharacterData.finalForm.crewmateAbilities[1].ability
            }}
          </td>
        </tr>
        <tr>
          <td>Effets du changement</td>
          <td>
            {{ legend.dualCharacterData.switchEffect }}
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="text-align: center">
              <b>Dépassement de limites</b>
            </div>
          </td>
        </tr>
        <tr
          *ngIf="
            !legend.dualCharacterData.hasDifferentCrewmateAbility1 &&
            legend.dualCharacterData.finalForm.crewmateAbilities[0]
              .needLimitBreak
          "
        >
          <td>Crewmate Ability 1</td>
          <td>
            {{
              legend.dualCharacterData.finalForm.crewmateAbilities[0].ability
            }}
          </td>
        </tr>

        <tr
          *ngIf="
            legend.dualCharacterData.hasDifferentCrewmateAbility1 &&
            legend.dualCharacterData.finalForm.crewmateAbilities[0]
              .needLimitBreak
          "
        >
          <td>Crewmate Ability 1</td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            :
            {{ legend.dualCharacterData.firstForm.crewmateAbilities[0].ability
            }}<br />
            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            :
            {{ legend.dualCharacterData.secondForm.crewmateAbilities[0].ability
            }}<br />
            <b
              class="dual-name"
              [ngStyle]="
                gradient(
                  legend.dualCharacterData.firstForm.type,
                  legend.dualCharacterData.secondForm.type
                )
              "
              >{{ legend.dualCharacterData.finalForm.name }}</b
            >
            :
            {{
              legend.dualCharacterData.finalForm.crewmateAbilities[0].ability
            }}
          </td>
        </tr>
        <tr
          *ngIf="
            !legend.dualCharacterData.hasDifferentCrewmateAbility2 &&
            legend.dualCharacterData.finalForm.crewmateAbilities[1]
              .needLimitBreak
          "
        >
          <td>Crewmate Ability 2</td>
          <td>
            {{
              legend.dualCharacterData.finalForm.crewmateAbilities[1].ability
            }}
          </td>
        </tr>
        <tr
          *ngIf="
            legend.dualCharacterData.hasDifferentCrewmateAbility2 &&
            legend.dualCharacterData.finalForm.crewmateAbilities[1]
              .needLimitBreak
          "
        >
          <td>Crewmate Ability 2</td>
          <td>
            <b [ngStyle]="color(legend.dualCharacterData.firstForm.type)">{{
              legend.dualCharacterData.firstForm.name
            }}</b>
            :
            {{ legend.dualCharacterData.firstForm.crewmateAbilities[1].ability
            }}<br />
            <b [ngStyle]="color(legend.dualCharacterData.secondForm.type)">{{
              legend.dualCharacterData.secondForm.name
            }}</b>
            :
            {{ legend.dualCharacterData.secondForm.crewmateAbilities[1].ability
            }}<br />
            <b
              class="dual-name"
              [ngStyle]="
                gradient(
                  legend.dualCharacterData.firstForm.type,
                  legend.dualCharacterData.secondForm.type
                )
              "
              >{{ legend.dualCharacterData.finalForm.name }}</b
            >
            :
            {{
              legend.dualCharacterData.finalForm.crewmateAbilities[1].ability
            }}
          </td>
        </tr>
        <tr>
          <td>Potential Ability 1</td>
          <td>{{ legend.potentialAbilities[0].effects[0] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[0].effects[1] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[0].effects[2] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[0].effects[3] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[0].effects[4] }}</td>
        </tr>
        <tr>
          <td>Potential Ability 2</td>
          <td>{{ legend.potentialAbilities[1].effects[0] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[1].effects[1] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[1].effects[2] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[1].effects[3] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[1].effects[4] }}</td>
        </tr>
        <tr>
          <td>Potential Ability 3</td>
          <td>{{ legend.potentialAbilities[2].effects[0] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[2].effects[1] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[2].effects[2] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[2].effects[3] }}</td>
        </tr>
        <tr>
          <td></td>
          <td>{{ legend.potentialAbilities[2].effects[4] }}</td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="text-align: center">
              <b>Évolueurs requis</b>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="text-align: center">
              <img height="8%" [src]="legend.evolvers[0]" width="8%" />
              <img height="8%" [src]="legend.evolvers[1]" width="8%" />
              <img height="8%" [src]="legend.evolvers[2]" width="8%" />
              <img height="8%" [src]="legend.evolvers[3]" width="8%" />
              <img height="8%" [src]="legend.evolvers[4]" width="8%" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</ng-container>
