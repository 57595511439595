<mat-tab-group>
  <mat-tab label="Informations Générales">
    <div class="informations-content">
      <strong>Welcome on OPTC TierList Maker</strong>
      <br /><br />

      You can make your own different Tier Lists for One Piece Treasure Cruise
      by category.
      <br />

      <ul>
        <li>Legends</li>
        <li>
          Rare recruits (not including limited like TM boosters, World Journey
          or World clash etc...)
        </li>
        <li>
          Limited Rare Recruits (commonly known as LRR, the ones you can have
          from Ray shop ticket for example)
        </li>
        <li>TM characters</li>
        <li>Raid characters</li>
        <li>Colosseum characters</li>
        <li>Ambush characters</li>
        <li>PVP LRR characters</li>
        <li>Kizuna characters</li>
      </ul>

      From these different categories, you can make Tier Lists from all the ways
      you think about.<br /><br />

      There are even different groups like
      <strong>"F2P characters Tierlist"</strong> or even
      <strong>"Premium characters tierlist"</strong>.<br /><br />

      A last Tier List even enables you to regroup
      <strong>absolutely all the characters</strong> from the different
      categories, the you can do whatever you want with it !<br /><br />

      You could for example make a global PVP Tier List containing legends, PVP
      LRR, raids characters etc...<br /><br />

      <strong
        >Last of all, don't forget that you can change the title of every of
        your Tier List and that you can create as much of them as you want
        !</strong
      >
      <br /><br />

      <strong>Enjoy !</strong>

      <br /><br />

      <p class="hint">
        PS : Please note that this application is made for desktop browser
      </p>

      <div class="copyright">©2020 webarranco</div>
    </div>
  </mat-tab>

  <mat-tab label="Features">
    <div class="informations-content">
      <div class="subtitle"><strong>General Informations</strong></div>

      <ul>
        <li>Tier List title is editable</li>
        <li>Tiers names are editable</li>
        <li>
          You can double-click on a character to see his detailed
          characteristics.
        </li>
        <li>
          Only in the last Tier,
          <strong
            >you can make a right-click on computer (press on mobile) on a
            character in order to delete him</strong
          >
          from the Tier List.
        </li>
      </ul>

      <div class="subtitle">
        <strong>Filters</strong>
      </div>

      <ul>
        <li>
          You can then display back again the deleted characters from your Tier
          List. You can then bring back the characters inside the Tier List with
          another right-click.
        </li>
        <li>On the Legends Tier List, you can filter by debut year.</li>
        <li>
          On the F2P, Premium Tier Lists and also on the one that regroups all
          characters, you can filter by character's category.
        </li>
      </ul>

      <div class="subtitle"><strong>Save, Loading and removal</strong></div>

      <ul>
        <li>
          You can save your Tier List any time in order to go back on it later
          or even keep it saved. Your Tier List is saved inside your own browser
          with the Local Storage.
        </li>
        <li>You can <strong>save as much Tier Lists as you want.</strong></li>
        <li>
          <strong
            >Your Tier Lists are saved based on their title. A Tier List loaded
            then modified will be erased by current Tier List. If you save a
            Tier List, it will erase and replace the saved Tier List with the
            same title.</strong
          >
        </li>
        <li>
          You can load a previously saved Tier List.
          <strong
            >Please note that this will erase the current Tier List</strong
          >
          if you haven't saved it before.
        </li>
        <li>You can remove a previously saved Tier List</li>
      </ul>

      <div class="subtitle"><strong>Share & Export</strong></div>

      <ul>
        <li>
          <strong>You can share a Tier List with a simple URL</strong>. Clic on
          the "Share" button and the Tier List URL will be pasted in your
          clipboard (just do CTRL+V then)
        </li>
        <li>You can export your Tier List as a PNG picture</li>
        <li>
          You can export your Tier List as a PNG picture and choose to hide the
          last tier from it
        </li>
      </ul>
    </div>
  </mat-tab>
</mat-tab-group>
