export const gloKizunaLrrs = [
  '2756',
  '2793',
  '2832',
  '2853',
  '2887',
  '2888',
  '2889',
  '2996',
  '2997',
  '2998',
  '3120',
  '3121',
  '3151',
  '3152',
  '3153',
  '3205',
  '3212',
  '3242',
  '3274',
  '3301',
  '3302',
  '3370',
  '3371',
  '3372',
  '3392',
  '3418',
  '3445',
  '3446',
  '3447',
  '3468',
  '3487',
  '3488',
  '3509',
  '3528',
  '3529',
  '3548',
  '3549',
  '3568',
  '3569',
  '3591',
  '3623',
  '3624',
  '3646',
  '3647',
  '3664',
  '3665',
  '3690',
  '3691',
  '3692',
  '3714',
  '3715',
  '3716',
  '3737',
  '3738',
  '3760',
  '3761',
  '3781',
  '3782',
  '3803',
  '3804',
  '3825',
  '3843',
  '3844',
];

export const japKizunaLrrs = [];

export const kizunaLrrs = [...gloKizunaLrrs, ...japKizunaLrrs];
