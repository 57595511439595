import { Legend } from '../../models/legend.interface';
import {
  CharacterClass,
  CharacterTypeClass,
} from '../../models/character-class.interface';
import { PotentialAbility } from '../../models/potential-ability';
import { PvpStyle } from 'src/app/models/pvp.interface';

export const singleLegendsStr: Legend[] = [
  // Barbe Blanche V1
  {
    id: '0261',
    title: null,
    name: 'Barbe Blanche',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/VWSTtgM.png',
      sixStars: 'https://i.imgur.com/03SM5d6.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Le capitaine de l'équipage de Barbe Blanche et l'un des Quatre Empereurs. Edward Newgate de son véritable nom. Il a mangé le Fruit du Tremblement qui permet de déclencher des séismes et des tsunamis. Utilise le Haki du Conquérant.`,
      type: CharacterTypeClass.STR,
      classes: [CharacterClass.Ravageur, CharacterClass.Tenace],
      combo: 4,
      hp: {
        initial: 3900,
        afterLimitBreak: 4395,
        afterLimitBreakPlus: 4895,
      },
      atk: {
        initial: 1360,
        afterLimitBreak: 1575,
        afterLimitBreakPlus: 1775,
      },
      rcv: {
        initial: 235,
        afterLimitBreak: 320,
        afterLimitBreakPlus: 395,
      },
      captainAbility: `Multiplie l'ATK de tous les personnages par 3 si les HP sont en-dessous de 30%.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Enlève 30% des HP de chaque ennemi.`,
      specialTurns: {
        initial: 25,
        max: 17,
        gainWithLimitBreak: 0,
        gainWithLimitBreakPlus: 2,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 60 en ATK pour les personnages de classe Ravageur et Tenace.`,
          needLimitBreak: true,
        },
        {
          ability: `Immunise le personnage contre "Paralysie".`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0260.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0261.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.DEX_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type DEX de 2%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 3%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 4%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 5%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 7%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 25 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 50 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 200 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0084.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0100.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0095.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de type STR'],
      levels: [
        `Ajoute 5% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Ajoute 6% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Ajoute 8% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Ajoute 10% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Ajoute 12% de l'ATK du personnage en ATK pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Barbe Blanche V1 6+
  {
    id: '1413',
    title: 'Quatre Empereurs',
    name: 'Barbe Blanche',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/ymeQY1E.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage de Barbe Blanche. Bien qu'ayant subi de multiples attaques lors de la guerre au sommet, ce "père" exemplaire est mort sans même poser genou à terre, le dos exempt de toute blessure infamante.`,
      type: CharacterTypeClass.STR,
      classes: [CharacterClass.Ravageur, CharacterClass.Tenace],
      combo: 4,
      hp: {
        initial: 3806,
        afterLimitBreak: 4301,
        afterLimitBreakPlus: 4801,
      },
      atk: {
        initial: 1558,
        afterLimitBreak: 1773,
        afterLimitBreakPlus: 1973,
      },
      rcv: {
        initial: 347,
        afterLimitBreak: 423,
        afterLimitBreakPlus: 498,
      },
      captainAbility: `Multiplie l'ATK de tous les personnages par 3 si les HP sont en-dessous de 30%, sinon par 1,75, et multiplie les HP de tous les personnages par 1,2.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Enlève 30% des HP de chaque ennemi, réduit le temps de chargement des coups spéciaux des personnages qui ne sont pas capitaines d'un tour et mélange les cercles qui ne sont pas correspondants de tous les personnages (cercles [BLK] inclus) aléatoirement.`,
      specialTurns: {
        initial: 25,
        max: 17,
        gainWithLimitBreak: 0,
        gainWithLimitBreakPlus: 2,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 60 en ATK pour les personnages de classe Ravageur et Tenace.`,
          needLimitBreak: true,
        },
        {
          ability: `Immunise le personnage contre "Paralysie".`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture: 'https://i.imgur.com/z9NGsV3.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.DEX_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type DEX de 2%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 3%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 4%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 5%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 7%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 25 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 50 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 200 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/red_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0084.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0300.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0095.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de type STR'],
      levels: [
        `Ajoute 5% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Ajoute 6% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Ajoute 8% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Ajoute 10% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Ajoute 12% de l'ATK du personnage en ATK pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Snakeman V1
  {
    id: '2363',
    title: 'Gear Fourth "Snake Man"',
    name: 'Monkey D. Luffy',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/3fFLZvS.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage de Chapeau de paille. Grâce à cette nouvelle forme du "Gear Fourth", à la fois plus fine et plus rapide que "Tank Man" et "Bound Man", il parvient à faire jeu égal avec Dent-de-chien lors de son duel contre celui-ci.`,
      type: CharacterTypeClass.STR,
      classes: [CharacterClass.Cogneur, CharacterClass.Tenace],
      combo: 4,
      hp: {
        initial: 3820,
        afterLimitBreak: 4330,
        afterLimitBreakPlus: 4680,
      },
      atk: {
        initial: 1645,
        afterLimitBreak: 1845,
        afterLimitBreakPlus: 2045,
      },
      rcv: {
        initial: 282,
        afterLimitBreak: 372,
        afterLimitBreakPlus: 447,
      },
      captainAbility: `Réduit le temps de chargement des coups spéciaux de tous les personnages d'un tour au début de la quête, multiplie l'ATK de tous les personnages par 3,25, les HP par 1,35 et rend les cercles [DEX] et [INT] avantageux pour tous les personnages. Si le coup spécial "Gum Gum King Cobra" est utilisé, pendant 3 tours, multiplie l'ATK de tous les personnages par 4 puis par 4,25 après 3 "PARFAIT" d'affilée.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: [
        {
          specialTurns: {
            initial: 12,
            max: 7,
            gainWithLimitBreak: 1,
            gainWithLimitBreakPlus: 0,
          },
          special: `Inflige 150 fois son ATK en dégâts non élémentaires sur un ennemi, ajoute 0,3 au multiplicateur de combos pendant un tour, multiplie les effets des cercles de tous les personnages par 1,5 pendant un tour et si le personnage est capitaine ou capitaine ami, rend les cercles de type avantageux pour tous les personnages pendant 3 tours.`,
        },
        {
          specialTurns: {
            initial: 19,
            max: 14,
            gainWithLimitBreak: 1,
            gainWithLimitBreakPlus: 0,
          },
          special: `Inflige 150 fois son ATK en dégâts non élémentaires sur un ennemi, ajoute 0,7 au multiplicateur de combos pendant 3 tours, multiplie les effets des cercles de tous les personnages par 1,75 pendant un tour et si au moins 3 personnages font un coup "PARFAIT", multiplie les effets des cercles de tous les personnages par 2 au tour suivant. Si le personnage est capitaine ou capitaine ami, rend les cercles de type avantageux pour tous les personnages pendant 3 tours.`,
        },
      ],
      special: null,
      specialTurns: null,
      crewmateAbilities: [
        {
          ability: `Immunise le personnage contre "Paralysie".`,
          needLimitBreak: true,
        },
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour tous les personnages.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f2363.png',
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 2 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_URGENCE,
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 10%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 15%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 20%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 30%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 4% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 6% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 80% de chances de faire 8% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 90% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/blue_skull_f.png',
      'https://onepiece-treasurecruise.com/wp-content/uploads/red_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0095.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0100.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Tous les personnages'],
      levels: [
        `Ajoute 3% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 5% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 7% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 8% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 9% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
      ],
    },
    batch: `??`,
  },
  // Log Luffy
  {
    id: '1121',
    title: 'Promesse de l’avènement de l’ère des pirates "Roi des pirates"',
    name: 'Monkey D. Luffy',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/XPH42Tp.png',
      sixStars: 'https://i.imgur.com/W2rDWee.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Chef de l'équipage de Chapeau de paille. Il est devenu l'ennemi du Gouvernement mondial lorsqu'il a secouru Robin. Lors de son combat contre le CP9, il a créé une nouvelle technique de combat, le "Gear", qui lui permet de donner des coups plus puissants que jamais.`,
      type: CharacterTypeClass.STR,
      classes: [CharacterClass.Cogneur, CharacterClass.Libre],
      combo: 4,
      hp: {
        initial: 3400,
        afterLimitBreak: 3670,
        afterLimitBreakPlus: 4020,
      },
      atk: {
        initial: 1430,
        afterLimitBreak: 1630,
        afterLimitBreakPlus: 2000,
      },
      rcv: {
        initial: 351,
        afterLimitBreak: 371,
        afterLimitBreakPlus: 392,
      },
      captainAbility: `Après un enchaînement suivant "BIEN", "SUPER" et "PARFAIT", multiplie l'ATK de tous les personnages par 4.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige de lourds dégâts aléatoires non élémentaires sur tous les ennemis et change son cercle en cercle [STR].`,
      specialTurns: {
        initial: 23,
        max: 17,
        gainWithLimitBreak: 8,
        gainWithLimitBreakPlus: 3,
      },
      crewmateAbilities: [
        {
          ability: `Rend les cercles [RCV] avantageux pour tous les personnages.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [TND] avantageux pour tous les personnages.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1120.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1121.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.SOIN_URGENCE,
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 2 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 10%.`,
          `Restaure les HP à hauteur de 2 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 15%.`,
          `Restaure les HP à hauteur de 2 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 20%.`,
          `Restaure les HP à hauteur de 2 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 30%.`,
          `Restaure les HP à hauteur de 2 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 4 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 4% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 6% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 80% de chances de faire 8% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 100% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0095.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0300.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: [
        'Roronoa Zoro',
        'Nami',
        'Usopp',
        'Sanji Vinsmoke',
        'Tony-Tony Chopper',
        'Nico Robin',
        'Franky',
      ],
      levels: [
        `Mélange le cercle du personnage soutenu au début du dernier stage d'une quête.`,
        `Mélange le cercle du personnage soutenu au début du dernier stage d'une quête.`,
        `Change le cercle du personnage soutenu en cercle [STR] au début du dernier stage d'une quête.`,
        `Change le cercle du personnage soutenu en cercle [STR] au début du dernier stage d'une quête.`,
        `Change le cercle du personnage soutenu en cercle [STR] et rend les cercles [STR] avantageux pour tous les personnages pendant un tour au début du dernier stage d'une quête.`,
      ],
    },
    batch: `First Legend`,
  },
  // Crocodile 6+
  {
    id: '1927',
    title: `Après le rêve d'Utopia`,
    name: 'Sir Crocodile',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/fvKR7ng.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Ancien membre de l'ordre des sept grands corsaires. Après avoir vu son rêve de créer Utopia réduit à néant par Luffy, il se retrouve emprisonné à Impel Down. Bien que ses activités dépassent le cadre de la simple piraterie, il caresse pourtant le désir de devenir le roi des pirates. À la fin de la guerre au sommet, il reprend la mer pour le Nouveau Monde.`,
      type: CharacterTypeClass.STR,
      classes: [CharacterClass.Intellectuel, CharacterClass.Ambitieux],
      combo: 4,
      hp: {
        initial: 3701,
        afterLimitBreak: 4201,
        afterLimitBreakPlus: 0,
      },
      atk: {
        initial: 1502,
        afterLimitBreak: 1702,
        afterLimitBreakPlus: 0,
      },
      rcv: {
        initial: 330,
        afterLimitBreak: 430,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Intellectuel selon les HP de l'équipe au début du tour.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: `Par 2 si les HP de l'équipe sont au maximum et par 3,5 s'il ne reste qu'un HP à l'équipe.`,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Enlève 20% des HP de chaque ennemi et multiplie l'ATK des personnages de classe Intellectuel par 2.`,
      specialTurns: {
        initial: 21,
        max: 14,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 0,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 50 en ATK pour les personnages de classe Intellectuel.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [DEX] avantageux pour les personnages de classe Intellectuel.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1927.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.QCK_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type QCK de 1%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 2%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 3%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 5%.`,
          `Réduit les dégâts subis par les ennemis de type QCK de 7%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 10% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 75 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 200 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 250 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 300 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/red_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0084.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0300.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de classe Intellectuel'],
      levels: [
        `Ajoute 5% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 6% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 7% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 8% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 9% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Lucci V1
  {
    id: '0748',
    title: 'Le plus fort du CP9',
    name: 'Rob Lucci',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/6DV5auL.png',
      sixStars: 'https://i.imgur.com/yNRH68g.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Membre du Cipher Pol N°9, un groupe d'espionnage affilié au Gouvernement mondial. Le membre le plus adroit du CP9, presque deux fois plus que Kaku, pourtant numéro 2 de l'équipe. Il pense que la faiblesse est fondamentalement néfaste.`,
      type: CharacterTypeClass.STR,
      classes: [CharacterClass.Tenace, CharacterClass.Cogneur],
      combo: 6,
      hp: {
        initial: 3230,
        afterLimitBreak: 3715,
        afterLimitBreakPlus: 4315,
      },
      atk: {
        initial: 1456,
        afterLimitBreak: 1666,
        afterLimitBreakPlus: 1966,
      },
      rcv: {
        initial: 382,
        afterLimitBreak: 487,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Tenace par 3 s'ils ont un cercle correspondant, sinon par 2,5, et réduit la RCV de l'équipe à 0.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie l'ATK des personnages de classe Tenace par 3,25 s'ils ont un cercle correspondant, sinon par 2,75, et réduit la RCV de l'équipe à 0.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige 35 fois son ATK en dégâts de type STR sur un ennemi et augmente les chances d'apparition des cercles correspondants pendant 3 tours.`,
      specialTurns: {
        initial: 25,
        max: 15,
        gainWithLimitBreak: 0,
        gainWithLimitBreakPlus: 7,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 30 en HP, ATK et RCV pour les personnages de classe Tenace.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [RCV] et [TND] avantageux pour les personnages de classe Tenace.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0747.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0748.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 4,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.DEX_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type DEX de 1%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 2%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 3%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 4%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 5%.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 30 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 90 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 120 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0084.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0300.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0095.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de classe Tenace'],
      levels: [
        `Réduit les dégâts subis par les ennemis de type DEX de ?% et ajoute ?% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type DEX de ?% et ajoute ?% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type DEX de ?% et ajoute ?% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type DEX de ?% et ajoute ?% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type DEX de 5% et ajoute 13% de l'ATK du personnage en ATK pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Lucci V1 6+
  {
    id: '1663',
    title: `Leader le plus puissant de l'histoire du CP9`,
    name: `Rob Lucci - "Résurrection"
    `,
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/ZmGWTjc.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Membre d'une organisation gouvernementale chargée de l'espionnage et du renseignement. Tandis qu'Enies Lobby s'effrondre sous les tirs de canon du buster call, il profite de son pouvoir de "résurrection" pour alléger son corps en forme semi-humaine, ce qui lui confère une rapidité égale à celle de Luffy en mode "gear 2" et lui permet d'exercer la Justice.`,
      type: CharacterTypeClass.STR,
      classes: [CharacterClass.Tenace, CharacterClass.Cogneur],
      combo: 4,
      hp: {
        initial: 3480,
        afterLimitBreak: 3965,
        afterLimitBreakPlus: 4565,
      },
      atk: {
        initial: 1570,
        afterLimitBreak: 1780,
        afterLimitBreakPlus: 2080,
      },
      rcv: {
        initial: 390,
        afterLimitBreak: 495,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Tenace par 3,5 s'ils ont un cercle correspondant, sinon par 2,5, et réduit la RCV de l'équipe à 0.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie l'ATK des personnages de classe Tenace par 3,75 s'ils ont un cercle correspondant, sinon par 2,75, et réduit la RCV de l'équipe à 0.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige 75 fois son ATK en dégâts de type STR sur un ennemi et augmente les chances d'apparition des cercles correspondants pendant 3 tours.`,
      specialTurns: {
        initial: 22,
        max: 15,
        gainWithLimitBreak: 0,
        gainWithLimitBreakPlus: 7,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 30 en HP, ATK et RCV pour les personnages de classe Tenace.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [RCV] et [TND] avantageux pour les personnages de classe Tenace.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1663.png',
      },
    },
    sockets: 4,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.DEX_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type DEX de 1%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 2%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 3%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 4%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 5%.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 30 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 90 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 120 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/red_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0095.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0300.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0095.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de classe Tenace'],
      levels: [
        `Réduit les dégâts subis par les ennemis de type DEX de ?% et ajoute ?% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type DEX de ?% et ajoute ?% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type DEX de ?% et ajoute ?% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type DEX de ?% et ajoute ?% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type DEX de 5% et ajoute 13% de l'ATK du personnage en ATK pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Bartolomeo
  {
    id: '1035',
    title: null,
    name: 'Bartolomeo le cannibale',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/WybxH1X.png',
      sixStars: 'https://i.imgur.com/PvdqV0p.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage du Barto Club. Il utilise le fruit de la barrière pour générér des barrières à volonté. Cela lui permet d'en former une autour de son poing pour infuser ce dernier de puissance, une technique qu'il a copiée du Gum Gum Bullet de Luffy.`,
      type: CharacterTypeClass.STR,
      classes: [CharacterClass.Ravageur, CharacterClass.Ambitieux],
      combo: 4,
      hp: {
        initial: 3505,
        afterLimitBreak: 3975,
        afterLimitBreakPlus: 0,
      },
      atk: {
        initial: 1431,
        afterLimitBreak: 1651,
        afterLimitBreakPlus: 0,
      },
      rcv: {
        initial: 341,
        afterLimitBreak: 426,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Ravageur selon le nombre de personnages de classe Ravageur présents dans l'équipe et si le personnage n'attaque pas durant le tour, réduit les dégâts subis de 30%.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: `Par 1,25 si l'équipe contient un personnage de classe Ravageur, par 1,5 si l'équipe contient 2 personnages de classe Ravageur, par 1,75 si l'équipe contient 3 personnages de classe Ravageur, par 2 si l'équipe contient 4 personnages de classe Ravageur, par 2,5 si l'équipe contient 5 personnages de classe Ravageur et par 2,75 si l'équipe contient 6 personnages de classe Ravageur.`,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Empêche la défaite pendant un tour et inflige des dégâts non élémentaires sur un ennemi selon les HP de l'équipe.`,
      specialTurns: {
        initial: 23,
        max: 18,
        gainWithLimitBreak: 4,
        gainWithLimitBreakPlus: 0,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 60 en HP et ATK pour les personnages de classe Ravageur.`,
          needLimitBreak: true,
        },
        {
          ability: `Immunise le personnage contre "Paralysie".`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1034.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1035.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.DEX_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type DEX de 2%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 3%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 4%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 5%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 7%.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 30 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 90 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 120 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 4 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0084.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0300.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Nico Robin', 'Cavendish'],
      levels: [
        `Réduit la durée de "Paralysie" de 1 tour si un personnage a été touché par la "Paralysie" (une fois par quête).`,
        `Réduit la durée de "Paralysie" de 1 tour si un personnage a été touché par la "Paralysie" (une fois par quête).`,
        `Réduit la durée de "Paralysie" de 1 tour si un personnage a été touché par la "Paralysie" (une fois par quête).`,
        `Réduit la durée de "Paralysie" de 2 tours si un personnage a été touché par la "Paralysie" (une fois par quête).`,
        `Réduit la durée de "Paralysie" de 3 tours si un personnage a été touché par la "Paralysie" (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Akainu V1
  {
    id: '1314',
    title: null,
    name: 'Sakazuki',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/jIiEQ5c.png',
      sixStars: 'https://i.imgur.com/uLmwyYL.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Amiral au quartier général de la Marine. Ce partisan d'une conception intransigeante de la justice considère qu'il faut éradiquer jusqu'à la possibilité du Mal, non seulement chez les pirates mais aussi chez tous ceux qu'il judge comme potentiellement malfaisants, y compris au sein de la Marine.`,
      type: CharacterTypeClass.STR,
      classes: [CharacterClass.Ambitieux, CharacterClass.Tenace],
      combo: 4,
      hp: {
        initial: 3500,
        afterLimitBreak: 3950,
        afterLimitBreakPlus: 4300,
      },
      atk: {
        initial: 1600,
        afterLimitBreak: 1800,
        afterLimitBreakPlus: 2000,
      },
      rcv: {
        initial: 99,
        afterLimitBreak: 189,
        afterLimitBreakPlus: 264,
      },
      captainAbility: `Multiplie l'ATK de tous les personnages par 3,9375 s'ils ont un cercle [STR], sinon par 2,25, et augmente les chances d'apparition des cercles [STR].`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige 125 fois son ATK en dégâts non élémentaires sur tous les ennemis, mélange les cercles qui ne sont pas [STR] aléatoirement et multiplie l'ATK des personnages ayant un coût supérieur ou égal à 50 par 1,75 pendant 2 tours.`,
      specialTurns: {
        initial: 25,
        max: 18,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 4,
      },
      crewmateAbilities: [
        {
          ability: `Multiplie l'ATK du personnage par 2 contre les ennemis de type QCK.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1313.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1314.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 30 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 90 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 120 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
          `Annule le “Lien des cercles” du personnage.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0095.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0300.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de type STR'],
      levels: [
        `Change le cercle du personnage soutenu en cercle [STR] et multiplie l'ATK des personnages de type STR par 1,1 pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [STR] et multiplie l'ATK des personnages de type STR par 1,2 pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [STR] et multiplie l'ATK des personnages de type STR par 1,3 pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [STR] et multiplie l'ATK des personnages de type STR par 1,4 pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change les cercles de tous les personnages en cercles [STR] et multiplie l'ATK des personnages de type STR par 1,5 pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Akainu V1 6+
  {
    id: '2578',
    title: 'Justicier zélé',
    name: 'Amiral commandant en chef Sakazuki',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/rTVUAgU.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Nouvel amiral en chef de la Marine. Soucieux de préserver l'autorité et la crédibilité de la Marine face à un Âge d'or de la piraterie de plus en plus tumultueux, il se montre sans pitié envers ceux qu'il appelle le "Mal" et fait construire à l'entrée du Nouveau Monde une nouvelle forteresse baptisée "New Marine Ford".`,
      type: CharacterTypeClass.STR,
      classes: [CharacterClass.Ambitieux, CharacterClass.Tenace],
      combo: 4,
      hp: {
        initial: 3642,
        afterLimitBreak: 4092,
        afterLimitBreakPlus: 4442,
      },
      atk: {
        initial: 1620,
        afterLimitBreak: 1820,
        afterLimitBreakPlus: 2020,
      },
      rcv: {
        initial: 119,
        afterLimitBreak: 209,
        afterLimitBreakPlus: 284,
      },
      captainAbility: `Multiplie l'ATK des personnages de type STR par 4,225 s'ils ont un cercle [STR], sinon par 3,25, et augmente les chances d'apparition des cercles [STR].`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige 120 fois son ATK en dégâts non élémentaires sur tous les ennemis, change les cercles de tous les personnages en cercles [STR] et multiplie l'ATK des personnages de type STR par 2 pendant un tour.`,
      specialTurns: {
        initial: 23,
        max: 16,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 4,
      },
      crewmateAbilities: [
        {
          ability: `Multiplie l'ATK du personnage par 2 contre les ennemis de type QCK.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture: 'https://i.imgur.com/ZUe9Zve.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 30 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 90 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 120 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
          `Annule le “Lien des cercles” du personnage.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/red_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0095.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0300.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de type STR'],
      levels: [
        `Change le cercle du personnage soutenu en cercle [STR] et multiplie l'ATK des personnages de type STR par 1,1 pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [STR] et multiplie l'ATK des personnages de type STR par 1,2 pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [STR] et multiplie l'ATK des personnages de type STR par 1,3 pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [STR] et multiplie l'ATK des personnages de type STR par 1,4 pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change les cercles de tous les personnages en cercles [STR] et multiplie l'ATK des personnages de type STR par 1,5 pendant un tour si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Inuarashi
  {
    id: '1571',
    title: 'Le seigneur diurne',
    name: 'Caborage',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/jIiEQ5c.png',
      sixStars: 'https://i.imgur.com/uLmwyYL.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Seigneur diurne de la principauté de Mokomo. Bien qu'ayant autrefois servi le même maître que Chavipère le seigneur nocturne, il entretenait avec ce dernier des relations si exécrables qu'un combat à mort pouvait éclater à tout moment entre eux. Il lui proposa toutefois de faire une trêve afin de ne pas faire de peine au fils de leur ancien suzerain.`,
      type: CharacterTypeClass.STR,
      classes: [CharacterClass.Intellectuel, CharacterClass.Sabreur],
      combo: 4,
      hp: {
        initial: 2978,
        afterLimitBreak: 3473,
        afterLimitBreakPlus: 3833,
      },
      atk: {
        initial: 1535,
        afterLimitBreak: 1740,
        afterLimitBreakPlus: 1940,
      },
      rcv: {
        initial: 389,
        afterLimitBreak: 489,
        afterLimitBreakPlus: 564,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Intellectuel par 2,75 et le multiplicateur de combos par 1,5 si les HP sont en-dessous de 30%, sinon par 2,5.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie l'ATK des personnages de classe Intellectuel par 3 et le multiplicateur de combos par 1,5 si les HP sont en-dessous de 30%, sinon par 2,75.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Réduit les HP de l'équipe de 80%, inflige 60 fois son ATK en dégâts de type STR sur un ennemi et ajoute 80 fois son ATK en dégâts additionnels non élémentaires pendant un tour.`,
      specialTurns: {
        initial: 19,
        max: 14,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 4,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 200 en HP pour les personnages de classe Intellectuel.`,
          needLimitBreak: true,
        },
        {
          ability: `Si le personnage est le dernier à attaquer, ajoute 200 en ATK pour le personnage.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1313.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1314.png',
        sixPlusStarsPicture: null,
      },
    },
    // pvpData: {
    //   cost: 55,
    //   style: PvpStyle.ATK,
    //   ct: 28,
    //   specialDescription: [
    //     `Réduit les HP du personnage de 20% et inflige 1,05 fois son ATK aux ennemis à longue portée (horizontal).`,
    //     `Réduit les HP du personnage de 20% et inflige 1,12 fois son ATK aux ennemis à longue portée (horizontal).`,
    //     `Réduit les HP du personnage de 20% et inflige 1,18 fois son ATK aux ennemis à longue portée (horizontal).`,
    //     `Réduit les HP du personnage de 20% et inflige 1,25 fois son ATK aux ennemis à longue portée (horizontal).`,
    //     `Réduit les HP du personnage de 20% et inflige 1,32 fois son ATK aux ennemis à longue portée (horizontal).`,
    //     `Réduit les HP du personnage de 20% et inflige 1,39 fois son ATK aux ennemis à longue portée (horizontal).`,
    //     `Réduit les HP du personnage de 20% et inflige 1,45 fois son ATK aux ennemis à longue portée (horizontal).`,
    //     `Réduit les HP du personnage de 20% et inflige 1,52 fois son ATK aux ennemis à longue portée (horizontal).`,
    //     `Réduit les HP du personnage de 20% et inflige 1,59 fois son ATK aux ennemis à longue portée (horizontal).`,
    //     `Réduit les HP du personnage de 20% et inflige 1,7 fois son ATK aux ennemis à longue portée (horizontal).`,
    //   ],
    //   capacityDescription: [
    //     `Augmente la vitesse des alliés de classe Intellectuel de 1 niveau et si les HP sont en-dessous de 60%, augmente l'ATK du personnage de 5 niveaux.`,
    //     `Augmente la vitesse des alliés de classe Intellectuel de 2 niveaux et si les HP sont en-dessous de 60%, augmente l'ATK du personnage de 5 niveaux.`,
    //     `Augmente la vitesse des alliés de classe Intellectuel de 3 niveaux et si les HP sont en-dessous de 60%, augmente l'ATK du personnage de 5 niveaux.`,
    //     `Augmente la vitesse des alliés de classe Intellectuel de 4 niveaux et si les HP sont en-dessous de 60%, augmente l'ATK du personnage de 5 niveaux.`,
    //     `Augmente la vitesse des alliés de classe Intellectuel de 5 niveaux et si les HP sont en-dessous de 60%, augmente l'ATK du personnage de 5 niveaux.`,
    //   ],
    //   capacityAttributes: [
    //     {
    //       SPEED: {
    //         value: 6,
    //         targets: [CharacterClass.Intellectuel],
    //         fromTime: 100,
    //         toTime: 0,
    //       },
    //       ATK: {
    //         value: 5,
    //         targets: ['SELF'],
    //         fromHPPercent: 60,
    //         toHPPercent: 0,
    //       },
    //     },
    //   ],
    // },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.DEX_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type DEX de 1%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 2%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 3%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 4%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 5%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 10% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 75 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 200 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 250 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 300 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0084.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0100.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Chavipère'],
      levels: [
        `Ajoute 50 fois son ATK en dégâts additionnels non élémentaires pour le personnage soutenu pendant un tour au début du dernier stage d'une quête.`,
        `Ajoute 70 fois son ATK en dégâts additionnels non élémentaires pour le personnage soutenu pendant un tour au début du dernier stage d'une quête.`,
        `Ajoute 90 fois son ATK en dégâts additionnels non élémentaires pour le personnage soutenu pendant un tour au début du dernier stage d'une quête.`,
        `Ajoute 110 fois son ATK en dégâts additionnels non élémentaires pour le personnage soutenu pendant un tour au début du dernier stage d'une quête.`,
        `Ajoute 130 fois son ATK en dégâts additionnels non élémentaires pour le personnage soutenu pendant 2 tours au début du dernier stage d'une quête.`,
      ],
    },
    batch: `First Legend`,
  },
  // Inuarashi 6+
  {
    id: '2372',
    title: 'Festin préparé à la main',
    name: 'Caborage',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/jIiEQ5c.png',
      sixStars: 'https://i.imgur.com/uLmwyYL.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Seigneur diurne de la principauté de Mokomo. Pour son Seigneur, il déclara à Chavipère une "Trêve" dans leur longue et interminable querelle, puis prit alors son ancien ami par l'épaule en repensant à leurs aventures passées.`,
      type: CharacterTypeClass.STR,
      classes: [CharacterClass.Intellectuel, CharacterClass.Sabreur],
      combo: 4,
      hp: {
        initial: 3245,
        afterLimitBreak: 3740,
        afterLimitBreakPlus: 4090,
      },
      atk: {
        initial: 1582,
        afterLimitBreak: 1787,
        afterLimitBreakPlus: 1989,
      },
      rcv: {
        initial: 421,
        afterLimitBreak: 521,
        afterLimitBreakPlus: 596,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Intellectuel par 3 et si les HP sont en-dessous de 30%, multiplie le multiplicateur de combos par 1,5.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie l'ATK des personnages de classe Intellectuel par 3,25 et si les HP sont en-dessous de 30%, multiplie le multiplicateur de combos par 1,5. Rend les cercles [STR] avantageux pour les personnages de classe Intellectuel.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Réduit les HP de l'équipe de 80%, enlève 20% des HP d'un ennemi et ajoute 130 fois son ATK en dégâts additionnels non élémentaires pendant un tour.`,
      specialTurns: {
        initial: 19,
        max: 14,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 4,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 200 en HP pour les personnages de classe Intellectuel.`,
          needLimitBreak: true,
        },
        {
          ability: `Si le personnage est le dernier à attaquer, ajoute 200 en ATK pour le personnage.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1313.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1314.png',
        sixPlusStarsPicture: null,
      },
    },
    pvpData: {
      cost: 55,
      style: PvpStyle.ATK,
      ct: 28,
      specialDescription: [
        `Réduit les HP du personnage de 20% et inflige 1,3 fois son ATK aux ennemis à longue portée (horizontal).`,
        `Réduit les HP du personnage de 20% et inflige 1,37 fois son ATK aux ennemis à longue portée (horizontal).`,
        `Réduit les HP du personnage de 20% et inflige 1,43 fois son ATK aux ennemis à longue portée (horizontal).`,
        `Réduit les HP du personnage de 20% et inflige 1,5 fois son ATK aux ennemis à longue portée (horizontal).`,
        `Réduit les HP du personnage de 20% et inflige 1,57 fois son ATK aux ennemis à longue portée (horizontal).`,
        `Réduit les HP du personnage de 20% et inflige 1,64 fois son ATK aux ennemis à longue portée (horizontal).`,
        `Réduit les HP du personnage de 20% et inflige 1,7 fois son ATK aux ennemis à longue portée (horizontal).`,
        `Réduit les HP du personnage de 20% et inflige 1,77 fois son ATK aux ennemis à longue portée (horizontal).`,
        `Réduit les HP du personnage de 20% et inflige 1,84 fois son ATK aux ennemis à longue portée (horizontal).`,
        `Réduit les HP du personnage de 20% et inflige 1,95 fois son ATK aux ennemis à longue portée (horizontal).`,
      ],
      capacityDescription: [
        `Augmente la vitesse des alliés de classe Intellectuel de 2 niveaux et si les HP sont en-dessous de 60%, augmente l'ATK du personnage de 9 niveaux.`,
        `Augmente la vitesse des alliés de classe Intellectuel de 3 niveaux et si les HP sont en-dessous de 60%, augmente l'ATK du personnage de 9 niveaux.`,
        `Augmente la vitesse des alliés de classe Intellectuel de 4 niveaux et si les HP sont en-dessous de 60%, augmente l'ATK du personnage de 9 niveaux.`,
        `Augmente la vitesse des alliés de classe Intellectuel de 5 niveaux et si les HP sont en-dessous de 60%, augmente l'ATK du personnage de 9 niveaux.`,
        `Augmente la vitesse des alliés de classe Intellectuel de 6 niveaux et si les HP sont en-dessous de 60%, augmente l'ATK du personnage de 9 niveaux.`,
      ],
      capacityAttributes: [
        {
          SPEED: {
            value: 6,
            targets: [CharacterClass.Intellectuel],
            fromTime: 100,
            toTime: 0,
          },
          ATK: {
            value: 9,
            targets: ['SELF'],
            fromHPPercent: 60,
            toHPPercent: 0,
          },
        },
      ],
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.DEX_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type DEX de 1%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 2%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 3%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 4%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 5%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 10% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 75 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 200 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 250 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 300 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0084.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0100.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Chavipère'],
      levels: [
        `Ajoute 50 fois son ATK en dégâts additionnels non élémentaires pour le personnage soutenu pendant un tour au début du dernier stage d'une quête.`,
        `Ajoute 70 fois son ATK en dégâts additionnels non élémentaires pour le personnage soutenu pendant un tour au début du dernier stage d'une quête.`,
        `Ajoute 90 fois son ATK en dégâts additionnels non élémentaires pour le personnage soutenu pendant un tour au début du dernier stage d'une quête.`,
        `Ajoute 110 fois son ATK en dégâts additionnels non élémentaires pour le personnage soutenu pendant un tour au début du dernier stage d'une quête.`,
        `Ajoute 130 fois son ATK en dégâts additionnels non élémentaires pour le personnage soutenu pendant 2 tours au début du dernier stage d'une quête.`,
      ],
    },
    batch: `First Legend`,
  },
];
