export const gloRrs = [
  '0075',
  '0077',
  '0210',
  '0217',
  '0219',
  '0221',
  '0223',
  '0225',
  '0251',
  '0253',
  '0255',
  '0257',
  '0259',
  '0308',
  '0310',
  '0312',
  '0314',
  '0316',
  '0357',
  '0359',
  '0361',
  '0363',
  '0365',
  '0410',
  '0412',
  '0414',
  '0449',
  '0451',
  '0453',
  '0455',
  '0457',
  '0500',
  '0502',
  '0504',
  '0506',
  '0508',
  '0586',
  '0588',
  '0590',
  '0639',
  '0641',
  '0643',
  '0645',
  '0647',
  '0671',
  '0673',
  '0675',
  '0677',
  '0750',
  '0752',
  '0754',
  '0756',
  '0758',
  '0760',
  '0785',
  '0787',
  '0789',
  '0791',
  '0793',
  '0838',
  '0840',
  '0842',
  '0844',
  '0846',
  '0872',
  '0874',
  '0876',
  '0878',
  '0880',
  '0906',
  '0908',
  '0910',
  '0912',
  '0914',
  '0937',
  '0939',
  '0941',
  '0943',
  '0991',
  '0993',
  '0995',
  '0997',
  '0999',
  '1025',
  '1027',
  '1029',
  '1031',
  '1033',
  '1075',
  '1077',
  '1079',
  '1081',
  '1083',
  '1115',
  '1117',
  '1119',
  '1125',
  '1127',
  '1129',
  '1131',
  '1133',
  '1173',
  '1175',
  '1177',
  '1182',
  '1184',
  '1186',
  '1188',
  '1190',
  '1208',
  '1210',
  '1212',
  '1230',
  '1232',
  '1234',
  '1236',
  '1238',
  '1270',
  '1272',
  '1274',
  '1276',
  '1278',
  '1316',
  '1318',
  '1320',
  '1322',
  '1324',
  '1364',
  '1366',
  '1368',
  '1370',
  '1406',
  '1408',
  '1410',
  '1412',
  '1436',
  '1438',
  '1440',
  '1442',
  '1444',
  '1475',
  '1477',
  '1479',
  '1481',
  '1483',
  '1534',
  '1536',
  '1538',
  '1540',
  '1542',
  '1573',
  '1575',
  '1577',
  '1579',
  '1612',
  '1614',
  '1616',
  '1618',
  '1654',
  '1656',
  '1658',
  '1660',
  '1682',
  '1684',
  '1686',
  '1688',
  '1700',
  '1702',
  '1704',
  '1706',
  '1741',
  '1743',
  '1745',
  '1749',
  '1753',
  '1755',
  '1757',
  '1759',
  '1761',
  '1796',
  '1798',
  '1800',
  '1802',
  '1834',
  '1836',
  '1838',
  '1840',
  '1871',
  '1873',
  '1875',
  '1877',
  '1902',
  '1904',
  '1906',
  '1908',
  '1953',
  '1955',
  '1957',
  '1959',
  '1987',
  '1989',
  '1991',
  '1993',
  '2027',
  '2029',
  '2031',
  '2072',
  '2078',
  '2080',
  '2082',
  '2101',
  '2103',
  '2105',
  '2107',
  '2115',
  '2117',
  '2119',
  '2121',
  '2139',
  '2140',
  '2141',
  '2142',
  '2150',
  '2152',
  '2154',
  '2156',
  '2183',
  '2185',
  '2187',
  '2189',
  '2203',
  '2205',
  '2238',
  '2240',
  '2242',
  '2244',
  '2267',
  '2269',
  '2271',
  '2273',
  '2275',
  '2304',
  '2306',
  '2308',
  '2310',
  '2332',
  '2334',
  '2340',
  '2342',
  '2344',
  '2346',
  '2367',
  '2369',
  '2371',
  '2420',
  '2422',
  '2424',
  '2426',
  '2447',
  '2448',
  '2449',
  '2450',
  '2451',
  '2452',
  '2453',
  '2454',
  '2479',
  '2481',
  '2483',
  '2485',
  '2538',
  '2540',
  '2542',
  '2544',
  '2563',
  '2565',
  '2567',
  '2605',
  '2607',
  '2609',
  '2653',
  '2655',
  '2657',
  '2674',
  '2676',
  '2678',
  '2702',
  '2704',
  '2706',
  '2743',
  '2745',
  '2747',
  '2778',
  '2780',
  '2787',
  '2789',
  '2806',
  '2808',
  '2810',
  '2839',
  '2841',
  '2864',
  '2866',
  '2881',
  '2882',
  '2899',
  '2901',
  '2903',
  '2966',
  '2968',
  '2970',
  '2972',
  '2984',
  '2986',
  '3029',
  '3031',
  '3011',
  '3013',
  '3015',
  '3040',
  '3042',
  '3044',
  '3067',
  '3069',
  '3104',
  '3106',
  '3137',
  '3139',
  '3141',
  '3168',
  '3170',
  '3179',
  '3181',
  '3183',
  '3185',
  '3229',
  '3231',
  '3255',
  '3257',
  '3259',
  '3261',
  '3283',
  '3284',
  '3308',
  '3309',
  '3310',
  '3343',
  '3344',
  '3345',
  '3358',
  '3359',
  '3360',
  '3380',
  '3381',
  '3406',
  '3407',
  '3423',
  '3424',
  '3431',
  '3434',
  '3435',
  '3455',
  '3456',
  '3475',
  '3476',
  '3496',
  '3497',
  '3516',
  '3517',
  '3537',
  '3538',
  '3556',
  '3557',
  '3558',
  '3598',
  '3599',
  '3600',
  '3614',
  '3615',
  '3616',
  '3617',
  '3618',
  '3632',
  '3633',
  '3655',
  '3656',
  '3677',
  '3683',
  '3684',
  '3685',
  '3698',
  '3699',
  '3700',
  '3709',
  '3711',
  '3712',
  '3724',
  '3725',
  '3726',
  '3727',
  '3746',
  '3747',
  '3748',
  '3749',
  '3756',
  '3757',
  '3767',
  '3768',
  '3769',
  '3793',
  '3794',
  '3812',
  '3813',
  '3833',
  '3834',
  '3840',
  '3841',
  '3853',
  '3854',
];

export const japRrs = [];

export const rrs = [...gloRrs, ...japRrs];
