<p>Voulez-vous vraiment supprimer la Tier List: "{{ _tierListTitle }} ?"</p>

<button class="active" mat-button [mat-dialog-close]="true">
  <mat-icon>delete</mat-icon>
  Supprimer
</button>

<button class="warn" mat-button mat-dialog-close>
  <mat-icon>cancel</mat-icon>
  Annuler
</button>
