<div class="tier-list-filters">
  <div>
    <button
      mat-button
      [ngClass]="{ active: filters.showRemovedCharacters }"
      (click)="onToggleShowRemovedCharacters()"
    >
      <mat-icon>visibility</mat-icon>
      <ng-container *ngIf="language === 'FR'"
        >Voir les personnages supprimés</ng-container
      >
      <ng-container *ngIf="language === 'EN'"
        >Show removed characters</ng-container
      >
    </button>

    <ng-container *ngIf="filters.showRemovedCharacters">
      <div *ngIf="language === 'FR'">
        <br />
        Les personnages supprimés sont désormais affichés en noir et blanc dans
        le dernier Tier.
      </div>

      <div *ngIf="language === 'EN'">
        <br />
        Removed characters are now displayed in black/white filter in the last
        Tier.
      </div>
    </ng-container>
  </div>
  <br />

  <ng-container *ngIf="tierListId === _tierListIdEnum.LEGEND">
    <mat-form-field appearance="fill">
      <mat-label *ngIf="language === 'FR'">Année de sortie</mat-label>
      <mat-label *ngIf="language === 'EN'">Debut Year Selection</mat-label>

      <mat-select
        name="year-selection"
        [ngModel]="filters.selectedYearLegend"
        (ngModelChange)="_onUpdateYearSelection($event)"
      >
        <mat-option *ngIf="language === 'FR'" value="none">Aucune</mat-option>
        <mat-option *ngIf="language === 'EN'" value="none">None</mat-option>
        <mat-option value="2015">2015</mat-option>
        <mat-option value="2016">2016</mat-option>
        <mat-option value="2017">2017</mat-option>
        <mat-option value="2018">2018</mat-option>
        <mat-option value="2019">2019</mat-option>
        <mat-option value="2020">2020</mat-option>
        <mat-option value="2021">2021</mat-option>
      </mat-select>
    </mat-form-field>
    <br />
    <mat-checkbox
      [checked]="filters.hideSixStarsLegendsHavingSixPlusVersion"
      (change)="_onToggleHideSixStarsLegendsHavingSixPlusVersion()"
      >Ne pas afficher la version 6 étoiles des personnages possédant une
      6+</mat-checkbox
    >
  </ng-container>

  <!-- F2P -->
  <ng-container *ngIf="language === 'FR'">
    <mat-checkbox
      *ngIf="tierListId !== _tierListIdEnum.JPN"
      [checked]="filters.showGlobalOnly"
      (change)="onToggleShowGlobalOnlyCharacters()"
      >Voir les personnages sortis uniquement sur la version Globale
    </mat-checkbox>

    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.TM]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.TM)"
      >Afficher les personnages de TM</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="
        filters.characterTypesDisplay[_tierListCharacterType.COLOSSEUM]
      "
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.COLOSSEUM)"
      >Afficher les personnages de Colisées</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.ARENA]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.ARENA)"
      >Afficher les personnages d'Arènes</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.RAID]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.RAID)"
      >Afficher les personnages de Raids</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.KIZUNA]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.KIZUNA)"
      >Afficher les personnages de Kizuna</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.PVP_F2P]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.PVP_F2P)"
      >Afficher les personnages de PVP</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.AMBUSH]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.AMBUSH)"
      >Afficher les personnages d'attaques ennemies</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.SPECIAL]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.SPECIAL)"
      >Afficher les personnages spéciaux</mat-checkbox
    >
  </ng-container>

  <ng-container *ngIf="language === 'EN'">
    <mat-checkbox
      *ngIf="tierListId !== _tierListIdEnum.JPN"
      [checked]="filters.showGlobalOnly"
      (change)="onToggleShowGlobalOnlyCharacters()"
      >Show Only Global Characters
    </mat-checkbox>

    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.TM]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.TM)"
      >Display TM characters</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="
        filters.characterTypesDisplay[_tierListCharacterType.COLOSSEUM]
      "
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.COLOSSEUM)"
      >Display Colosseums characters</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.ARENA]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.ARENA)"
      >Display Arena characters</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.RAID]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.RAID)"
      >Display Raids characters</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.KIZUNA]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.KIZUNA)"
      >Display Kizuna characters</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.PVP_F2P]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.PVP_F2P)"
      >Display PVP characters</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.AMBUSH]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.AMBUSH)"
      >Display Ambush characters</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.F2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.SPECIAL]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.SPECIAL)"
      >Display Special characters</mat-checkbox
    >
  </ng-container>

  <!-- P2P -->
  <ng-container *ngIf="language === 'FR'">
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.LEGEND]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.LEGEND)"
      >Afficher les légendes</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.RR]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.RR)"
      >Afficher les personnages du recrutement rare</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.LRR]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.LRR)"
      >Afficher les personnages limités au recrutement rare</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="
        filters.characterTypesDisplay[_tierListCharacterType.KIZUNA_LRR]
      "
      (change)="
        _onToggleCharacterTypeDisplay(_tierListCharacterType.KIZUNA_LRR)
      "
      >Afficher les personnages limités au recrutement rare pour le
      Kizuna</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="
        filters.characterTypesDisplay[_tierListCharacterType.PERIOD_LRR]
      "
      (change)="
        _onToggleCharacterTypeDisplay(_tierListCharacterType.PERIOD_LRR)
      "
      >Afficher les personnages limités au recrutement rare pour certaines
      périodes</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.TM_LRR]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.TM_LRR)"
      >Afficher les personnages limités au recrutement rare pour le
      TM</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.SUPPORT]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.SUPPORT)"
      >Afficher les personnages spéciaux (soutiens)</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.PVP]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.PVP)"
      >Afficher les personnages limités pour la Fête des Pirates</mat-checkbox
    >
  </ng-container>

  <ng-container *ngIf="language === 'EN'">
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.LEGEND]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.LEGEND)"
      >Display Legends</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.RR]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.RR)"
      >Display rare recruits</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.LRR]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.LRR)"
      >Display limited rare recruits</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.TM_LRR]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.TM_LRR)"
      >Display limited rare recruits for Treasure Map</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="
        filters.characterTypesDisplay[_tierListCharacterType.KIZUNA_LRR]
      "
      (change)="
        _onToggleCharacterTypeDisplay(_tierListCharacterType.KIZUNA_LRR)
      "
      >Display limited rare recruits for Kizuna</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="
        filters.characterTypesDisplay[_tierListCharacterType.PERIOD_LRR]
      "
      (change)="
        _onToggleCharacterTypeDisplay(_tierListCharacterType.PERIOD_LRR)
      "
      >Display limited rare recruits for certain periods</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.SUPPORT]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.SUPPORT)"
      >Display specials characters (supports)</mat-checkbox
    >
    <mat-checkbox
      *ngIf="
        tierListId === _tierListIdEnum.P2P ||
        tierListId === _tierListIdEnum.ALL ||
        tierListId === _tierListIdEnum.JPN
      "
      [checked]="filters.characterTypesDisplay[_tierListCharacterType.PVP]"
      (change)="_onToggleCharacterTypeDisplay(_tierListCharacterType.PVP)"
      >Display PVP limited rare recruits</mat-checkbox
    >
  </ng-container>
</div>
