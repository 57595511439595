import { Legend } from '../../models/legend.interface';
import {
  CharacterClass,
  CharacterTypeClass,
} from '../../models/character-class.interface';
import { PotentialAbility } from '../../models/potential-ability';
import { PvpStyle } from 'src/app/models/pvp.interface';

export const singleLegendsPsy: Legend[] = [
  // Sengoku
  {
    id: '0459',
    title: null,
    name: 'Sengoku le Bouddha',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/E18Tace.png',
      sixStars: 'https://i.imgur.com/qBjMWrh.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Amiral de la Marine. Il a mangé le Fruit de l'Humain, version Bouddha. Il est capable de se transformer en un Bouddha géant et d'envoyer des ondes de chocs depuis la paume de ses mains. Il croit en la justice moral. Il est "Sengoku le Bouddha".`,
      type: CharacterTypeClass.PSY,
      classes: [CharacterClass.Cogneur, CharacterClass.Intellectuel],
      combo: 4,
      hp: {
        initial: 3429,
        afterLimitBreak: 3779,
        afterLimitBreakPlus: 4279,
      },
      atk: {
        initial: 1338,
        afterLimitBreak: 1588,
        afterLimitBreakPlus: 1788,
      },
      rcv: {
        initial: 389,
        afterLimitBreak: 489,
        afterLimitBreakPlus: 539,
      },
      captainAbility: `Réduit le temps de chargement des coups spéciaux de tous les personnages de 2 tours au début de la quête et multiplie l'ATK des personnages ayant un coût inférieur ou égal à 20 ou égal à 54 par 3.`,
      captainAbilityAfterLimitBreakPlus: 'Inconnue.',
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige de lourds dégâts de type PSY sur tous les ennemis et multiplie l'ATK de tous les personnages par 1,5 pendant un tour.`,
      specialTurns: {
        initial: 28,
        max: 19,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 6,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 100 en HP et ATK pour les personnages ayant un coût inférieur à 29.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [INT] avantageux pour tous les personnages.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0458.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0459.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 54,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 2 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 10% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 75 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 200 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 250 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 300 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0094.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0098.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages ayant un coût inférieur ou égal à 29'],
      levels: [
        `Multiplie l'ATK du personnage soutenu par 1,25 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 1,5 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 1,75 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 2 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK du personnage soutenu par 2,5 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Shanks V1
  {
    id: '0530',
    title: 'Empereur vêtu de noir',
    name: 'Shanks',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/iYHclpb.png',
      sixStars: 'https://i.imgur.com/NTkNwoA.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage du Roux. Membre des Quatre Empereurs et parmi les trois plus grandes puissances au monde. Il évite les conflits inutiles et jouit de l'estime de la Marine. Il est "Shanks le Roux".`,
      type: CharacterTypeClass.PSY,
      classes: [CharacterClass.Sabreur, CharacterClass.Intellectuel],
      combo: 5,
      hp: {
        initial: 3140,
        afterLimitBreak: 3620,
        afterLimitBreakPlus: 3970,
      },
      atk: {
        initial: 1400,
        afterLimitBreak: 1685,
        afterLimitBreakPlus: 1885,
      },
      rcv: {
        initial: 418,
        afterLimitBreak: 508,
        afterLimitBreakPlus: 583,
      },
      captainAbility: `Multiplie l'ATK des personnages de type PSY selon les HP de l'équipe au début du tour et augmente les chances d'apparition des cercles [PSY].`,
      captainAbilityAfterLimitBreakPlus: 'Inconnue.',
      captainAbilityAdditionnalInformation: `Par 2,75 si les HP de l'équipe sont au maximum et par 2 s'il ne reste qu'un HP à l'équipe.`,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige 35 fois son ATK en dégâts de type PSY sur tous les ennemis et réduit à 0 la défense de tous les ennemis pendant 2 tours.`,
      specialTurns: {
        initial: 25,
        max: 20,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 6,
      },
      crewmateAbilities: [
        {
          ability: `Rend les cercles [INT] avantageux pour les personnages de type PSY.`,
          needLimitBreak: true,
        },
        {
          ability: `Ajoute 3 fois son ATK en dégâts additionnels non élémentaires.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: 'https://i.imgur.com/c4NrTpZ.png',
        sixStarsPicture: 'https://i.imgur.com/gLJmOj7.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 7%.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 4% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire ?% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire ?% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 80% de chances de faire ?% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 90% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0087.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0098.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de type PSY'],
      levels: [
        `Change les cercles [STR] des personnages de type PSY en cercles [PSY] si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change les cercles [STR] des personnages de type PSY en cercles [PSY] si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change les cercles [STR] et [DEX] des personnages de type PSY en cercles [PSY] si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change les cercles [STR], [DEX] et [QCK] des personnages de type PSY en cercles [PSY] si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change les cercles de type des personnages de type PSY en cercles [PSY] si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Shanks V1 6+
  {
    id: '1707',
    title: 'Empereur',
    name: 'Shanks le Roux',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/fcvk2oY.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Chef de l'équipage de Shanks le Roux. Après son intervention visant à mettre fin à la guerre au sommet, il récupère le chapeau de Luffy et le lance à Baggy, mais préfère ne pas se rendre au chevet de son ami afin de respecter la promesse qu'ils se sont faite, même si cela fait 10 ans qu'il ne l'a pas vu.`,
      type: CharacterTypeClass.PSY,
      classes: [CharacterClass.Sabreur, CharacterClass.Intellectuel],
      combo: 5,
      hp: {
        initial: 3204,
        afterLimitBreak: 3684,
        afterLimitBreakPlus: 4034,
      },
      atk: {
        initial: 1510,
        afterLimitBreak: 1795,
        afterLimitBreakPlus: 1995,
      },
      rcv: {
        initial: 464,
        afterLimitBreak: 554,
        afterLimitBreakPlus: 629,
      },
      captainAbility: `Multiplie l'ATK des personnages de type PSY par 2,75 et les HP par 1,3. Augmente les chances d'apparition des cercles [PSY] selon les HP de l'équipe au début du tour.`,
      captainAbilityAfterLimitBreakPlus: `Multiplie les HP des personnages de type PSY par 1,3 et multiplie l'ATK des personnages de type PSY par 4,25 s'ils ont un cercle correspondant, sinon par 3,5. Augmente les chances d'apparition des cercles [PSY] selon les HP de l'équipe au début du tour.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Inflige 75 fois son ATK en dégâts non élémentaires sur un ennemi, réduit à 0 la défense de tous les ennemis pendant un tour et change les cercles de tous les personnages en cercles [PSY].`,
      specialTurns: {
        initial: 19,
        max: 14,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 6,
      },
      crewmateAbilities: [
        {
          ability: `Rend les cercles [INT] avantageux pour les personnages de type PSY.`,
          needLimitBreak: true,
        },
        {
          ability: `Ajoute 3 fois son ATK en dégâts additionnels non élémentaires.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1707.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 7%.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 4% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire ?% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire ?% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 80% de chances de faire ?% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 90% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/yellow_skull2_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0098.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0303.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de type PSY'],
      levels: [
        `Change les cercles [STR] des personnages de type PSY en cercles [PSY] si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change les cercles [STR] des personnages de type PSY en cercles [PSY] si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change les cercles [STR] et [DEX] des personnages de type PSY en cercles [PSY] si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change les cercles [STR], [DEX] et [QCK] des personnages de type PSY en cercles [PSY] si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
        `Change les cercles de type des personnages de type PSY en cercles [PSY] si le personnage soutenu utilise un coup spécial qui enlève un pourcentage d'HP ou qui inflige des dégâts aux ennemis (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Sabo V1
  {
    id: '0669',
    title: 'Armée révolutionnaire',
    name: 'Sabo',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/itUHno9.png',
      sixStars: 'https://i.imgur.com/Pajsbdg.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Commandant de l'Armée révolutionnaire. Fils noble du royaume de Goa, il prit la mer loin des tracas et autres soucis du royaume, mais un dragon céleste a rapidement détruit son navire. Dragon le révolutionnaire lui a sauvé la vie, et il a fini par rejoindre ses rangs.`,
      type: CharacterTypeClass.PSY,
      classes: [CharacterClass.Libre, CharacterClass.Ravageur],
      combo: 4,
      hp: {
        initial: 2985,
        afterLimitBreak: 3480,
        afterLimitBreakPlus: 0,
      },
      atk: {
        initial: 1422,
        afterLimitBreak: 1642,
        afterLimitBreakPlus: 0,
      },
      rcv: {
        initial: 451,
        afterLimitBreak: 536,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie les HP et la RCV de tous les personnages par 1,2 et multiplie l'ATK des personnages de classe Libre selon les HP de l'équipe au début du tour.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: `Par 2,75 si les HP de l'équipe sont au maximum et par 2 s'il ne reste qu'un HP à l'équipe.`,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Multiplie l'ATK des personnages de classe Libre par 1,5 pendant un tour et si tous les personnages font un coup "PARFAIT", multiplie l'ATK des personnages de classe Libre par 2 au tour suivant`,
      specialTurns: {
        initial: 20,
        max: 14,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 0,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour les personnages de classe Libre.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [INT] avantageux pour les personnages de classe Libre.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0668.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0669.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 2 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 4 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
        ],
      },
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 7%.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_URGENCE,
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 0,75 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 40%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 40%.`,
          `Restaure les HP à hauteur de 1,25 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 40%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
          `Restaure les HP à hauteur de 2 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0087.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0098.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Monkey D. Luffy', 'Koala'],
      levels: [
        `Ajoute 5% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 6% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 8% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 10% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 14% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Sabo V1 6+
  {
    id: '1492',
    title: 'Détenteur des pouvoirs du pyro-fruit',
    name: 'Sabo',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/dquhKdv.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Chef d'état-major de l'Armée révolutionnaire. Grâce aux pouvoirs du pyro-fruit, réapparu suite au décès de son grand frère Ace, il carbonise tous ceux qui se mettent en travers de sa route. Son objectif : venger Ace, protéger Luffy et devenir un véritable homme libre.`,
      type: CharacterTypeClass.PSY,
      classes: [CharacterClass.Libre, CharacterClass.Ravageur],
      combo: 4,
      hp: {
        initial: 3234,
        afterLimitBreak: 3729,
        afterLimitBreakPlus: 0,
      },
      atk: {
        initial: 1534,
        afterLimitBreak: 1754,
        afterLimitBreakPlus: 0,
      },
      rcv: {
        initial: 452,
        afterLimitBreak: 537,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie les HP et la RCV de tous les personnages par 1,2 et multiplie l'ATK des personnages de classe Libre par 2,75.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: [
        {
          specialTurns: {
            initial: 18,
            max: 12,
            gainWithLimitBreak: 1,
            gainWithLimitBreakPlus: 0,
          },
          special: `Multiplie l'ATK des personnages de classe Libre par 1,5 pendant un tour et si au moins 5 personnages font un coup "PARFAIT", multiplie l'ATK des personnages de classe Libre par 2 au tour suivant.`,
        },
        {
          specialTurns: {
            initial: 23,
            max: 17,
            gainWithLimitBreak: 1,
            gainWithLimitBreakPlus: 0,
          },
          special: `Multiplie l'ATK des personnages de classe Libre par 1,75 pendant un tour et si au moins 4 personnages font un coup "PARFAIT", multiplie l'ATK des personnages de classe Libre par 2 au tour suivant.`,
        },
      ],
      special: null,
      specialTurns: null,
      crewmateAbilities: [
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour les personnages de classe Libre.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [INT] avantageux pour les personnages de classe Libre.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1492.png',
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 2 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 4 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
        ],
      },
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 7%.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_URGENCE,
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 0,75 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 40%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 40%.`,
          `Restaure les HP à hauteur de 1,25 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 40%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
          `Restaure les HP à hauteur de 2 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/yellow_skull2_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0087.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0098.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Monkey D. Luffy', 'Koala'],
      levels: [
        `Ajoute 5% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 6% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 8% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 10% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 14% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Law V1
  {
    id: '1045',
    title: 'Sept grands corsaires',
    name: 'Trafalgar Law',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/g0fLJ8y.png',
      sixStars: 'https://i.imgur.com/KfbBYfI.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage du "Heart" et membre de l'ordre des sept grands corsaires. Attaché à sa liberté et hostile à toute forme d'autorité, il propose à l'équipage de Chapeau de paille de nouer une alliance de pirates.`,
      type: CharacterTypeClass.PSY,
      classes: [CharacterClass.Sabreur, CharacterClass.Libre],
      combo: 6,
      hp: {
        initial: 2790,
        afterLimitBreak: 3210,
        afterLimitBreakPlus: 0,
      },
      atk: {
        initial: 1484,
        afterLimitBreak: 1694,
        afterLimitBreakPlus: 0,
      },
      rcv: {
        initial: 470,
        afterLimitBreak: 610,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Sabreur et Libre par 2, puis par 3 après 3 "PARFAIT" d'affilée et par 4 après 5 "PARFAIT" d'affilée.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: [
        {
          specialTurns: {
            initial: 18,
            max: 13,
            gainWithLimitBreak: 4,
            gainWithLimitBreakPlus: 0,
          },
          special: `Annule les effets défensifs dont bénéficient les ennemis contre les coups spéciaux des personnages de classe Sabreur et Libre pendant un tour et inflige 20 fois son ATK en dégâts de type PSY sur un ennemi.`,
        },
        {
          specialTurns: {
            initial: 25,
            max: 20,
            gainWithLimitBreak: 4,
            gainWithLimitBreakPlus: 0,
          },
          special: `Annule les effets défensifs dont bénéficient les ennemis contre les coups spéciaux de tous les personnages pendant un tour et inflige 50 fois son ATK en dégâts de type PSY sur un ennemi.`,
        },
      ],
      special: null,
      specialTurns: null,
      crewmateAbilities: [
        {
          ability: `Si les HP sont en-dessous de 10%, ajoute 300 en RCV pour les personnages de classe Sabreur et Libre.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [INT], [RCV] et [TND] avantageux pour le personnage.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: 'https://i.imgur.com/UwrWSQ1.png',
        sixStarsPicture: 'https://i.imgur.com/MEO8XzT.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 7%.`,
        ],
      },
      {
        type: PotentialAbility.BARRIERE,
        icon: '',
        effects: [
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 80%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 50%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 4% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 6% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 80% de chances de faire 8% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 90% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0087.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0303.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Monkey D. Luffy', 'Don Quijote Rosinante'],
      levels: [
        `?.`,
        `?.`,
        `?.`,
        `?.`,
        `Restaure 3 000 HP et réduit le temps de chargement du coup spécial du personnage soutenu de 1 tour au début du dernier stage d'une quête.`,
      ],
    },
    batch: `First Legend`,
  },
  // Law V1 6+
  {
    id: '2001',
    title: `Ancien membre de l'ordre des sept grands corsaires`,
    name: 'Trafalgar Law',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/e9rpPD9.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage du "Heart". Depuis que Corazon lui a sauvé la vie il y a 13 ans, il s'est juré de vaincre Doflamingo afin de se venger, au point d'en faire son seul objectif dans la vie.`,
      type: CharacterTypeClass.PSY,
      classes: [CharacterClass.Sabreur, CharacterClass.Libre],
      combo: 4,
      hp: {
        initial: 3110,
        afterLimitBreak: 3530,
        afterLimitBreakPlus: 0,
      },
      atk: {
        initial: 1529,
        afterLimitBreak: 1739,
        afterLimitBreakPlus: 0,
      },
      rcv: {
        initial: 482,
        afterLimitBreak: 622,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Sabreur et Libre par 2,5, puis par 3,5 après 3 "PARFAIT" d'affilée et par 4 après 5 "PARFAIT" d'affilée. Restaure 1000 HP à la fin de chaque tour.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: [],
      specialTurns: {
        initial: 17,
        max: 12,
        gainWithLimitBreak: 4,
        gainWithLimitBreakPlus: 0,
      },
      special: `Annule les effets défensifs des ennemis contre les coups spéciaux de tous les personnages pendant un tour, inflige 100 fois son ATK en dégâts de type PSY sur un ennemi et réduit le temps de chargement des coups spéciaux de tous les personnages d'un tour.`,
      crewmateAbilities: [
        {
          ability: `Si les HP sont en-dessous de 10%, ajoute 300 en RCV pour les personnages de classe Sabreur et Libre.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [INT], [RCV] et [TND] avantageux pour le personnage.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f2001.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 7%.`,
        ],
      },
      {
        type: PotentialAbility.BARRIERE,
        icon: '',
        effects: [
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 80%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 50%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 4% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 6% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 80% de chances de faire 8% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 90% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/yellow_skull2_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0087.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0303.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Monkey D. Luffy', 'Don Quijote Rosinante'],
      levels: [
        `?.`,
        `?.`,
        `?.`,
        `?.`,
        `Restaure 3 000 HP et réduit le temps de chargement du coup spécial du personnage soutenu de 1 tour au début du dernier stage d'une quête.`,
      ],
    },
    batch: `First Legend`,
  },
  // Kizaru
  {
    id: '1240',
    title: null,
    name: 'Borsalino',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/4RdIJtP.png',
      sixStars: 'https://i.imgur.com/adgWTQL.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Amiral au quartier général de la Marine. D'un caractère insouciant mais capable de capturer 500 pirates par simples représailles, il se situe quelque part entre l'indépendance d'esprit d'Aokiji et le zèle forcené d'Akainu.`,
      type: CharacterTypeClass.PSY,
      classes: [CharacterClass.Sabreur, CharacterClass.Tireur],
      combo: 4,
      hp: {
        initial: 2590,
        afterLimitBreak: 2940,
        afterLimitBreakPlus: 3290,
      },
      atk: {
        initial: 1489,
        afterLimitBreak: 1739,
        afterLimitBreakPlus: 1939,
      },
      rcv: {
        initial: 500,
        afterLimitBreak: 600,
        afterLimitBreakPlus: 675,
      },
      captainAbility: `Réduit le temps de chargement des coups spéciaux de tous les personnages d'un tour au début de la quête, multiplie l'ATK de tous les personnages par 2 et la RCV par 1,3. Si le coup spécial "Perles sacrées de sagesse" est utilisé, multiplie l'ATK de tous les personnages par 3 pendant un tour.`,
      captainAbilityAfterLimitBreakPlus: `Inconnue.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: [
        {
          specialTurns: {
            initial: 6,
            max: 3,
            gainWithLimitBreak: 0,
            gainWithLimitBreakPlus: 0,
          },
          special: `Mélange les cercles qui ne sont pas correspondants des personnages de classe Sabreur et Tireur aléatoirement et inflige 10 coups avec de faibles dégâts aléatoires non élémentaires sur des ennemis aléatoires.`,
        },
        {
          specialTurns: {
            initial: 12,
            max: 9,
            gainWithLimitBreak: 0,
            gainWithLimitBreakPlus: 0,
          },
          special: `Mélange les cercles qui ne sont pas correspondants des personnages de classe Sabreur et Tireur aléatoirement, restaure les HP avec 5 fois sa RCV et inflige 10 coups avec des dégâts aléatoires non élémentaires sur des ennemis aléatoires.`,
        },
        {
          specialTurns: {
            initial: 20,
            max: 17,
            gainWithLimitBreak: 0,
            gainWithLimitBreakPlus: 0,
          },
          special: `Change les cercles des personnages de classe Sabreur et Tireur en cercles correspondants, restaure les HP avec 10 fois sa RCV et inflige 10 coups avec de lourds dégâts aléatoires non élémentaires sur des ennemis aléatoires.`,
        },
      ],
      special: null,
      specialTurns: null,
      crewmateAbilities: [
        {
          ability: `Immunise le personnage contre "Paralysie".`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [PSY] avantageux pour les personnages de classe Tireur.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1239.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1240.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 4%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 4 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
        ],
      },
      {
        type: PotentialAbility.BARRIERE,
        icon: '',
        effects: [
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 90%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 70%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 50%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0098.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0303.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Sakazuki', 'Kuzan'],
      levels: [
        `Mélange les cercles des personnages de classe Tireur si les cercles sont mélangés par les ennemis (une fois par quête).`,
        `Mélange les cercles des personnages de classe Tireur si les cercles sont mélangés par les ennemis (une fois par quête).`,
        `Change les cercles des personnages de classe Tireur en cercles correspondants si les cercles sont mélangés par les ennemis (une fois par quête).`,
        `Change les cercles des personnages de classe Tireur en cercles correspondants si les cercles sont mélangés par les ennemis (une fois par quête).`,
        `Change les cercles des personnages de classe Tireur et Sabreur en cercles correspondants si les cercles sont mélangés par les ennemis (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Kizaru 6+
  {
    id: '2034',
    title: 'La plus puissante des forces de combat de la Marine',
    name: 'Borsalino',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/sEjmxpr.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Amiral au quartier général de la Marine. Arrivé "le cœur léger" sur l'archipel des Sabaody, dont il sait qu'il abrite un grand nombre de pirates dont la prime dépasse les 100 millions, il inflige une telle correction aux supernovæ que Luffy n'a pas d'autre choix que de reconnaître sa supériorité et de préférer la fuite à la confrontation.`,
      type: CharacterTypeClass.PSY,
      classes: [CharacterClass.Sabreur, CharacterClass.Tireur],
      combo: 7,
      hp: {
        initial: 2928,
        afterLimitBreak: 3278,
        afterLimitBreakPlus: 3628,
      },
      atk: {
        initial: 1512,
        afterLimitBreak: 1762,
        afterLimitBreakPlus: 1962,
      },
      rcv: {
        initial: 508,
        afterLimitBreak: 608,
        afterLimitBreakPlus: 683,
      },
      captainAbility: `Réduit le temps de chargement des coups spéciaux de tous les personnages de 2 tours au début de la quête, multiplie l'ATK de tous les personnages par 2 et la RCV par 1,35. Si le coup spécial "Perles sacrées de sagesse - Lumière divine" est utilisé, multiplie l'ATK de tous les personnages par 3,5 pendant un tour.`,
      captainAbilityAfterLimitBreakPlus: `Réduit le temps de chargement des coups spéciaux de tous les personnages de 2 tours au début de la quête, multiplie l'ATK de tous les personnages par 3,5 et la RCV par 1,35. Si le coup spécial "Perles sacrées de sagesse - Lumière divine" est utilisé, multiplie l'ATK de tous les personnages par 4,25 pendant un tour.`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: [
        {
          specialTurns: {
            initial: 9,
            max: 6,
            gainWithLimitBreak: 0,
            gainWithLimitBreakPlus: 0,
          },
          special: `Mélange les cercles qui ne sont pas correspondants des personnages de classe Sabreur et Tireur aléatoirement, restaure les HP avec 6 fois sa RCV et inflige 20 coups avec des dégâts aléatoires non élémentaires sur des ennemis aléatoires.`,
        },
        {
          specialTurns: {
            initial: 15,
            max: 12,
            gainWithLimitBreak: 0,
            gainWithLimitBreakPlus: 0,
          },
          special: `Change les cercles des personnages de classe Sabreur et Tireur en cercles correspondants, restaure les HP avec 15 fois sa RCV et inflige 20 coups avec de lourds dégâts aléatoires non élémentaires sur des ennemis aléatoires.`,
        },
      ],
      special: null,
      specialTurns: null,
      crewmateAbilities: [
        {
          ability: `Immunise le personnage contre "Paralysie".`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [PSY] avantageux pour les personnages de classe Tireur.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f2034.png',
      },
    },
    pvpData: {
      cost: 55,
      style: PvpStyle.SUPPORT,
      ct: 15,
      specialDescription: [
        `Inflige 0,7 fois son ATK à 4 ennemis et augmente l'ATK des alliés de classe Sabreur et Tireur de 2 niveaux pendant 20 secondes.`,
        `Inflige 0,73 fois son ATK à 4 ennemis et augmente l'ATK des alliés de classe Sabreur et Tireur de 2 niveaux pendant 20 secondes.`,
        `Inflige 0,76 fois son ATK à 4 ennemis et augmente l'ATK des alliés de classe Sabreur et Tireur de 3 niveaux pendant 21 secondes.`,
        `Inflige 0,79 fois son ATK à 4 ennemis et augmente l'ATK des alliés de classe Sabreur et Tireur de 3 niveaux pendant 21 secondes.`,
        `Inflige 0,82 fois son ATK à 4 ennemis et augmente l'ATK des alliés de classe Sabreur et Tireur de 4 niveaux pendant 22 secondes.`,
        `Inflige 0,85 fois son ATK à 4 ennemis et augmente l'ATK des alliés de classe Sabreur et Tireur de 4 niveaux pendant 22 secondes.`,
        `Inflige 0,88 fois son ATK à 4 ennemis et augmente l'ATK des alliés de classe Sabreur et Tireur de 5 niveaux pendant 23 secondes.`,
        `Inflige 0,91 fois son ATK à 4 ennemis et augmente l'ATK des alliés de classe Sabreur et Tireur de 5 niveaux pendant 23 secondes.`,
        `Inflige 0,94 fois son ATK à 4 ennemis et augmente l'ATK des alliés de classe Sabreur et Tireur de 6 niveaux pendant 24 secondes.`,
        `Inflige 1 fois son ATK à 4 ennemis et augmente l'ATK des alliés de classe Sabreur et Tireur de 7 niveaux pendant 24 secondes.`,
      ],
      capacityDescription: [
        `Augmente la vitesse des alliés de classe Sabreur et Tireur de 2 niveaux. Pendant 30 secondes à partir du début du combat, augmente la vitesse de chargement des coups spéciaux des alliés de classe Sabreur et Tireur de 2 niveaux.`,
        `Augmente la vitesse des alliés de classe Sabreur et Tireur de 3 niveaux. Pendant 30 secondes à partir du début du combat, augmente la vitesse de chargement des coups spéciaux des alliés de classe Sabreur et Tireur de 2 niveaux.`,
        `Augmente la vitesse des alliés de classe Sabreur et Tireur de 4 niveaux. Pendant 30 secondes à partir du début du combat, augmente la vitesse de chargement des coups spéciaux des alliés de classe Sabreur et Tireur de 2 niveaux.`,
        `Augmente la vitesse des alliés de classe Sabreur et Tireur de 5 niveaux. Pendant 30 secondes à partir du début du combat, augmente la vitesse de chargement des coups spéciaux des alliés de classe Sabreur et Tireur de 2 niveaux.`,
        `Augmente la vitesse des alliés de classe Sabreur et Tireur de 6 niveaux. Pendant 30 secondes à partir du début du combat, augmente la vitesse de chargement des coups spéciaux des alliés de classe Sabreur et Tireur de 4 niveaux.`,
      ],
      capacityAttributes: [
        {
          SPEED: {
            value: 6,
            targets: [CharacterClass.Sabreur, CharacterClass.Tireur],
            fromTime: 100,
            toTime: 0,
          },
          CT_SPEED: {
            value: 4,
            targets: [CharacterClass.Sabreur, CharacterClass.Tireur],
            fromTime: 100,
            toTime: 70,
          },
        },
      ],
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 4%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 4 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
        ],
      },
      {
        type: PotentialAbility.BARRIERE,
        icon: '',
        effects: [
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 90%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 70%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 50%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/yellow_skull2_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0087.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0303.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Sakazuki', 'Kuzan'],
      levels: [
        `Mélange les cercles des personnages de classe Tireur si les cercles sont mélangés par les ennemis (une fois par quête).`,
        `Mélange les cercles des personnages de classe Tireur si les cercles sont mélangés par les ennemis (une fois par quête).`,
        `Change les cercles des personnages de classe Tireur en cercles correspondants si les cercles sont mélangés par les ennemis (une fois par quête).`,
        `Change les cercles des personnages de classe Tireur en cercles correspondants si les cercles sont mélangés par les ennemis (une fois par quête).`,
        `Change les cercles des personnages de classe Tireur et Sabreur en cercles correspondants si les cercles sont mélangés par les ennemis (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Law V3
  {
    id: '3027',
    title: 'Alliance pour ouvrir le pays des Wa',
    name: 'Trafalgar Law',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/4RdIJtP.png',
      sixStars: 'https://i.imgur.com/adgWTQL.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage du "Heart". Il prouve encore une fois au pays des Wa que ses capacités sont suffisantes pour faire de lui un membre des sept grands corsaires. Lors de son affrontement contre Hawkins, il comprend immédiatement les pouvoirs de son adversaire et assène des coups de sabre aux hommes d'Hawkins afin de sceller sa substitution.`,
      type: CharacterTypeClass.PSY,
      classes: [CharacterClass.Libre, CharacterClass.Sabreur],
      combo: 5,
      hp: {
        initial: 3218,
        afterLimitBreak: 3718,
        afterLimitBreakPlus: 4068,
      },
      atk: {
        initial: 1640,
        afterLimitBreak: 1840,
        afterLimitBreakPlus: 2040,
      },
      rcv: {
        initial: 317,
        afterLimitBreak: 417,
        afterLimitBreakPlus: 492,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Libre, Cogneur et Ravageur par 4,5 si les HP sont au maximum, sinon par 4, restaure 1 500 HP à la fin de chaque tour et réduit les dégâts subis de 25%.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: [],
      special: `Change les cercles [EMP], [BLK], [BOMB], [RCV], [G], désavantageux et son cercle en cercles correspondants. Si le capitaine est un personnage de classe Cogneur, Sabreur, Ravageur ou Tireur, multiplie les effets des cercles de tous les personnages par 2,25 pendant 2 tours et si le capitaine est un personnage de classe Libre, Intellectuel, Tenace ou Ambitieux, bloque le minimum du multiplicateur de combos à 2,5 et le maximum du multiplicateur de combos à 35 pendant 2 tours.`,
      specialTurns: null,
      crewmateAbilities: [
        {
          ability: `Si un autre personnage de classe Libre ou Sabreur utilise son coup spécial, réduit le temps de chargement du coup spécial du personnage d'un tour.`,
          needLimitBreak: true,
        },
        {
          ability: `Ajoute 300 sur les HP restaurés par les cercles [RCV].`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1239.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1240.png',
        sixPlusStarsPicture: null,
      },
    },
    pvpData: {
      cost: 55,
      style: PvpStyle.ATK,
      ct: 35,
      specialDescription: [
        `Inflige 1 100 points de dégâts aux ennemis à longue portée, réduit la durée de "Lien des coups spéciaux" des alliés de type PSY pendant 15 secondes, réduit la durée de "Paralysie" des alliés de type PSY pendant 15 secondes et réduit la durée de "Lien des actions" des alliés de type PSY pendant 15 secondes.`,
        `Inflige 1 200 points de dégâts aux ennemis à longue portée, réduit la durée de "Lien des coups spéciaux" des alliés de type PSY pendant 15 secondes, réduit la durée de "Paralysie" des alliés de type PSY pendant 15 secondes et réduit la durée de "Lien des actions" des alliés de type PSY pendant 15 secondes.`,
        `Inflige 1 300 points de dégâts aux ennemis à longue portée, réduit la durée de "Lien des coups spéciaux" des alliés de type PSY pendant 16 secondes, réduit la durée de "Paralysie" des alliés de type PSY pendant 16 secondes et réduit la durée de "Lien des actions" des alliés de type PSY pendant 16 secondes.`,
        `Inflige 1 400 points de dégâts aux ennemis à longue portée, réduit la durée de "Lien des coups spéciaux" des alliés de type PSY pendant 17 secondes, réduit la durée de "Paralysie" des alliés de type PSY pendant 17 secondes et réduit la durée de "Lien des actions" des alliés de type PSY pendant 17 secondes.`,
        `Inflige 1 500 points de dégâts aux ennemis à longue portée, réduit la durée de "Lien des coups spéciaux" des alliés de type PSY pendant 18 secondes, réduit la durée de "Paralysie" des alliés de type PSY pendant 18 secondes et réduit la durée de "Lien des actions" des alliés de type PSY pendant 18 secondes.`,
        `Inflige 1 600 points de dégâts aux ennemis à longue portée, réduit la durée de "Lien des coups spéciaux" des alliés de type PSY pendant 19 secondes, réduit la durée de "Paralysie" des alliés de type PSY pendant 19 secondes et réduit la durée de "Lien des actions" des alliés de type PSY pendant 19 secondes.`,
        `Inflige 1 700 points de dégâts aux ennemis à longue portée, réduit la durée de "Lien des coups spéciaux" des alliés de type PSY pendant 19 secondes, réduit la durée de "Paralysie" des alliés de type PSY pendant 19 secondes et réduit la durée de "Lien des actions" des alliés de type PSY pendant 19 secondes.`,
        `Inflige 1 800 points de dégâts aux ennemis à longue portée, réduit la durée de "Lien des coups spéciaux" des alliés de type PSY pendant 20 secondes, réduit la durée de "Paralysie" des alliés de type PSY pendant 20 secondes et réduit la durée de "Lien des actions" des alliés de type PSY pendant 20 secondes.`,
        `Inflige 1 900 points de dégâts aux ennemis à longue portée, réduit la durée de "Lien des coups spéciaux" des alliés de type PSY pendant 21 secondes, réduit la durée de "Paralysie" des alliés de type PSY pendant 21 secondes et réduit la durée de "Lien des actions" des alliés de type PSY pendant 21 secondes.`,
        `Inflige 2 200 points de dégâts aux ennemis à longue portée, réduit la durée de "Lien des coups spéciaux" des alliés de type PSY pendant 22 secondes, réduit la durée de "Paralysie" des alliés de type PSY pendant 22 secondes et réduit la durée de "Lien des actions" des alliés de type PSY pendant 22 secondes.`,
      ],
      capacityDescription: [
        `Augmente la vitesse de chargement des coups spéciaux des alliés de type PSY de 2 niveaux. Pendant 40 secondes à partir du début du combat, réduit la vitesse de chargement des coups spéciaux des ennemis de classe Ambitieux et Tenace de 2 niveaux, réduit les chances de réussite de "Garde" des ennemis de classe Ambitieux et Tenace de 2 niveaux et réduit la vitesse des ennemis de classe Ambitieux et Tenace de 2 niveaux.`,
        `Augmente la vitesse de chargement des coups spéciaux des alliés de type PSY de 3 niveaux. Pendant 40 secondes à partir du début du combat, réduit la vitesse de chargement des coups spéciaux des ennemis de classe Ambitieux et Tenace de 3 niveaux, réduit les chances de réussite de "Garde" des ennemis de classe Ambitieux et Tenace de 3 niveaux et réduit la vitesse des ennemis de classe Ambitieux et Tenace de 3 niveaux.`,
        `Augmente la vitesse de chargement des coups spéciaux des alliés de type PSY de 4 niveaux. Pendant 40 secondes à partir du début du combat, réduit la vitesse de chargement des coups spéciaux des ennemis de classe Ambitieux et Tenace de 4 niveaux, réduit les chances de réussite de "Garde" des ennemis de classe Ambitieux et Tenace de 4 niveaux et réduit la vitesse des ennemis de classe Ambitieux et Tenace de 4 niveaux.`,
        `Augmente la vitesse de chargement des coups spéciaux des alliés de type PSY de 5 niveaux. Pendant 40 secondes à partir du début du combat, réduit la vitesse de chargement des coups spéciaux des ennemis de classe Ambitieux et Tenace de 5 niveaux, réduit les chances de réussite de "Garde" des ennemis de classe Ambitieux et Tenace de 5 niveaux et réduit la vitesse des ennemis de classe Ambitieux et Tenace de 5 niveaux.`,
        `Augmente la vitesse de chargement des coups spéciaux des alliés de type PSY de 6 niveaux. Pendant 40 secondes à partir du début du combat, réduit la vitesse de chargement des coups spéciaux des ennemis de classe Ambitieux et Tenace de 6 niveaux, réduit les chances de réussite de "Garde" des ennemis de classe Ambitieux et Tenace de 6 niveaux et réduit la vitesse des ennemis de classe Ambitieux et Tenace de 6 niveaux.`,
      ],
      capacityAttributes: [
        {
          CT_SPEED: {
            value: 6,
            targets: [CharacterTypeClass.PSY],
            fromTime: 100,
            toTime: 0,
          },
        },
      ],
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.SOIN_URGENCE,
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 10%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 20%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 30%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 40%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0098.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0303.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Bepo', 'Don Quijote Rosinante'],
      levels: [
        `Change les cercles [RCV] et [G] des personnages de classe Libre en cercles correspondants si les cercles sont mélangés par les ennemis (une fois par quête).`,
        `Change les cercles [BOMB], [RCV] et [G] des personnages de classe Libre en cercles correspondants si les cercles sont mélangés par les ennemis (une fois par quête).`,
        `Change les cercles [EMP], [BOMB], [RCV] et [G] des personnages de classe Libre en cercles correspondants si les cercles sont mélangés par les ennemis (une fois par quête).`,
        `Change les cercles [EMP], [BOMB], [RCV], [G] et désavantageux des personnages de classe Libre en cercles correspondants si les cercles sont mélangés par les ennemis (une fois par quête).`,
        `Change les cercles [EMP], [BLK], [BOMB], [RCV], [G], désavantageux des personnages de classe Libre en cercles correspondants si les cercles sont mélangés par les ennemis (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
];
