<mat-tab-group>
  <mat-tab label="Informations Générales">
    <div class="informations-content">
      <strong>Bienvenue sur OPTC TierList Maker</strong>
      <br /><br />

      Ici vous pouvez réaliser vos différentes Tier List pour One Piece Treasure
      Cruise par catégorie.
      <br /><br />

      Voici la liste des différentes catégories :<br />

      <ul>
        <li>Légendes</li>
        <li>
          Personnages du recrutement rare (n'inclue pas les personnages limités
          comme les boosters TM, les World Journey, les World Clash etc...)
        </li>
        <li>
          Personnages limités au recrutement rare (plus connus sous le terme de
          LRR, ceux que vous pouvez acheter au Ray Shop via un ticket par
          exemple)
        </li>
        <li>Personnages TM</li>
        <li>Personnages de combat crucial</li>
        <li>Personnages de colisée</li>
        <li>Personnages d'attaque ennemie</li>
        <li>Personnages limités pour la fête des pirates (PVP)</li>
        <li>Personnages du Kizuna</li>
      </ul>

      Via ces différentes catégories, vous pouvez réaliser vos Tier Lists de
      toutes les façons que vous souhaitez. <br /><br />

      Il existe même une <strong>Tier List "F2P"</strong> ou encore une
      <strong>Tier List "Personnages Premium"</strong>. <br /><br />

      Une dernière Tier List permet même de regrouper
      <strong>absolument tous les personnages</strong> des différentes
      catégories, vous pouvez ainsi faire tout ce que vous voulez ! <br /><br />

      Vous pouvez par exemple en ayant accès à tous les personnages faire une
      Tier List pour le PVP avec les légendes, les LRR PVP, les personnages
      raids etc... <br /><br />

      <strong
        >Et surtout n'oubliez pas, vous pouvez changer le nom de toutes vos Tier
        List et en créer autant que vous le souhaitez !</strong
      >
      <br /><br />

      <strong>Amusez-vous bien !</strong>

      <p class="hint">
        PS : Cette application est avant tout destinée à un usage sur ordinateur
      </p>
      <div class="copyright">©2020 webarranco</div>
    </div>
  </mat-tab>

  <mat-tab label="Fonctionnalités">
    <div class="informations-content">
      <div class="subtitle"><strong>Informations générales</strong></div>

      <ul>
        <li>Le titre de la Tier list est modifiable</li>
        <li>Les noms des différents Tiers sont modifiables</li>
        <li>
          Vous pouvez double-cliquer sur un personnage pour accéder à sa fiche
          détaillée.
        </li>
        <li>
          Uniquement dans le dernier Tier,
          <strong
            >vous pouvez effectuer un clic droit sur ordinateur (rester appuyé
            sur mobile) sur un personnage pour le supprimer de la Tier
            List</strong
          >.
        </li>
      </ul>

      <div class="subtitle">
        <strong>Filtres</strong>
      </div>

      <ul>
        <li>
          Vous pouvez ré-afficher les personnages supprimés de votre Tier List.
          Les personnages peuvent ensuite être remis dans la tier list avec un
          nouveau clic droit.
        </li>
        <li>
          Sur la Tier List regroupant les Légendes, vous pouvez filtrer par
          année de sortie.
        </li>
        <li>
          Sur les Tier List F2P, Premium ainsi que celle regroupant tous les
          personnages, vous pouvez filtrer par catégorie de personnages.
        </li>
      </ul>

      <div class="subtitle">
        <strong>Sauvegarde, chargement et suppression</strong>
      </div>

      <ul>
        <li>
          Vous pouvez sauvegarder votre Tier List à tout moment pour la
          reprendre plus tard ou simplement la garder. Votre Tier List est
          stockée dans votre propre navigateur via le Local Storage.
        </li>
        <li>
          Vous pouvez
          <strong
            >sauvegarder autant de Tier Lists que vous le souhaitez</strong
          >
        </li>
        <li>
          <strong
            >Vos Tier Lists seront sauvegardées d'après leur titre. Une Tier
            List chargée puis modifiée sera écrasée. Si vous sauvegardez une
            Tier List, celle-ci écrasera la sauvegarde de la Tier List du même
            nom.</strong
          >
        </li>
        <li>
          Vous pouvez charger une Tier List précédemment sauvegardée.
          <strong>Cela effacera votre Tier List en cours</strong> si elle n'a
          pas été sauvegardée au préalable.
        </li>
        <li>Vous pouvez supprimer une Tier List précédemment sauvegardée</li>
      </ul>

      <div class="subtitle"><strong>Partage et export</strong></div>

      <ul>
        <li>
          <strong>Vous pouvez partager une Tier List</strong> via une simple
          URL. Cliquez sur le bouton de partage et l'URL contenant votre Tier
          List sera copiée dans votre presse-papiers (faites CTRL+V ensuite)
        </li>
        <li>Vous pouvez exporter votre Tier List sous forme d'une image</li>
        <li>
          Vous pouvez exporter votre Tier List sous forme d'une image tout en
          choisissant de cacher le dernier Tier
        </li>
      </ul>
    </div>
  </mat-tab>
</mat-tab-group>
