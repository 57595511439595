export const glbColisees = [
  '0777',
  '0779',
  '0781',
  '0804',
  '0806',
  '0831',
  '0833',
  '0834',
  '0860',
  '0862',
  '0882',
  '0884',
  '0901',
  '0903',
  '0904',
  '0924',
  '0926',
  '0927',
  '0951',
  '0953',
  '0983',
  '0984',
  '1016',
  '1018',
  '1019',
  '1041',
  '1043',
  '1087',
  '1089',
  '1091',
  '1102',
  '1104',
  '1106',
  '1141',
  '1143',
  '1145',
  '1196',
  '1197',
  '1220',
  '1222',
  '1251',
  '1252',
  '1253',
  '1292',
  '1294',
  '1310',
  '1312',
  '1338',
  '1339',
  '1341',
  '1376',
  '1378',
  '1401',
  '1402',
  '1422',
  '1423',
  '1424',
  '1456',
  '1458',
  '1460',
  '1487',
  '1489',
  '1491',
  '1525',
  '1527',
  '1528',
  '1553',
  '1555',
  '1584',
  '1586',
  '1602',
  '1603',
  '1628',
  '1630',
  '1667',
  '1694',
  '1733',
  '1792',
  '1828',
  '1861',
  '1863',
  '1897',
  '1899',
  '1945',
  '1976',
  '1978',
  '2017',
  '2037',
  '2111',
  '2123',
  '2163',
  '2193',
  '2249',
  '2290',
  '2323',
  '2354',
  '2375',
  '2416',
  '2471',
  '2489',
  '2512',
  '2529',
  '2550',
  '2569',
  '2611',
  '2649',
  '2721',
  '2755',
  '2785',
  '2813',
  '2845',
  '2869',
  '2908',
  '2953',
  '2990',
  '3372',
  '3017',
  '3049',
  '3083',
  '3110',
];

export const japColisees = [];

export const colisees = [...glbColisees, ...japColisees];
