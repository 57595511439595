export const gloPvps = [
  '2934',
  '2989',
  '3016',
  '3047',
  '3082',
  '3107',
  '3142',
  '3173',
  '3208',
  '3233',
  '3251',
  '3285',
  '3332',
  '3374',
  '3396',
  '3415',
  '3436',
  '3466',
  '3486',
  '3505',
  '3526',
];

export const japPvps = [];

export const pvps = [...gloPvps, ...japPvps];
