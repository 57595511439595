export const glbSpecials = [
  '2499',
  '2628',
  '2668',
  '2709',
  '2738',
  '2749',
  '2767',
  '2799',
  '2815',
  '2843',
  '2867',
  '2875',
  '2883',
  '2884',
  '2915',
  '2916',
  '2918',
  '2975',
  '2976',
  '2988',
  '3004',
  '3045',
  '3046',
  '3050',
  '3186',
  '3187',
  '3188',
  '5065',
  '3032',
  '3033',
  '3034',
  '3035',
  '3086',
  '3108',
  '3109',
  '3144',
  '3171',
  '2855',
  '3222',
  '3223',
  '3247',
  '3262',
  '3286',
  '3287',
  '3304',
  '3305',
  '3346',
  '3353',
  '3382',
  '3400',
  '3401',
  '3425',
  '3436',
  '3457',
  '3477',
  '3481',
  '3498',
  '3518',
  '3520',
];

export const japSpecials = [];

export const specials = [...glbSpecials, ...japSpecials];
