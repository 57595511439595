<p>Are you sure you want to remove your Tier List : "{{ _tierListTitle }}" ?</p>

<button class="active" mat-button [mat-dialog-close]="true">
  <mat-icon>delete</mat-icon>
  Remove
</button>

<button class="warn" mat-button mat-dialog-close>
  <mat-icon>cancel</mat-icon>
  Cancel
</button>
