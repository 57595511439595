export const glbTms = [
  '1808',
  '1853',
  '1889',
  '1916',
  '1941',
  '1972',
  '2000',
  '2064',
  '2109',
  '2137',
  '2175',
  '2211',
  '2261',
  '2299',
  '2336',
  '2362',
  '2387',
  '2443',
  '2469',
  '2510',
  '2557',
  '2583',
  '2618',
  '2659',
  '2690',
  '2729',
  '2763',
  '2792',
  '2823',
  '2850',
  '2879',
  '2913',
  '2977',
  '2995',
  '3022',
  '3060',
  '3115',
  '3094',
  '3150',
  '3197',
  '3217',
  '3239',
  '3273',
  '3297',
  '3339',
  '3368',
  '3389',
  '3416',
  '3443',
  '3467',
  '3491',
  '3506',
  '3527',
  '3547',
  '3567',
  '3588',
  '3621',
  '3645',
  '3663',
  '3689',
  '3712',
  '3736',
  '3758',
  '3778',
  '3801',
  '3822',
  '3842',
];

export const specialTms = ['2439', '2632', '2661', '2880'];

export const japTms = [];

export const tms = [...specialTms, ...glbTms, ...japTms];
