<p>
  A saved Tier list with the same title already exists. Do you want to override
  it ?
</p>

<button class="active" mat-button [mat-dialog-close]="true">
  <mat-icon>save</mat-icon>
  Save
</button>

<button class="warn" mat-button mat-dialog-close>
  <mat-icon>cancel</mat-icon>
  Cancel
</button>
