<div class="header">
  <h1>OPTC Tier List Maker</h1>
  <div class="page-title">
    <input [(ngModel)]="tierListTitle" />
  </div>

  <div class="streamer-mode">
    <button
      mat-button
      [ngClass]="{ active: _isStreamerMode }"
      (click)="_toggleStreamerMode()"
    >
      <span *ngIf="language === 'EN'">Streamer Mode</span>
      <span *ngIf="language === 'FR'">Mode Streamer</span>
    </button>
  </div>

  <div class="languages" [ngClass]="{ 'languages-hidden': _isStreamerMode }">
    <button
      mat-button
      [ngClass]="{ active: language === 'FR' }"
      (click)="changeLanguage('FR')"
    >
      FR
    </button>
    <button
      mat-button
      [ngClass]="{ active: language === 'EN' }"
      (click)="changeLanguage('EN')"
    >
      EN
    </button>
  </div>
</div>

<ul *ngIf="currentTierList && !_isStreamerMode" class="nav">
  <ng-container *ngFor="let tierList of tierLists; let tierIndex = index">
    <li
      *ngIf="tierIndex < 5"
      [ngClass]="{ active: currentTierList.id === tierList.id }"
      (click)="_switchTierList(tierList.id)"
    >
      <span *ngIf="language === 'FR'"> {{ tierList.frenchName }}</span>
      <span *ngIf="language === 'EN'"> {{ tierList.englishName }}</span>
    </li>
  </ng-container>

  <li>
    <span *ngIf="language === 'FR'">Autres</span>
    <span *ngIf="language === 'EN'">Others</span>

    <ul>
      <ng-container *ngFor="let tierList of tierLists; let tierIndex = index">
        <li
          *ngIf="tierIndex > 4"
          [ngClass]="{ active: currentTierList.id === tierList.id }"
          (click)="_switchTierList(tierList.id)"
        >
          <span *ngIf="language === 'FR'"> {{ tierList.frenchName }}</span>
          <span *ngIf="language === 'EN'"> {{ tierList.englishName }}</span>
        </li>
      </ng-container>
    </ul>
  </li>
</ul>

<div *ngIf="currentTierList" class="container">
  <app-tier-list-actions
    class="actions-list"
    [hidden]="!showActions"
    [tierListTitle]="tierListTitle"
    [tiers]="tiers"
    [currentTierListId]="currentTierList.id"
    [removedCharacters]="removedCharacters"
    [language]="language"
    (loadTierList)="_onLoadTierList($event)"
    (exportTierList)="_onExportTierList($event)"
    (exportAllTierLists)="_onExportAllTierLists()"
  ></app-tier-list-actions>

  <div [hidden]="!showFilters">
    <!-- <button mat-button>Hide Legends 6+ version</button> -->
    <!-- <button mat-button>
      Hide Legends 6 stars version when legend also have a 6+ version
    </button> -->

    <app-tier-list-filters
      [language]="language"
      [tierListId]="currentTierList.id"
      [filters]="_filters"
      (updateYearSelection)="_onUpdateYearSelection($event)"
      (toggleCharacterTypeDisplay)="_onToggleCharacterTypeDisplay($event)"
      (toggleShowRemovedCharacters)="_onToggleShowRemovedCharacters()"
      (toggleHideSixStarsLegendsHavingSixPlusVersion)="
        _onToggleHideSixStarsLegendsHavingSixPlusVersion()
      "
      (toggleShowGlobalOnlyCharacters)="_onToggleShowGlobalOnlyCharacters()"
    ></app-tier-list-filters>
  </div>

  <br />

  <div id="download" style="visibility: hidden; height: 0">
    <img #canvas />
    <a #downloadLink></a>
  </div>

  <div cdkDropListGroup>
    <table #screen>
      <tbody>
        <ng-container *ngFor="let tier of tiers; let i = index">
          <tr *ngIf="!_isLastTier(tier) || !hideLastTier">
            <td class="labelHolder">
              <div
                class="label-holder"
                [ngStyle]="{ 'background-color': tier.color }"
                (blur)="updateTierName($event.target.innerText, i)"
              >
                <span contenteditable="true" [textContent]="tier.name"></span>
              </div>
            </td>

            <td
              class="tier-characters"
              cdkDropList
              [cdkDropListData]="tier.characters"
              (cdkDropListDropped)="drop($event)"
            >
              <div class="tier sort" id="rows">
                <ng-container *ngFor="let character of tier.characters">
                  <ng-container *ngIf="!_isLastTier(tier)">
                    <img
                      [src]="_getCharacterImgPath(character)"
                      [ngStyle]="_getImgStyleFormFiltering(character.id)"
                      width="64"
                      (dblclick)="_seeCharacterInformation(character.id)"
                      cdkDrag
                      [cdkDragData]="character"
                    />
                  </ng-container>

                  <ng-container *ngIf="_isLastTier(tier)">
                    <img
                      [src]="_getCharacterImgPath(character)"
                      [ngStyle]="_getImgStyleFormFiltering(character.id)"
                      width="64"
                      (contextmenu)="removeCharacterFromTier(tier, character)"
                      (dblclick)="_seeCharacterInformation(character.id)"
                      cdkDrag
                      [cdkDragData]="character"
                    />
                  </ng-container>
                </ng-container>

                <ng-container
                  *ngIf="_filters.showRemovedCharacters && _isLastTier(tier)"
                >
                  <img
                    *ngFor="let character of removedCharacters"
                    class="removed-character"
                    [ngStyle]="_getImgStyleFormFiltering(character.id)"
                    [src]="_getCharacterImgPath(character)"
                    width="64"
                    (contextmenu)="putCharacterBackInTier(tier, character)"
                  />
                </ng-container>
              </div>
            </td>
            <td *ngIf="!_isExporting" class="tier-actions">
              <div class="tier-settings" (click)="_onOpenTierSettings(tier)">
                <mat-icon>settings</mat-icon>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<div class="fixed-buttons">
  <button mat-icon-button (click)="_onSaveTierList()">
    <mat-icon>save</mat-icon>
  </button>

  <button
    mat-icon-button
    [ngClass]="{ active: showActions }"
    (click)="_toggleActionsButton()"
  >
    <mat-icon>settings</mat-icon>
  </button>

  <button
    mat-icon-button
    [ngClass]="{ active: showFilters }"
    (click)="_toggleFiltersButton()"
  >
    <mat-icon>filter_list</mat-icon>
  </button>

  <button
    *ngIf="!_isStreamerMode"
    mat-icon-button
    (click)="showInformationsDialog()"
  >
    <mat-icon>info</mat-icon>
  </button>
</div>
