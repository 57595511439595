import {
  CharacterClass,
  CharacterTypeClass,
} from '../models/character-class.interface';
import { Legend } from '../models/legend.interface';
import { PvpStyle } from '../models/pvp.interface';

export const dualLegends: Legend[] = [
  {
    id: '2862',
    title: 'Pirates solidaires',
    name: 'Équipage de Shanks le Roux',
    isDual: true,
    artwork: {
      fiveStars: 'https://i.imgur.com/HxLigTR.png',
      sixStars: 'https://i.imgur.com/94L21Il.png',
      sixPlusStars: null,
    },
    dualCharacterData: {
      hasOnlyOneSpecial: true,
      hasDifferentCrewmateAbility1: false,
      hasDifferentCrewmateAbility2: false,
      special: `Enlève 20% des HP de tous les ennemis en ignorant les effets défensifs, bloque le multiplicateur de combos à 3,5 pendant 3 tours et devient "Équipage de Shanks le Roux" pendant 3 tours.`,
      specialTurns: {
        initial: 18,
        max: 13,
        gainWithLimitBreak: 0,
        gainWithLimitBreakPlus: 0,
      },
      specialWithStages: [],
      switchEffect: `Change les cercles adjacents en cercles correspondants et multiplie l'ATK de tous les personnages par 1,5 pendant un tour.`,
      firstForm: {
        name: 'Shanks le Roux',
        story: `Capitaine de l'équipage de Shanks le Roux. Le dernier jour de son long séjour au village de Fuchsia, il confie à Luffy son précieux chapeau de paille et lui demande de le lui rendre lorsqu'il sera devenu un vrai pirate.`,
        type: CharacterTypeClass.PSY,
        classes: [CharacterClass.Sabreur, CharacterClass.Intellectuel],
        combo: 5,
        hp: {
          initial: 3756,
          afterLimitBreak: 4236,
          afterLimitBreakPlus: 4736,
        },
        atk: {
          initial: 1512,
          afterLimitBreak: 1732,
          afterLimitBreakPlus: 1932,
        },
        rcv: {
          initial: 295,
          afterLimitBreak: 395,
          afterLimitBreakPlus: 445,
        },
        captainAbility: `Multiplie l'ATK de tous les personnages par 3,5, les HP par 1,2, rend les cercles [QCK] avantageux pour tous les personnages et réduit la durée de "Lien des coups spéciaux" de 10 tours.`,
        captainAbilityAfterLimitBreakPlus: null,
        captainAbilityAdditionnalInformation: null,
        ownSpecial: null,
        crewmateAbilities: [],
        picture: {
          fiveStarsPicture: 'https://i.imgur.com/628lFI3.png',
          sixStarsPicture: 'https://i.imgur.com/zmgMSzO.png',
          sixPlusStarsPicture: '',
        },
      },
      secondForm: {
        name: 'Ben Beckmann, Lucky Roo et Yasopp',
        story: `Lieutenants de l'équipage de Shanks le Roux. Lorsque les brigands les menacent avec leurs sabres et leurs armes à feu, ils n'hésitent pas à employer la force et à faire la démonstration de leur supériorité, quitte à rompre avec l'image joyeuse qu'ils ont l'habitude de donner.`,
        type: CharacterTypeClass.QCK,
        classes: [CharacterClass.Tireur, CharacterClass.Intellectuel],
        combo: 4,
        hp: {
          initial: 3756,
          afterLimitBreak: 4236,
          afterLimitBreakPlus: 4736,
        },
        atk: {
          initial: 1431,
          afterLimitBreak: 1651,
          afterLimitBreakPlus: 1851,
        },
        rcv: {
          initial: 322,
          afterLimitBreak: 422,
          afterLimitBreakPlus: 472,
        },
        captainAbility: `Multiplie l'ATK de tous les personnages par 3,25, les HP par 1,2, rend les cercles [PSY] avantageux pour tous les personnages, réduit les dégâts subis de 10% et réduit la durée de "Lien des coups spéciaux" de 10 tours.`,
        captainAbilityAfterLimitBreakPlus: null,
        captainAbilityAdditionnalInformation: null,
        ownSpecial: null,
        crewmateAbilities: [],
        picture: {
          fiveStarsPicture: 'https://i.imgur.com/8xl5NjD.png',
          sixStarsPicture: 'https://i.imgur.com/dwSWhXt.png',
          sixPlusStarsPicture: '',
        },
      },
      finalForm: {
        name: 'Équipage de Shanks le Roux',
        story: `Capitaine et lieutenants de l'équipage de Shanks le Roux. Malgré leur bonté d'âme, ils se montrent sans pitié avec ceux qui s'en prennent à leurs amis, comme lorsqu'ils sortent leurs armes pour sauver Luffy de ses agresseurs.`,
        type: 'PSY/QCK',
        classes: [CharacterClass.Sabreur, CharacterClass.Intellectuel],
        combo: 6,
        hp: {
          initial: 4040,
          afterLimitBreak: 4520,
          afterLimitBreakPlus: 5020,
        },
        atk: {
          initial: 1625,
          afterLimitBreak: 1845,
          afterLimitBreakPlus: 2045,
        },
        rcv: {
          initial: 360,
          afterLimitBreak: 460,
          afterLimitBreakPlus: 510,
        },
        captainAbility: `Multiplie l'ATK de tous les personnages par 4,5, les HP par 1,2, rend les cercles [QCK] et [PSY] avantageux pour tous les personnages, réduit les dégâts subis de 20%, réduit la durée de "Lien des coups spéciaux" de 10 tours et inflige des dégâts non élémentaires sur tous les ennemis à la fin de chaque tour selon le nombre de "PARFAIT" effectués.`,
        captainAbilityAdditionnalInformation: `Aucun dégât si aucun "PARFAIT" n'a été effectué au tour précédent, 5 fois son ATK si un "PARFAIT" a été effectué au tour précédent, 13 fois son ATK si 2 "PARFAIT" ont été effectués au tour précédent, 32 fois son ATK si 3 "PARFAIT" ont été effectués au tour précédent, 80 fois son ATK si 4 "PARFAIT" ont été effectués au tour précédent, 350 fois son ATK si 5 "PARFAIT" ont été effectués au tour précédent et 1 000 fois son ATK si 6 "PARFAIT" ont été effectués au tour précédent.
        `,
        captainAbilityAfterLimitBreakPlus: null,
        ownSpecial: null,
        crewmateAbilities: [
          {
            ability: `Immunise le personnage contre “Lien des coups spéciaux”.`,
            needLimitBreak: false,
          },
          {
            ability: `Si le personnage est le dernier à attaquer, ajoute 250 en ATK pour le personnage.`,
            needLimitBreak: true,
          },
        ],
        picture: {
          fiveStarsPicture: 'https://i.imgur.com/AwDf52O.png',
          sixStarsPicture: 'https://i.imgur.com/LxKmEaI.png',
          sixPlusStarsPicture: '',
        },
      },
    },
    singleCharacterData: null,
    pvpData: {
      cost: 55,
      style: PvpStyle.DEF,
      ct: 24,
      specialDescription: [
        `Enlève 18% des HP des ennemis à moyenne portée (horizontal) et augmente la défense de tous les alliés de 2 niveaux pendant 5 secondes.`,
        `Enlève 18% des HP des ennemis à moyenne portée (horizontal) et augmente la défense de tous les alliés de 2 niveaux pendant 6 secondes.`,
        `Enlève 18% des HP des ennemis à moyenne portée (horizontal) et augmente la défense de tous les alliés de 2 niveaux pendant 7 secondes.`,
        `Enlève 18% des HP des ennemis à moyenne portée (horizontal) et augmente la défense de tous les alliés de 2 niveaux pendant 8 secondes.`,
        `Enlève 19% des HP des ennemis à moyenne portée (horizontal) et augmente la défense de tous les alliés de 2 niveaux pendant 9 secondes.`,
        `Enlève 19% des HP des ennemis à moyenne portée (horizontal) et augmente la défense de tous les alliés de 2 niveaux pendant 10 secondes.`,
        `Enlève 19% des HP des ennemis à moyenne portée (horizontal) et augmente la défense de tous les alliés de 3 niveaux pendant 11 secondes.`,
        `Enlève 19% des HP des ennemis à moyenne portée (horizontal) et augmente la défense de tous les alliés de 3 niveaux pendant 12 secondes.`,
        `Enlève 20% des HP des ennemis à moyenne portée (horizontal) et augmente la défense de tous les alliés de 3 niveaux pendant 13 secondes.`,
        `Enlève 21% des HP des ennemis à moyenne portée (horizontal) et augmente la défense de tous les alliés de 4 niveaux pendant 14 secondes.`,
      ],
      capacityDescription: [
        `Lorsqu'il reste 70 secondes ou moins, augmente la défense de tous les alliés de 1 niveau et augmente l'ATK du personnage de 3 niveaux.`,
        `Lorsqu'il reste 70 secondes ou moins, augmente la défense de tous les alliés de 2 niveaux et augmente l'ATK du personnage de 3 niveaux.`,
        `Lorsqu'il reste 70 secondes ou moins, augmente la défense de tous les alliés de 3 niveaux et augmente l'ATK du personnage de 3 niveaux.`,
        `Lorsqu'il reste 70 secondes ou moins, augmente la défense de tous les alliés de 4 niveaux et augmente l'ATK du personnage de 3 niveaux.`,
        `Lorsqu'il reste 70 secondes ou moins, augmente la défense de tous les alliés de 5 niveaux et augmente l'ATK du personnage de 3 niveaux.`,
      ],
      capacityAttributes: [
        {
          DEF: {
            value: 5,
            targets: ['ALL'],
            fromTime: 70,
            toTime: 0,
          },
          ATK: {
            value: 3,
            targets: ['SELF'],
            fromTime: 70,
            toTime: 0,
          },
        },
      ],
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: 'Lien des cercles',
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 2 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
        ],
      },
      {
        type: 'Réduction soin impossible',
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 4 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
        ],
      },
      {
        type: 'Ajout ATK si dégâts subis',
        icon: '',
        effects: [
          `Ajoute 75 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 200 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 300 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0103.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0101.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: false,
      levels: [],
      supportedCharacters: [],
    },
    batch: `6ème anniversaire de la version japonaise`,
  },
  {
    id: '2534',
    title: 'Membres de la génération des "miracles"',
    name: 'Luffy et Law',
    isDual: true,
    artwork: {
      fiveStars: 'https://i.imgur.com/0qeraCX.png',
      sixStars: 'https://i.imgur.com/yulO2bB.png',
      sixPlusStars: null,
    },
    dualCharacterData: {
      hasOnlyOneSpecial: false,
      hasDifferentCrewmateAbility1: true,
      hasDifferentCrewmateAbility2: false,
      special: null,
      specialTurns: {
        initial: 17,
        max: 12,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 0,
      },
      specialWithStages: [],
      switchEffect: `Libère le personnage de "Lien des cercles", change son cercle (cercle [BLK] inclus) en cercle correspondant, multiplie l'ATK du personnage par 2 pendant un tour et annule les effets défensifs dont bénéficient les ennemis contre les ATK normales du personnage pendant un tour.`,
      firstForm: {
        name: 'Monkey D. Luffy',
        story: `Capitaine de l'équipage de Chapeau de paille. Membre de la "génération terrible" dont fait aussi partie Law, il provoque Doflamingo en duel pour lui faire payer les larmes et la colère qu'il a suscitées chez ses camarades.`,
        type: CharacterTypeClass.DEX,
        classes: [CharacterClass.Cogneur, CharacterClass.Libre],
        combo: 6,
        hp: {
          initial: 3418,
          afterLimitBreak: 3898,
          afterLimitBreakPlus: 4248,
        },
        atk: {
          initial: 1518,
          afterLimitBreak: 1733,
          afterLimitBreakPlus: 1933,
        },
        rcv: {
          initial: 312,
          afterLimitBreak: 417,
          afterLimitBreakPlus: 492,
        },
        captainAbility: `Multiplie l'ATK de tous les personnages par 3,5 et les HP par 1,25.`,
        captainAbilityAfterLimitBreakPlus: null,
        captainAbilityAdditionnalInformation: null,
        ownSpecial:
          'Inflige 400 fois son ATK en dégâts non élémentaires sur un ennemi, rend les cercles [DEX] et [QCK] avantageux pour tous les personnages pendant un tour, multiplie les effets des cercles de tous les personnages par 2,5 pendant un tour et devient "Luffy et Law" pendant 3 tours.',
        crewmateAbilities: [
          {
            ability: `Ajoute 50 en ATK pour tous les personnages.`,
            needLimitBreak: false,
          },
        ],
        picture: {
          fiveStarsPicture: 'https://i.imgur.com/Q9cXCYu.png',
          sixStarsPicture: 'https://i.imgur.com/UCxboK0.png',
          sixPlusStarsPicture: '',
        },
      },
      secondForm: {
        name: 'Trafalgar Law',
        story: `Capitaine de l'équipage du "Heart". Membre de la "génération terrible" dont fait aussi partie Luffy, il s'allie à ce dernier pour combattre Doflamingo et lui faire payer ce qu'il a commis il y a 13 ans.`,
        type: CharacterTypeClass.QCK,
        classes: [CharacterClass.Sabreur, CharacterClass.Intellectuel],
        combo: 4,
        hp: {
          initial: 3288,
          afterLimitBreak: 3768,
          afterLimitBreakPlus: 4118,
        },
        atk: {
          initial: 1482,
          afterLimitBreak: 1697,
          afterLimitBreakPlus: 1897,
        },
        rcv: {
          initial: 381,
          afterLimitBreak: 486,
          afterLimitBreakPlus: 561,
        },
        captainAbility: `Multiplie l'ATK de tous les personnages par 3, les HP par 1,25 et restaure les HP avec 0,5 fois sa RCV à chaque "PARFAIT" à la fin du tour.`,
        captainAbilityAfterLimitBreakPlus: null,
        captainAbilityAdditionnalInformation: null,
        ownSpecial: `Inflige 75 fois son ATK en dégâts non élémentaires sur tous les ennemis en ignorant les effets défensifs, rend les cercles [DEX] et [QCK] avantageux pour tous les personnages pendant un tour, multiplie les effets des cercles de tous les personnages par 2,5 pendant un tour et devient "Luffy et Law" pendant 3 tours.
        `,
        crewmateAbilities: [
          {
            ability: `Ajoute 50 en HP pour tous les personnages.`,
            needLimitBreak: false,
          },
        ],
        picture: {
          fiveStarsPicture: 'https://i.imgur.com/O9vYfIg.png',
          sixStarsPicture: 'https://i.imgur.com/gQjtivT.png',
          sixPlusStarsPicture: '',
        },
      },
      finalForm: {
        name: 'Luffy et Law',
        story: `Capitaines de l'équipage de Chapeau de paille et de l'équipage du "Heart". Au terme d'une série de périls auxquels ils ont miraculeusement survécu, ces deux membres de la "génération terrible" engagent le combat contre Doflamingo, lequel est considéré comme un descendant direct des "dieux".`,
        type: 'DEX/QCK',
        classes: [CharacterClass.Cogneur, CharacterClass.Sabreur],
        combo: 4,
        hp: {
          initial: 3677,
          afterLimitBreak: 4157,
          afterLimitBreakPlus: 4507,
        },
        atk: {
          initial: 1644,
          afterLimitBreak: 1859,
          afterLimitBreakPlus: 2059,
        },
        rcv: {
          initial: 425,
          afterLimitBreak: 530,
          afterLimitBreakPlus: 605,
        },
        captainAbility: `Multiplie l'ATK de tous les personnages par 4,5, les HP par 1,25 et restaure les HP avec 1 fois sa RCV à chaque "PARFAIT" à la fin du tour.`,
        captainAbilityAdditionnalInformation: null,
        captainAbilityAfterLimitBreakPlus: null,
        ownSpecial: null,
        crewmateAbilities: [
          {
            ability: `Ajoute 50 en HP, ATK et RCV pour tous les personnages.`,
            needLimitBreak: false,
          },
          {
            ability: `Immunise le personnage contre "Lien des coups spéciaux" et "Paralysie".`,
            needLimitBreak: true,
          },
        ],
        picture: {
          fiveStarsPicture:
            'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f2533.png',
          sixStarsPicture:
            'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f2534.png',
          sixPlusStarsPicture: '',
        },
      },
    },
    singleCharacterData: null,
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: 'Restauration HP',
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 0,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 10%.`,
          `Restaure les HP à hauteur de 0,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 15%.`,
          `Restaure les HP à hauteur de 0,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 20%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 25%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 30%.`,
        ],
      },
      {
        type: 'Réduction soin impossible',
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 4 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
        ],
      },
      {
        type: 'ATK Critique',
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 60% de chances de faire 6% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 80% de chances de faire 8% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 90% de chances de faire 9% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 100% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0102.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0101.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: false,
      levels: [],
      supportedCharacters: [],
    },
    batch: `5ème anniversaire de la version japonaise`,
  },
];
