import { Legend } from '../../models/legend.interface';
import {
  CharacterClass,
  CharacterTypeClass,
} from '../../models/character-class.interface';
import { PotentialAbility } from '../../models/potential-ability';
import { PvpStyle } from 'src/app/models/pvp.interface';

export const singleLegendsQck: Legend[] = [
  // Soba Mask
  {
    id: '2804',
    title: null,
    name: 'O-Soba Mask',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/eXxHck5.png',
      sixStars: 'https://i.imgur.com/wyK6tw4.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Cuisinier de l'équipage de Chapeau de paille. Grâce à sa Raid Suit, un bijou de technologie Germa permettant de devenir invisible en projetant sur son corps tout son environnement, il remporte sur Page One une victoire écrasante.`,
      type: CharacterTypeClass.QCK,
      classes: [CharacterClass.Tenace, CharacterClass.Libre],
      combo: 5,
      hp: {
        initial: 3622,
        afterLimitBreak: 3972,
        afterLimitBreakPlus: 4272,
      },
      atk: {
        initial: 1597,
        afterLimitBreak: 1797,
        afterLimitBreakPlus: 1997,
      },
      rcv: {
        initial: 264,
        afterLimitBreak: 344,
        afterLimitBreakPlus: 384,
      },
      captainAbility: `Réduit le temps de chargement des coups spéciaux de tous les personnages d'un tour au début de la quête, multiplie l'ATK de tous les personnages par 4,25 s'ils ont un cercle [TND], sinon par 3,5, rend les cercles [TND] avantageux pour tous les personnages et augmente les chances d'apparition des cercles [TND].`,
      captainAbilityAfterLimitBreakPlus: `Réduit le temps de chargement des coups spéciaux de tous les personnages d'un tour au début de la quête, multiplie l'ATK de tous les personnages par 4,25 s'ils ont un cercle [TND], sinon par 3,5, rend les cercles [TND] avantageux pour tous les personnages et augmente grandement les chances d'apparition des cercles [TND].
      `,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: `Le personnage devient transparent pendant 3 tours, l'immunisant contre les effets négatifs. Annule les effets défensifs dont bénéficient les ennemis contre les ATK normales du personnage et ajoute 500 fois son ATK en dégâts additionnels non élémentaires pour le personnage.`,
      captainActionTurns: 6,
      special: `Change les cercles de tous les personnages (cercles [BLK] inclus) en cercles [TND], multiplie l’ATK de tous les personnages par 1,75 pendant un tour et si le coup spécial "Assaut - O-Soba Mask" est utilisé une nouvelle fois lors du même tour, multiplie l’ATK de tous les personnages par 2,5 pendant un tour.`,
      specialTurns: {
        initial: 16,
        max: 11,
        gainWithLimitBreak: 0,
        gainWithLimitBreakPlus: 0,
      },
      specialWithStages: null,
      crewmateAbilities: [
        {
          ability: `Si un autre personnage utilise son coup spécial, réduit le temps de chargement du coup spécial du personnage de 4 tours.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [TND] avantageux pour tous les personnages.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: 'https://i.imgur.com/pZfA2vh.png',
        sixStarsPicture: 'https://i.imgur.com/mdZnRbI.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_URGENCE,
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 20%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 25%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 30%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 35%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
          `Réduit la durée de “Soin impossible” de 10 tours.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0101.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0301.png',
      'https://i.imgur.com/83DcaEZ.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Usopp', 'Franky'],
      levels: [
        `Multiplie l'ATK de tous les personnages par 1,1 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK de tous les personnages par 1,1 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK de tous les personnages par 1,2 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK de tous les personnages par 1,2 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Multiplie l'ATK de tous les personnages par 1,3 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `Wano`,
  },
  // Hancock V1
  {
    id: '0416',
    title: 'Sept grands corsaires',
    name: 'Boa Hancock',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/kP7Cbdd.png',
      sixStars: 'https://i.imgur.com/V8HEWr6.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage des pirates Kuja, elle est l'une des sept grands corsaires. Elle dispose des pouvoirs du Fruit de la Passion et peut transformer celui qui l'admire en pierre. L'aînée des gorgones. Enfant, elle fut l'esclave d'un noble.`,
      type: CharacterTypeClass.QCK,
      classes: [CharacterClass.Cogneur, CharacterClass.Libre],
      combo: 4,
      hp: {
        initial: 2860,
        afterLimitBreak: 3380,
        afterLimitBreakPlus: 3580,
      },
      atk: {
        initial: 1320,
        afterLimitBreak: 1530,
        afterLimitBreakPlus: 1680,
      },
      rcv: {
        initial: 515,
        afterLimitBreak: 585,
        afterLimitBreakPlus: 705,
      },
      captainAbility: `Multiplie l'ATK des personnages de type QCK et PSY par 2,75 et la RCV par 1,5 si les HP sont au-dessus de 70%.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Restaure les HP avec 15 fois sa RCV et retarde toutes les attaques ennemies d'un tour.`,
      specialTurns: {
        initial: 17,
        max: 13,
        gainWithLimitBreak: 0,
        gainWithLimitBreakPlus: 4,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 30 en HP, ATK et RCV pour les personnages de type QCK et PSY.`,
          needLimitBreak: true,
        },
        {
          ability: `Ajoute 75 en ATK pour les personnages de type QCK et PSY.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0415.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0416.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.STR_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type STR de 1%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 2%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 3%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 4%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 5%.`,
        ],
      },
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 7%.`,
        ],
      },
      {
        type: PotentialAbility.REDUCTION_SPECIAL,
        icon: '',
        effects: [
          `Réduit le temps de chargement de son coup spécial de 1 tour au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 2 tours au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 3 tours au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 4 tours au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 5 tours au début de la quête.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0094.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0096.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0095.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: [
        'Monkey D. Luffy',
        'Boa Sandersonia',
        'Boa Marigold',
      ],
      levels: [
        `?`,
        `?`,
        `?`,
        `?`,
        `Restaure 2 500 HP et change les cercles des personnages de la colonne de gauche si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Hancock V1 6+
  {
    id: '1445',
    title: 'Princesse Serpent',
    name: 'Boa Hancock',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/7TiM1tY.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage des Kuja et membre de l'ordre des sept grands corsaires. Sur la demande de Luffy, qu'elle a rencontré peu après l'incident des dragons célestes et dont elle est tombée amoureuse, elle décide de participer à la guerre au sommet afin de berner le Gouvernement mondial.`,
      type: CharacterTypeClass.QCK,
      classes: [CharacterClass.Libre, CharacterClass.Tireur],
      combo: 4,
      hp: {
        initial: 3002,
        afterLimitBreak: 3502,
        afterLimitBreakPlus: 3702,
      },
      atk: {
        initial: 1480,
        afterLimitBreak: 1690,
        afterLimitBreakPlus: 1840,
      },
      rcv: {
        initial: 507,
        afterLimitBreak: 577,
        afterLimitBreakPlus: 692,
      },
      captainAbility: `Multiplie l'ATK des personnages de type QCK et PSY selon les HP de l'équipe au début du tour et si les HP sont en-dessous de 50%, multiplie la RCV des personnages de type QCK et PSY par 1,5.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: `Par 2,75 si les HP de l'équipe sont au maximum et par 2,25 s'il ne reste qu'un HP à l'équipe.`,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Multiplie l'ATK et la RCV des personnages de type QCK et PSY par 1,75 pendant un tour, retarde toutes les attaques ennemies d'un tour, change le cercle du capitaine en cercle [RCV] et si les HP sont au-dessus de 50%, change les cercles de la colonne de gauche en cercles correspondants.`,
      specialTurns: {
        initial: 17,
        max: 13,
        gainWithLimitBreak: 0,
        gainWithLimitBreakPlus: 4,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 30 en HP, ATK et RCV pour les personnages de type QCK et PSY.`,
          needLimitBreak: true,
        },
        {
          ability: `Ajoute 75 en ATK pour les personnages de type QCK et PSY.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture: 'https://i.imgur.com/NGPiGd5.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.STR_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type STR de 1%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 2%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 3%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 4%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 5%.`,
        ],
      },
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 7%.`,
        ],
      },
      {
        type: PotentialAbility.REDUCTION_SPECIAL,
        icon: '',
        effects: [
          `Réduit le temps de chargement de son coup spécial de 1 tour au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 2 tours au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 3 tours au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 4 tours au début de la quête.`,
          `Réduit le temps de chargement de son coup spécial de 5 tours au début de la quête.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0094.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0096.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0095.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: [
        'Monkey D. Luffy',
        'Boa Sandersonia',
        'Boa Marigold',
      ],
      levels: [
        `?`,
        `?`,
        `?`,
        `?`,
        `Restaure 2 500 HP et change les cercles des personnages de la colonne de gauche si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Marco
  {
    id: '0649',
    title: `Commandant de la 1re flotte de l'équipage de Barbe Blanche`,
    name: 'Marco le Phénix',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/6XEZlfu.png',
      sixStars: 'https://i.imgur.com/lh21HvE.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Commandant de la 1re flotte de l'équipage de Barbe Blanche. Il aide son capitaine tout en veillant sur le reste de l'équipage. L'un des vieux de la vieille de la bande de Barbe Blanche (avec Joz et Vista), il a aussi combattu l'Amiral de la Marine Kizaru lors de la Bataille de Marine Ford.`,
      type: CharacterTypeClass.QCK,
      classes: [CharacterClass.Cogneur, CharacterClass.Tenace],
      combo: 4,
      hp: {
        initial: 3013,
        afterLimitBreak: 3323,
        afterLimitBreakPlus: 3523,
      },
      atk: {
        initial: 1402,
        afterLimitBreak: 1702,
        afterLimitBreakPlus: 1827,
      },
      rcv: {
        initial: 455,
        afterLimitBreak: 565,
        afterLimitBreakPlus: 690,
      },
      captainAbility: `Réduit les dégâts subis de 30% si les HP sont au-dessus de 70% et multiplie l'ATK des personnages de type QCK et PSY par 2,75 si les HP sont au-dessus de 70%.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Restaure les HP avec 100 fois sa RCV et multiplie les effets des cercles de tous les personnages par 1,5 pendant 2 tours.`,
      specialTurns: {
        initial: 25,
        max: 18,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 2,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour les personnages de type QCK et PSY.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [QCK], [PSY] et [TND] avantageux pour les personnages de type QCK et PSY.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0648.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0649.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.STR_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type STR de 1%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 2%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 3%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 5%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 7%.`,
        ],
      },
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 7%.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
          `Réduit la durée de “Soin impossible” de 10 tours.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0085.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0301.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Barbe Blanche', 'Portgas D. Ace'],
      levels: [
        `Restaure 500 HP si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Restaure 1 000 HP si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Restaure 1 500 HP si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Restaure 2 500 HP si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Restaure 4 000 HP si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Marco 6+
  {
    id: '2868',
    title: `Créature de flammes bleues`,
    name: 'Marco le Phénix',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/697iwrb.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Commandant de la 1re flotte de Barbe Blanche. Grâce aux pouvoirs d'un fruit du démon de type "zoan mythique" dont la rareté est réputée supérieure à celle d'un logia, il peut combattre sous la forme d'un phénix entouré de flammes bleues et guérir de ses blessures.`,
      type: CharacterTypeClass.QCK,
      classes: [CharacterClass.Cogneur, CharacterClass.Tenace],
      combo: 4,
      hp: {
        initial: 3130,
        afterLimitBreak: 3440,
        afterLimitBreakPlus: 3660,
      },
      atk: {
        initial: 1424,
        afterLimitBreak: 1724,
        afterLimitBreakPlus: 1849,
      },
      rcv: {
        initial: 500,
        afterLimitBreak: 610,
        afterLimitBreakPlus: 735,
      },
      captainAbility: `Multiplie l'ATK des personnages de type QCK et PSY par 4 si les HP sont au maximum, sinon par 3,5, et si les HP sont au-dessus de 70%, réduit les dégâts subis de 30%.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Restaure les HP avec 100 fois sa RCV et multiplie les effets des cercles de tous les personnages par 2 pendant un tour.`,
      specialTurns: {
        initial: 25,
        max: 18,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 2,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 50 en HP, ATK et RCV pour les personnages de type QCK et PSY.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [QCK], [PSY] et [TND] avantageux pour les personnages de type QCK et PSY.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture: 'https://i.imgur.com/RO2WGGj.png',
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.STR_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type STR de 1%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 2%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 3%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 5%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 7%.`,
        ],
      },
      {
        type: PotentialAbility.INT_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type INT de 1%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 2%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 3%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 5%.`,
          `Réduit les dégâts subis par les ennemis de type INT de 7%.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
          `Réduit la durée de “Soin impossible” de 7 tours.`,
          `Réduit la durée de “Soin impossible” de 10 tours.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/blue_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0096.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0301.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0095.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Barbe Blanche', 'Portgas D. Ace'],
      levels: [
        `Restaure 500 HP si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Restaure 1 000 HP si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Restaure 1 500 HP si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Restaure 2 500 HP si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Restaure 4 000 HP si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Doflamingo V1
  {
    id: '0870',
    title: null,
    name: 'Doflamingo, le démon céleste',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/JKVD7w1.png',
      sixStars: 'https://i.imgur.com/i01Tyed.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage de Don Quijote et l'un des sept grands corsaires. Les pouvoirs de son fruit du fil lui permettent de se déplacer comme s'il volait et de prendre le contrôle d'autres personnes.`,
      type: CharacterTypeClass.QCK,
      classes: [CharacterClass.Ambitieux, CharacterClass.Intellectuel],
      combo: 4,
      hp: {
        initial: 2800,
        afterLimitBreak: 3250,
        afterLimitBreakPlus: 3550,
      },
      atk: {
        initial: 1530,
        afterLimitBreak: 1780,
        afterLimitBreakPlus: 1930,
      },
      rcv: {
        initial: 405,
        afterLimitBreak: 480,
        afterLimitBreakPlus: 580,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Ambitieux par 3 jusqu'au premier coup autre que "PARFAIT".`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Change les cercles adjacents en cercles correspondants et multiplie les effets des cercles de tous les personnages par 2 pendant un tour.`,
      specialTurns: {
        initial: 20,
        max: 15,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 1,
      },
      crewmateAbilities: [
        {
          ability: `Si le personnage a un cercle [QCK] et qu'il fait un coup "PARFAIT", garde son cercle [QCK] pour le tour suivant.`,
          needLimitBreak: true,
        },
        {
          ability: `Rend les cercles [QCK] avantageux pour les personnages de classe Ambitieux.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0869.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0870.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_URGENCE,
        icon: '',
        effects: [
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 10%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 15%.`,
          `Restaure les HP à hauteur de 1 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 20%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 30%.`,
          `Restaure les HP à hauteur de 1,5 fois les RCV du personnage si ce dernier réussit un “PARFAIT” lorsque les HP restants sont inférieurs ou égaux à 50%.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Annule le “Lien des cercles” du personnage.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0085.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0301.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de classe Ambitieux'],
      levels: [
        `Ajoute 5% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 6% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 8% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 10% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
        `Ajoute 12% des HP, de l'ATK et de la RCV du personnage en HP, ATK et RCV pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Bartolomeo 6+
  {
    id: '1928',
    title: `Armada de Chapeau de paille "Coupe du serment d'allégeance"`,
    name: 'Bartolomeo le cannibale',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/Y64t7Cd.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage du Barto Club. Il voue une admiration sans limites à l'équipage de Chapeau de paille. Il fait un discours émouvant après avoir appris pourquoi Luffy désire devenir le roi des pirates, puis échange avec ses camarades d'armes la "Coupe du serment d'allégeance", concrétisant ainsi son rêve de devenir un serviteur de Luffy.`,
      type: CharacterTypeClass.QCK,
      classes: [CharacterClass.Ambitieux, CharacterClass.Ravageur],
      combo: 4,
      hp: {
        initial: 3602,
        afterLimitBreak: 4072,
        afterLimitBreakPlus: 0,
      },
      atk: {
        initial: 1510,
        afterLimitBreak: 1730,
        afterLimitBreakPlus: 0,
      },
      rcv: {
        initial: 380,
        afterLimitBreak: 465,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie l'ATK des personnages de classe Ravageur et Ambitieux par 3 et si le personnage n'attaque pas durant le tour, réduit les dégâts subis de 30%.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Empêche la défaite pendant un tour et multiplie l'ATK et les effets des cercles des personnages de classe Ravageur et Ambitieux par 1,5 pendant un tour.`,
      specialTurns: {
        initial: 22,
        max: 17,
        gainWithLimitBreak: 4,
        gainWithLimitBreakPlus: 0,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 60 en HP et ATK pour les personnages de classe Ravageur.`,
          needLimitBreak: true,
        },
        {
          ability: `Immunise le personnage contre "Paralysie".`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1928.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.DEX_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type DEX de 2%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 3%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 4%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 5%.`,
          `Réduit les dégâts subis par les ennemis de type DEX de 7%.`,
        ],
      },
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 30 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 90 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 120 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.SOIN_IMPOSSIBLE,
        icon: '',
        effects: [
          `Réduit la durée de “Soin impossible” de 1 tour.`,
          `Réduit la durée de “Soin impossible” de 2 tours.`,
          `Réduit la durée de “Soin impossible” de 3 tours.`,
          `Réduit la durée de “Soin impossible” de 4 tours.`,
          `Réduit la durée de “Soin impossible” de 5 tours.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/blue_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0085.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0301.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Nico Robin', 'Cavendish'],
      levels: [
        `Réduit la durée de "Paralysie" de 1 tour si un personnage a été touché par la "Paralysie" (une fois par quête).`,
        `Réduit la durée de "Paralysie" de 1 tour si un personnage a été touché par la "Paralysie" (une fois par quête).`,
        `Réduit la durée de "Paralysie" de 1 tour si un personnage a été touché par la "Paralysie" (une fois par quête).`,
        `Réduit la durée de "Paralysie" de 2 tours si un personnage a été touché par la "Paralysie" (une fois par quête).`,
        `Réduit la durée de "Paralysie" de 3 tours si un personnage a été touché par la "Paralysie" (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Cavendish
  {
    id: '1123',
    title: null,
    name: 'Cavendish au cheval blanc',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/mYIpq6s.png',
      sixStars: 'https://i.imgur.com/EPQXZsQ.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage des jolis pirates. Alors que les journaux ne parlaient que de lui autrefois, ce pirate dont la prime dépasse les 100 millions de berrys a perdu de sa popularité lorsque Luffy et les autres supernovæ ont fait leur apparition. Outre le fait qu'il voue à ces derniers une haine tenace, il change totalement de personnalité lorsqu'il est endormi.`,
      type: CharacterTypeClass.QCK,
      classes: [CharacterClass.Sabreur, CharacterClass.Ambitieux],
      combo: 4,
      hp: {
        initial: 2970,
        afterLimitBreak: 3450,
        afterLimitBreakPlus: 0,
      },
      atk: {
        initial: 1460,
        afterLimitBreak: 1680,
        afterLimitBreakPlus: 0,
      },
      rcv: {
        initial: 438,
        afterLimitBreak: 488,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie l'ATK des personnages de type STR, DEX et QCK par 2,5 et multiplie l'ATK du personnage par 4.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Enlève tous les effets positifs à l'équipe, inflige 15 fois son ATK en dégâts non élémentaires sur tous les ennemis, multiplie l'ATK du personnage par 2,25 pendant 2 tours, multiplie l'effet du cercle du personnage par 1,5 pendant 2 tours, bloque son cercle pendant 2 tours et change son cercle en cercle [QCK].`,
      specialTurns: {
        initial: 20,
        max: 15,
        gainWithLimitBreak: 3,
        gainWithLimitBreakPlus: 0,
      },
      crewmateAbilities: [
        {
          ability: `Si le personnage a un cercle [QCK] et qu'il fait un coup "PARFAIT", garde son cercle [QCK] pour le tour suivant.`,
          needLimitBreak: true,
        },
        {
          ability: `Si le personnage est le dernier à attaquer, ajoute 200 en ATK pour le personnage.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1122.png',
        sixStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1123.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 30 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 90 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 4% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 6% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 80% de chances de faire 8% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 90% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0085.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0301.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de type QCK'],
      levels: [
        `Change le cercle du personnage soutenu en cercle [QCK] et multiplie l'ATK et l'effet du cercle du personnage soutenu par 1,1 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [QCK] et multiplie l'ATK et l'effet du cercle du personnage soutenu par 1,2 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [QCK] et multiplie l'ATK et l'effet du cercle du personnage soutenu par 1,3 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [QCK] et multiplie l'ATK et l'effet du cercle du personnage soutenu par 1,4 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [QCK] et multiplie l'ATK et l'effet du cercle du personnage soutenu par 1,5 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Cavendish 6+
  {
    id: '2357',
    title: 'La rafale de Rommel',
    name: 'Hakuba',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/QRReGCs.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Capitaine de l'équipage des jolis pirates et autre personnalité de Cavendish. Surnommé "la rafale de Rommel", cet alter ego a la particularité d'attaquer indistinctement tous ceux qui l'entourent, comme lorsqu'il tente de trancher Robin avant d'en être empêché par le véritable Cavendish.`,
      type: CharacterTypeClass.QCK,
      classes: [CharacterClass.Sabreur, CharacterClass.Ambitieux],
      combo: 6,
      hp: {
        initial: 3100,
        afterLimitBreak: 3580,
        afterLimitBreakPlus: 0,
      },
      atk: {
        initial: 1608,
        afterLimitBreak: 1828,
        afterLimitBreakPlus: 0,
      },
      rcv: {
        initial: 461,
        afterLimitBreak: 511,
        afterLimitBreakPlus: 0,
      },
      captainAbility: `Multiplie l'ATK des personnages de type STR, DEX et QCK par 3 et multiplie l'ATK du personnage par 4.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Enlève tous les effets positifs à l'équipe, inflige 60 fois son ATK en dégâts non élémentaires sur tous les ennemis, multiplie l'ATK du personnage par 2,25 pendant 2 tours, multiplie l'effet du cercle du personnage par 2 pendant 2 tours, bloque son cercle pendant 2 tours et change son cercle (cercle [BLK] inclus) en cercle [QCK].`,
      specialTurns: {
        initial: 19,
        max: 14,
        gainWithLimitBreak: 3,
        gainWithLimitBreakPlus: 0,
      },
      crewmateAbilities: [
        {
          ability: `Si le personnage a un cercle [QCK] et qu'il fait un coup "PARFAIT", garde son cercle [QCK] pour le tour suivant.`,
          needLimitBreak: true,
        },
        {
          ability: `Si le personnage est le dernier à attaquer, ajoute 200 en ATK pour le personnage.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture: 'https://i.imgur.com/LM4nG1B.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: false,
    potentialAbilities: [
      {
        type: PotentialAbility.AUGMENTATION_ATK,
        icon: '',
        effects: [
          `Ajoute 30 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 60 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 90 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 100 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
          `Ajoute 150 points d'ATK au tour suivant lorsque des dégâts sont subis.`,
        ],
      },
      {
        type: PotentialAbility.LIEN_CERCLES,
        icon: '',
        effects: [
          `Réduit la durée de “Lien des cercles” du personnage de 1 tour.`,
          `Réduit la durée de “Lien des cercles” du personnage de 3 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 5 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 7 tours.`,
          `Réduit la durée de “Lien des cercles” du personnage de 10 tours.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 4% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 6% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 80% de chances de faire 8% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 90% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/Cavendish_skull_f.png',
      'https://onepiece-treasurecruise.com/wp-content/uploads/Cavendish_skull_f.png',
      'https://onepiece-treasurecruise.com/wp-content/uploads/Cavendish_skull_f.png',
      'https://onepiece-treasurecruise.com/wp-content/uploads/Cavendish_skull_f.png',
      'https://onepiece-treasurecruise.com/wp-content/uploads/Cavendish_skull_f.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Personnages de type QCK'],
      levels: [
        `Change le cercle du personnage soutenu en cercle [QCK] et multiplie l'ATK et l'effet du cercle du personnage soutenu par 1,1 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [QCK] et multiplie l'ATK et l'effet du cercle du personnage soutenu par 1,2 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [QCK] et multiplie l'ATK et l'effet du cercle du personnage soutenu par 1,3 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [QCK] et multiplie l'ATK et l'effet du cercle du personnage soutenu par 1,4 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
        `Change le cercle du personnage soutenu en cercle [QCK] et multiplie l'ATK et l'effet du cercle du personnage soutenu par 1,5 pendant un tour si le personnage soutenu utilise son coup spécial (une fois par quête).`,
      ],
    },
    batch: `First Legend`,
  },
  // Aokiji V1
  {
    id: '1192',
    title: null,
    name: 'Kuzan',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/VVpQwGp.png',
      sixStars: 'https://i.imgur.com/cn9TE3E.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Amiral au quartier général de la Marine. D'une nature à la fois calme et désinvolte, il n'agit que selon ses propres convictions, auxquelles il accorde parfois plus d'importance qu'à son rôle d'amiral.`,
      type: CharacterTypeClass.QCK,
      classes: [CharacterClass.Ravageur, CharacterClass.Tireur],
      combo: 4,
      hp: {
        initial: 3300,
        afterLimitBreak: 3650,
        afterLimitBreakPlus: 4000,
      },
      atk: {
        initial: 1466,
        afterLimitBreak: 1716,
        afterLimitBreakPlus: 1916,
      },
      rcv: {
        initial: 392,
        afterLimitBreak: 492,
        afterLimitBreakPlus: 567,
      },
      captainAbility: `Multiplie l'ATK de tous les personnages par 2 et les HP par 1,2. Si un ennemi a été vaincu au tour précédent, multiplie légèrement l'ATK (3,5 au maximum pour un ennemi vaincu par tour sur 8 tours d'affilée).`,
      captainAbilityAfterLimitBreakPlus: `Multiplie l'ATK de tous les personnages par 2,5 et les HP par 1,2. Si un ennemi a été vaincu au tour précédent, multiplie légèrement l'ATK (3,5 au maximum pour un ennemi vaincu par tour sur 8 tours d'affilée).`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Ajoute un tour aux augmentations d'ATK et aux augmentations d'effets des cercles.`,
      specialTurns: {
        initial: 19,
        max: 13,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 1,
      },
      crewmateAbilities: [
        {
          ability: `Rend les cercles [QCK] avantageux pour les personnages de classe Ravageur et Tireur.`,
          needLimitBreak: true,
        },
        {
          ability: `Si le personnage a un cercle [QCK] et qu'il fait un coup "PARFAIT", garde son cercle [QCK] pour le tour suivant.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: 'https://i.imgur.com/qlwveMk.png',
        sixStarsPicture: 'https://i.imgur.com/egc92TX.png',
        sixPlusStarsPicture: null,
      },
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.STR_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type STR de 1%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 2%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 3%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 5%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 7%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 10% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.BARRIERE,
        icon: '',
        effects: [
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 90%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 70%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 50%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0301.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0101.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Smoker', 'Monkey D. Garp', 'Sengoku'],
      levels: [
        `Réduit les dégâts subis par les ennemis de type STR de 1% et ajoute 3% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type STR de 2% et ajoute 5% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type STR de 3% et ajoute 7% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type STR de 4% et ajoute 10% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type STR de 5% et ajoute 13% de l'ATK du personnage en ATK pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Aokiji V1 6+
  {
    id: '1764',
    title: `Ancien membre de la plus puissante des forces de combat de la Marine`,
    name: 'Kuzan',
    isDual: false,
    artwork: {
      fiveStars: null,
      sixStars: null,
      sixPlusStars: 'https://i.imgur.com/xhpO7Fv.png',
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `Ancien amiral au quartier général de la Marine. Bien que Smoker le soupçonne d'être en lien avec le "monde de l'ombre", on ignore tout de ses appartenances et de ses activités depuis son départ de la Marine, qu'il a quittée suite à sa défaite contre Akainu au lendemain de la guerre au sommet.`,
      type: CharacterTypeClass.QCK,
      classes: [CharacterClass.Ravageur, CharacterClass.Tireur],
      combo: 4,
      hp: {
        initial: 3478,
        afterLimitBreak: 3828,
        afterLimitBreakPlus: 4178,
      },
      atk: {
        initial: 1501,
        afterLimitBreak: 1751,
        afterLimitBreakPlus: 1951,
      },
      rcv: {
        initial: 420,
        afterLimitBreak: 520,
        afterLimitBreakPlus: 595,
      },
      captainAbility: `Multiplie l'ATK de tous les personnages par 2,25 et les HP par 1,25. Si un ennemi a été vaincu au tour précédent, multiplie légèrement l'ATK (3,5 au maximum pour un ennemi vaincu par tour sur 5 tours d'affilée).`,
      captainAbilityAfterLimitBreakPlus: `Multiplie l'ATK de tous les personnages par 3 et les HP par 1,25. Si un ennemi a été vaincu au tour précédent, multiplie légèrement l'ATK (4 au maximum pour un ennemi vaincu par tour sur 5 tours d'affilée).`,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Ajoute un tour aux augmentations d'ATK et aux augmentations d'effets des cercles, bloque le multiplicateur de combos à 2,5 pendant un tour et inflige 100 000 points de dégâts fixes sur tous les ennemis en ignorant les effets défensifs.`,
      specialTurns: {
        initial: 19,
        max: 13,
        gainWithLimitBreak: 2,
        gainWithLimitBreakPlus: 1,
      },
      crewmateAbilities: [
        {
          ability: `Rend les cercles [QCK] avantageux pour les personnages de classe Ravageur et Tireur.`,
          needLimitBreak: true,
        },
        {
          ability: `Si le personnage a un cercle [QCK] et qu'il fait un coup "PARFAIT", garde son cercle [QCK] pour le tour suivant.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: null,
        sixStarsPicture: null,
        sixPlusStarsPicture:
          'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f1764.png',
      },
    },
    sockets: 5,
    cost: 65,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.STR_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type STR de 1%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 2%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 3%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 5%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 7%.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 10% de chances de faire 3% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 20% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 30% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 7% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
      {
        type: PotentialAbility.BARRIERE,
        icon: '',
        effects: [
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 90%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 70%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 50%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/wp-content/uploads/blue_skull_f.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0085.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0301.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Smoker', 'Monkey D. Garp', 'Sengoku'],
      levels: [
        `Réduit les dégâts subis par les ennemis de type STR de 1% et ajoute 3% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type STR de 2% et ajoute 5% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type STR de 3% et ajoute 7% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type STR de 4% et ajoute 10% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Réduit les dégâts subis par les ennemis de type STR de 5% et ajoute 13% de l'ATK du personnage en ATK pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
  // Carrot
  {
    id: '2338',
    title: 'Sulong mystérieuse',
    name: 'Carrot',
    isDual: false,
    artwork: {
      fiveStars: 'https://i.imgur.com/VVpQwGp.png',
      sixStars: 'https://i.imgur.com/cn9TE3E.png',
      sixPlusStars: null,
    },
    dualCharacterData: null,
    singleCharacterData: {
      story: `"Oiseau royal" de la principauté de Mokomo. Pour permettre à l'équipage de Chapeau de paille de semer l'ennemi, elle se transforme en sulong et détruit plusieurs gouvernails de la flotte commandée par Daifuku.`,
      type: CharacterTypeClass.QCK,
      classes: [CharacterClass.Cogneur, CharacterClass.Sabreur],
      combo: 4,
      hp: {
        initial: 3650,
        afterLimitBreak: 4120,
        afterLimitBreakPlus: 4470,
      },
      atk: {
        initial: 1555,
        afterLimitBreak: 1805,
        afterLimitBreakPlus: 2005,
      },
      rcv: {
        initial: 280,
        afterLimitBreak: 380,
        afterLimitBreakPlus: 455,
      },
      captainAbility: `Réduit le temps de chargement des coups spéciaux de tous les personnages de 2 tours au début de la quête. Pendant 12 tours, multiplie l'ATK des personnages de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel par 2 (par 4 pour les personnages qui ont 2 classes), augmente les chances d’apparition des cercles correspondants des personnages de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel et permet aux attaques normales d'ignorer les effets défensifs dont bénéficient les ennemis et l'équipe. Après 12 tours, multiplie l’ATK des personnages de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel par 2,75.`,
      captainAbilityAfterLimitBreakPlus: null,
      captainAbilityAdditionnalInformation: null,
      captainAbilityAfterLimitBreakPlusAdditionnalInformation: null,
      captainAction: null,
      captainActionTurns: null,
      specialWithStages: null,
      special: `Multiple les effets des cercles des personnages de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel par 2 pendant un tour, réduit le temps de chargement des coups spéciaux des personnages de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 2 tours et rend les cercles [QCK], [RCV] et [TND] avantageux pour tous les personnages pendant un tour. Si le personnage n'est pas capitaine, échange sa place avec le capitaine pendant un tour.`,
      specialTurns: {
        initial: 18,
        max: 13,
        gainWithLimitBreak: 1,
        gainWithLimitBreakPlus: 2,
      },
      crewmateAbilities: [
        {
          ability: `Ajoute 30 en HP, ATK et RCV pour tous les personnages.`,
          needLimitBreak: true,
        },
        {
          ability: `Immunise le personnage contre l'inversion de son coup spécial.`,
          needLimitBreak: true,
        },
      ],
      picture: {
        fiveStarsPicture: 'https://i.imgur.com/qlwveMk.png',
        sixStarsPicture: 'https://i.imgur.com/egc92TX.png',
        sixPlusStarsPicture: null,
      },
    },
    pvpData: {
      cost: 55,
      style: PvpStyle.ATK,
      ct: 39,
      specialDescription: [
        `Inflige 1 700 points de dégâts aux ennemis à longue portée (frontal) et réduit le chargement des coups spéciaux des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 16%.`,
        `Inflige 1 775 points de dégâts aux ennemis à longue portée (frontal) et réduit le chargement des coups spéciaux des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 16%.`,
        `Inflige 1 850 points de dégâts aux ennemis à longue portée (frontal) et réduit le chargement des coups spéciaux des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 16%.`,
        `Inflige 1 925 points de dégâts aux ennemis à longue portée (frontal) et réduit le chargement des coups spéciaux des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 17%.`,
        `Inflige 2 000 points de dégâts aux ennemis à longue portée (frontal) et réduit le chargement des coups spéciaux des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 17%.`,
        `Inflige 2 075 points de dégâts aux ennemis à longue portée (frontal) et réduit le chargement des coups spéciaux des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 18%.`,
        `Inflige 2 150 points de dégâts aux ennemis à longue portée (frontal) et réduit le chargement des coups spéciaux des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 18%.`,
        `Inflige 2 225 points de dégâts aux ennemis à longue portée (frontal) et réduit le chargement des coups spéciaux des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 18%.`,
        `Inflige 2 300 points de dégâts aux ennemis à longue portée (frontal) et réduit le chargement des coups spéciaux des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 19%.`,
        `Inflige 2 450 points de dégâts aux ennemis à longue portée (frontal) et réduit le chargement des coups spéciaux des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 20%.`,
      ],
      capacityDescription: [
        `Augmente la vitesse des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 1 niveau et augmente l'ATK du personnage de 5 niveaux.`,
        `Augmente la vitesse des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 2 niveaux et augmente l'ATK du personnage de 5 niveaux.`,
        `Augmente la vitesse des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 3 niveaux et augmente l'ATK du personnage de 5 niveaux.`,
        `Augmente la vitesse des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 4 niveaux et augmente l'ATK du personnage de 5 niveaux.`,
        `Augmente la vitesse des alliés de classe Cogneur, Sabreur, Ravageur, Tireur et Intellectuel de 5 niveaux et augmente l'ATK du personnage de 5 niveaux.`,
      ],
      capacityAttributes: [
        {
          SPEED: {
            value: 5,
            targets: [
              CharacterClass.Cogneur,
              CharacterClass.Sabreur,
              CharacterClass.Ravageur,
              CharacterClass.Tireur,
              CharacterClass.Intellectuel,
            ],
            fromTime: 100,
            toTime: 0,
          },
          ATK: {
            value: 5,
            targets: ['SELF'],
            fromTime: 100,
            toTime: 0,
          },
        },
      ],
    },
    sockets: 5,
    cost: 55,
    hasLimitBreak: true,
    hasLimitBreakPlus: true,
    potentialAbilities: [
      {
        type: PotentialAbility.STR_REDUCTION,
        icon: '',
        effects: [
          `Réduit les dégâts subis par les ennemis de type STR de 2%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 3%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 4%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 5%.`,
          `Réduit les dégâts subis par les ennemis de type STR de 7%.`,
        ],
      },
      {
        type: PotentialAbility.BARRIERE,
        icon: '',
        effects: [
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont au maximum.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 90%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 70%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières si les HP sont supérieurs ou égaux à 50%.`,
          `Permet aux attaques normales du personnage d'ignorer les barrières.`,
        ],
      },
      {
        type: PotentialAbility.ATTAQUE_CRITIQUE,
        icon: '',
        effects: [
          `Si un coup "PARFAIT" est effectué avec ce personnage, 40% de chances de faire 4% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 50% de chances de faire 5% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 70% de chances de faire 6% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 80% de chances de faire 8% l'ATK du personnage en dégâts additionnels.`,
          `Si un coup "PARFAIT" est effectué avec ce personnage, 90% de chances de faire 10% l'ATK du personnage en dégâts additionnels.`,
        ],
      },
    ],
    evolvers: [
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0118.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0266.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0301.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0101.png',
      'https://onepiece-treasurecruise.com/en/wp-content/uploads/sites/2/f0267.png',
    ],
    support: {
      hasSupportEffect: true,
      supportedCharacters: ['Nami', 'Tony-Tony Chopper', 'Brook', 'Jinbe'],
      levels: [
        `Ajoute 8% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Ajoute 10% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Ajoute 12% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Ajoute 15% de l'ATK du personnage en ATK pour les personnages soutenus.`,
        `Ajoute 18% de l'ATK du personnage en ATK pour les personnages soutenus.`,
      ],
    },
    batch: `First Legend`,
  },
];
