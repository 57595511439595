export const glbKizunas = [
  '2518',
  '2531',
  '2615',
  '2630',
  '2682',
  '2733',
  '2758',
  '2794',
  '2812',
  '2831',
  '2854',
  '2890',
  '2891',
  '2920',
  '2955',
  '3381',
  '2999',
  '3036',
  '3052',
  '3098',
  '3124',
  '3154',
  '3207',
  '3213',
  '3241',
  '3276',
  '3303',
  '3351',
  '3373',
  '3397',
  '3419',
  '3450',
  '3470',
  '3490',
  '3511',
  '3532',
];

export const japKizunas = [];

export const kizunas = [...glbKizunas, ...japKizunas];
