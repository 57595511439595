<p>
  Une Tier list sauvegardée portant le même nom existe déjà. Voulez-vous
  l'écraser ?
</p>

<button class="active" mat-button [mat-dialog-close]="true">
  <mat-icon>save</mat-icon>
  Sauvegarder
</button>

<button class="warn" mat-button mat-dialog-close>
  <mat-icon>cancel</mat-icon>
  Annuler
</button>
